import * as types from './types'

export const switchTheme = (payload) => {
	return {
		type: types.SWITCH_THEME,
		value: payload
	}
}

export const setIsLoading = (payload) => {
	return {
		type: types.IS_LOADING,
		value: payload
	}
}

export const setlatestMessage = (payload) => {
	return {
		type: types.LATEST_MESSAGE,
		value: payload
	}
}

export const setEmail = (payload) => {
	return {
		type: types.EMAIL,
		value: payload
	}
}
export const setUserName = (payload) => {
	return {
		type: types.USERNAME,
		value: payload
	}
}

export const setRecievedMessages = (payload) => {
	return {
		type: types.Recieved_Messages,
		value: payload
	}
}
export const setNotificationsMessages = (payload) => {
	return {
		type: types.Recieved_Notifications,
		value: payload
	}
}
