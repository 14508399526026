import * as types from '../actions/types';

const initialState = {
    isDarkTheme: false,
    email: null,
    isLoading: false,
    userName: '',
    latestMessage: null, // New state property
    recievedMessages: {
        customers: "customer",
        order: "order",
        feedback: "feedback"
    } ,
    notificationMessages:false
};

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case types.SWITCH_THEME:
            return {
                ...state,
                isDarkTheme: action.value
            };

        case types.IS_LOADING:
            return {
                ...state,
                isLoading: action.value
            };

        case types.EMAIL:
            return {
                ...state,
                email: action.value
            };

        case types.USERNAME:
            return {
                ...state,
                userName: action.value
            };

        case types.LATEST_MESSAGE: // New case to handle LATEST_MESSAGE action
            return {
                ...state,
                latestMessage: action.value
            };
        case types.Recieved_Messages: // New case to handle LATEST_MESSAGE action
            return {
                ...state,
                recievedMessages: action.value
            };
            case types.Recieved_Notifications: // New case to handle LATEST_MESSAGE action
            return {
                ...state,
                notificationMessages: action.value
            };


        default:
            return state;
    }
}
