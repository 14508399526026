// src/serviceWorkerRegistration.js
// This file is responsible for registering your service worker.
// It's typically generated automatically when you initialize your project with service worker support.

// Check if the browser supports service workers


  export function register() {
    if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/firebase-messaging-sw.js') // Replace with your service worker file path
        .then((registration) => {
          console.log('ServiceWorker registration successful with scope: ', registration.scope);
        })
        .catch((error) => {
          console.error('ServiceWorker registration failed: ', error);
        });
    });
  }
  }