// firebase/firebase.js
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyAoOf5mKS_sWnWevWgQ-Ql5dbuqYRVsfJ4",
    authDomain: "mx-hub-f0c99.firebaseapp.com",
    projectId: "mx-hub-f0c99",
    storageBucket: "mx-hub-f0c99.appspot.com",
    messagingSenderId: "736273153509",
    appId: "1:736273153509:web:8e7210ef35c5e3fde7c30c"
  };

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };
export default app;
