import React from 'react'
import {MDBDataTable, MDBIcon} from 'mdbreact'
import {FaArrowRight} from 'react-icons/fa' // Assuming you're using Font Awesome icons
import {palette} from '../../styles/colors'
import {styled} from 'styled-components'

const OrderDetailsTable = ({OrderDetails}) => {
	const data = {
		columns: [
			{
				label: 'Id',
				field: 'id',
				width: 150
			},

			{
				label: 'Product Name',
				field: 'productName',
				width: 150
			},
			{
				label: 'Qty',
				field: 'qty',
				width: 150
			},
			{
				label: 'Price',
				field: 'price',
				width: 150
			},
			{
				label: 'Total',
				field: 'total',
				width: 40
			},
		],
		rows: OrderDetails
	}

	// const paginationIcons = [<MDBIconCustom icon="angle-left" size="lg" />, <MDBIconCustom icon="angle-right" size="lg" />]

	return <MDBDataTable paging={false} paginationLabel={false} displayEntries={false} responsive entries={6} responsiveSm noBottomColumns={true} hover small data={data} sortable={false} searching={false} />
}

// const MDBIconCustom = styled(MDBIcon)`
// 	background: ${palette.black};
// 	padding: 0 !important;
// 	width: 34.19px;
// 	border-radius: 5px;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	height: 37.03px;
// 	color: ${palette.yellow};
// `
export default OrderDetailsTable
