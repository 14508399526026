import React, {useState} from 'react'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import {styled} from 'styled-components'
import {palette} from '../styles/colors'
import SearchSection from '../components/common/SearchSection'
import InputField from './common/InputField'
import {Flexed, Heading, Spacer, Text} from '../styles/shared'
import Button from './common/Button'
import SuccessModal from './modals/SuccessModal'
import CheckBox from './common/CheckBox'
import CustomCheckBox from './common/CustomCheckBox'

const EditProductForm = () => {
	return (
		<>
			<Form>
				<Row>
					<Col lg={6}>
						<InputField
							labelType="xlarge"
							label="Name"
							placeholder="Zero nicotine"
							// handleChange={(e) => {
							// 	setCustomerNameError('')
							// 	if (e === '') {
							// 		setCustomerNameError('name is required')
							// 	}
							// 	setCustomerName(e)
							// }}
							// error={customerNameError}
							// errorMsg={customerNameError}
							required
						/>
						<Spacer height={1} />
					</Col>
					<Col lg={6}>
						<InputField
							labelType="xlarge"
							label="Category"
							placeholder="Bar Series"
							// handleChange={(e) => {
							// 	setCustomerEmailError('')
							// 	if (e === '') {
							// 		setCustomerEmailError('Email is required')
							// 	}
							// 	setCustomerEmail(e)
							// }}
							// error={customerEmailError}
							// errorMsg={customerEmailError}
							required
						/>
					</Col>
					<Col>
						<InputField
							labelType="xlarge"
							type="textarea"
							label="Description"
							placeholder="Name"
							// handleChange={(e) => {
							// 	setCustomerNameError('')
							// 	if (e === '') {
							// 		setCustomerNameError('name is required')
							// 	}
							// 	setCustomerName(e)
							// }}
							// error={customerNameError}
							// errorMsg={customerNameError}
							required
						/>
					</Col>
				</Row>

				<Row>
					<Col>
						<Spacer height={1} />
						<InputField
							labelType="xlarge"
							label="Remaining qty"
							placeholder="2500"
							allowPhoneNumberOnly
							// handleChange={(e) => {
							// 	setCustomerNameError('')
							// 	if (e === '') {
							// 		setCustomerNameError('name is required')
							// 	}
							// 	setCustomerName(e)
							// }}
							// error={customerNameError}
							// errorMsg={customerNameError}
							required
						/>

						<Spacer height={1} />
					</Col>
				</Row>
				<Row>
					<Col>
						<Spacer height={1.625} />
						<Heading fontSize={1.5} fontWeight={700}>
							Prices
						</Heading>
						<Spacer height={1} />
					</Col>
					<Col lg={6}>
						<InputField
							labelType="xlarge"
							label="Distributors"
							allowPhoneNumberOnly
							placeholder="435"
							// handleChange={(e) => {
							// 	setCustomerNameError('')
							// 	if (e === '') {
							// 		setCustomerNameError('name is required')
							// 	}
							// 	setCustomerName(e)
							// }}
							// error={customerNameError}
							// errorMsg={customerNameError}
							required
						/>

						<Spacer height={1} />
					</Col>
					<Col lg={6}>
						<InputField
							labelType="xlarge"
							label="Online power house"
							placeholder="456"
							// handleChange={(e) => {
							// 	setCustomerEmailError('')
							// 	if (e === '') {
							// 		setCustomerEmailError('Email is required')
							// 	}
							// 	setCustomerEmail(e)
							// }}
							// error={customerEmailError}
							// errorMsg={customerEmailError}
							required
						/>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
						<InputField
							labelType="xlarge"
							label="Shops"
							placeholder="635"
							// handleChange={(e) => {
							// 	setCustomerNameError('')
							// 	if (e === '') {
							// 		setCustomerNameError('name is required')
							// 	}
							// 	setCustomerName(e)
							// }}
							// error={customerNameError}
							// errorMsg={customerNameError}
							required
						/>

						<Spacer height={1} />
					</Col>
					<Col lg={6}>
						<CustomFlexed justify="space-between" align="center">
							<CustomCheckBox label="New deals" right="0.5" />
							<CustomCheckBox label="Coming soon" right="0.5" />
						</CustomFlexed>
					</Col>
				</Row>
				<Spacer height="1" />
				<Row>
					<CustomCol lg={6}>
						<Col lg={9}>
							<Text fontSize="1.25" lineHeight="1" color="black">
								Product fullfillment &nbsp;<InnetText>(in days)</InnetText>
							</Text>
						</Col>
						<Col lg={3}>
							<InputField
								placeholder="635"
								// handleChange={(e) => {
								// 	setCustomerNameError('')
								// 	if (e === '') {
								// 		setCustomerNameError('name is required')
								// 	}
								// 	setCustomerName(e)
								// }}
								// error={customerNameError}
								// errorMsg={customerNameError}
								required
							/>
						</Col>
					</CustomCol>
					<Col lg={6}>
						<CustomFlexed justify="space-between" align="center">
							<CustomCheckBox label="Product Available" />
						</CustomFlexed>
					</Col>
				</Row>
			</Form>
		</>
	)
}

const Form = styled.div``

const InnetText = styled.span`
	font-size: 0.85rem;
	color: black;
`
const CustomCol = styled(Col)`
	display: flex;
	align-items: center;
	justify-content: center;
`

const CustomFlexed = styled(Flexed)`
	width: 100% !important;
	height: 100% !important;
`
export default EditProductForm
