import React, {useState} from 'react'
import styled from 'styled-components'
import {Flexed, Spacer} from '../../styles/shared'
import {palette} from '../../styles/colors'

const CustomCheckBox = ({label, direction,width,height,lineHeight,isChecked, setIsChecked,justify,fontSize, fontWeight, opacity, textTransform,right}) => {
	// const [isChecked, setIsChecked] = useState(false)

	return (
        <>      
         <Flexed onClick={() => setIsChecked(!isChecked)} src={isChecked ? '/icons/rectangleCheck.svg' : '/icons/rectangleUncheck.svg'} direction={direction} justify={justify} align="center">
         {label && (
				<CustomText right={right} isChecked={isChecked} fontSize={fontSize} lineHeight={lineHeight} fontWeight={fontWeight} opacity={opacity} textTransform={textTransform}>
					{label}
				</CustomText>
			)}
         {/* <Checkbox
           type="checkbox"
           onChange={(e) => handleCheckboxChange(e.target.checked)}
         /> */}
         <IconWrapper height={height} width={width}>
				<Icon onClick={() => setIsChecked(!isChecked)} src={isChecked ? '/icons/rectangleCheck.svg' : '/icons/rectangleUncheck.svg'} />
			</IconWrapper>

       </Flexed>
       </>
	)
}

const CustomText = styled.div`
	/* font-family: ${({family}) => (family ? `${family}` : 'Manrope')}; */
	font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : '400')};
	font-size: ${({fontSize}) => (fontSize ? `${fontSize}rem` : '1.3rem')};
	line-height: ${({lineHeight}) => (lineHeight ? `${lineHeight}rem` : '1rem')};

	padding-right:${({right})=>(right ? `${right}rem` : "3rem")};
	color: ${ palette.black};
	text-transform: ${({textTransform}) => (textTransform ? textTransform : 'normal')};
	opacity: ${({opacity}) => (opacity ? opacity : 1)};
`

const Checkbox = styled.input`

  /* Define the size */
  width: 3rem;
  height:3rem;
  /* Add a border */
  border: 7px solid ${palette.black}; /* Adjust the color and width as needed */
  /* Add some padding for visual spacing */
  padding: 2px;
  /* Ensure the checkbox remains clickable */
  cursor: pointer;

  
`;

const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
    height: ${({height}) => (height ? `${height}rem` : '3rem')};
    width: ${({width}) => (width ? `${width}rem`:'3rem')};
   
`
const StyledIconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 0.875rem;
	width: 0.875rem;
	margin-left: 0.375rem;
`

const Icon = styled.img`
	cursor: pointer;
	height: 100%;
	width: 100%;
`
export default CustomCheckBox
