import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { palette } from "../styles/colors";
import { Container, Row, Col, media } from "styled-bootstrap-grid";
import { Flexed, Spacer, Text } from "../styles/shared";
import InputField from "../components/common/InputField";
import Button from "../components/common/Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const BacktoLogin = () => {
const  _navigate=useNavigate();
const email = useSelector((state) => state.auth.email)
useEffect(() => {
  if (email===null) {
    
    _navigate("/forgotpassword");
  }
}, []);
  return (
    <>
     <CustomContainer fluid>
        <RowWrapper justifyContent="center" alignItems="center">
          <Wrapper>
            <ColWrapper lg={8}>
              <Flexed direction="column" justify="space-evenly" width={`70%`}>
                <Flexed justify="center">
                  <Logo src="/icons/tickBlack.svg" alt="logo" />
               
                </Flexed>

                <Flexed direction="column">
                  <Text fontSize={2.5} fontWeight="600" color="black" lineHeight={3}>
                  Your password has been reset
                   successfully!
                  </Text>

                  <Spacer height={2} />

                 

                  

                  <Spacer height={1} />
                  <Button
                    width="100%"
                    ifClicked={() => {
                      console.log('button clicked back', )
                      _navigate('/')
                    }}
                    label="Back to Login"
                  />
                </Flexed>
              </Flexed>
            </ColWrapper>
            {/* <ImageWrapper> */}
            <CustomhideCol lg={4}>
              <Image src="/icons/getstarted.png" />
            </CustomhideCol>
            {/* </ImageWrapper> */}
          </Wrapper>
        </RowWrapper>
      </CustomContainer>
	
    </>
  );
};

const CustomCol = styled(Col)`
  display: flex;

  gap: 20px;
`;
const CustomhideCol = styled(Col)`
  ${media.xs`
display:none;
`}
  ${media.sm`
display:none;
`}
${media.md`
display:none;
`}
${media.lg`
padding:0px;
display:block;
height: 80vh;
width: 100%;
border-radius: 0rem 2rem 2rem 0rem;
`}
`;
const ImageWrapper = styled.div`
  ${media.xs`
display:none;
`}
  ${media.sm`
display:none;
`}
${media.md`
display:none;
`}
${media.lg`

display:block;
height: 80vh;
width: 100%;
border-radius: 0rem 2rem 2rem 0rem;
`}
`;

const Image = styled.img`
  height: 80vh;
  width: 100%;
  border-radius: 0rem 2rem 2rem 0rem;
`;
const Logo = styled.img`
width:50%;
${media.xs`
width:60%;
`}
${media.sm`
width:45%;
`}
${media.md`
width:45%;
`}
${media.lg`
width:50%;
`}
`;

const CustomContainer = styled(Container)`
  background-color: ${palette.black};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
`;

const RowWrapper = styled(Row)`
  width: 100%;

  margin-left: 0px !important;
`;

const ColWrapper = styled(Col)`
  display: flex;

  justify-content: center; /* Center horizontally */
`;

const Wrapper = styled.div`
  width: 85%;
  height: 80vh;
  background-color: ${palette.white};
  border-radius: 2.106rem;
  display: flex;
  box-shadow: 0px 0px 15px 0px #F49C10;
`;
export default BacktoLogin;
