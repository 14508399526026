import axios from 'axios'
// import {toastError, toastSuccess} from '../styled/toastStyle'

// axios.defaults.headers.post['Content-Type'] = 'application/json'
// axios.defaults.headers.post['Accept'] = 'application/json'
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
// axios.defaults.headers.get["ngrok-skip-browser-warning"] = "69420";
// var app = express();

// app.use(function(req, res, next) {
//   res.header("Access-Control-Allow-Origin", "*");
//   res.header("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
//   next();
// });

//Category

export const addCustomer = async (username, email, company_name, company_email, salespersoncode, phone_no, address, city, state, zipcode) => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.post(
			`${process.env.REACT_APP_API_URL}admincrm/createCustomer/`,
			{
				username,
				email,
				companyName: company_name,
				companyEmail: company_email,
				salesPersonCode: salespersoncode,
				phoneNo: phone_no,
				address: address,
				city,
				state,
				zipCode: zipcode
			},
			config
		)
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const getCustomer = async (salesPersonCode, page, pageSize, search) => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(
			salesPersonCode === 0
				? `${process.env.REACT_APP_API_URL}admincrm/getAllCustomers/?page=${page}&pageSize=${pageSize}${search != '' ? `&search=${search}` : ''}`
				: `${process.env.REACT_APP_API_URL}admincrm/getAllCustomers/?salesPersonCode=${salesPersonCode}&page=${page}&pageSize=${pageSize}${search != '' ? `&search=${search}` : ''}`,
			config
		)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}
export const getProducts = async (page, pageSize, search) => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(`${process.env.REACT_APP_API_URL}admincrm/getAllProducts/?page=${page}&pageSize=${pageSize}${search != '' ? `&search=${search}` : ''}`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}
export const getCustomerType = async () => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(`${process.env.REACT_APP_API_URL}admincrm/getCustomerType/`)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const getProductGroup = async (search="") => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(`${process.env.REACT_APP_API_URL}admincrm/getProductGroup/${search != '' ? `?search=${search}` : ''}`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}
export const deleteCustomer = async (id) => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.delete(`${process.env.REACT_APP_API_URL}admincrm/deleteCustomer?id=${id}`, config)
		.then((res) => {
			console.log('resasdasda', res)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

// export const getFeedBacks = async (page, pageSize) => {
// 	const token = sessionStorage.getItem('authorization')
// 	const config = {
// 		headers: {
// 			authorization: `Bearer ${token}`
// 		}
// 	}
// 	let response = []
// 	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
// 	await axios
// 		.get(`${process.env.REACT_APP_API_URL}admincrm/getAllFeedbacks/?page=${page}&pageSize=${pageSize}`, config)
// 		.then((res) => {
// 			response = res?.data
// 		})
// 		.catch((error) => {
// 			console.error(error)
// 		})
// 	return response
// }
// &date=12
export const getFeedBacks = async (page, pageSize, selectedReview, selectedDate) => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(`${process.env.REACT_APP_API_URL}admincrm/getAllFeedbacks/?page=${page}&pageSize=${pageSize}&review=${selectedReview}&date=${selectedDate}`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}
//delete FeedBack
export const deleteFeedBack = async (id) => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.delete(`${process.env.REACT_APP_API_URL}admincrm/deleteFeedback?id=${id}`, config)
		.then((res) => {
			console.log('resasdasda', res)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}
export const getUser = async () => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}

	let response = []
	await axios
		.get(
			`${process.env.REACT_APP_API_URL}admincrm/getUser/`,
			config
			// {
			// 	email: email,
			// 	password: password
			// }
		)
		.then((res) => {
			console.log('res', res)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const logInApi = async (email, password, fcmToken) => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}

	let response = []
	await axios
		.post(
			`${process.env.REACT_APP_API_URL}admincrm/signin/`,
			{
				email: email,
				password: password,
				fcmToken
			},
			config
		)
		.then((res) => {
			console.log('res', res)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const forgetPassword = async (email) => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}

	let response = []
	await axios
		.post(
			`${process.env.REACT_APP_API_URL}admincrm/forgotPassword/`,
			{
				email: email
			},
			config
		)
		.then((res) => {
			console.log('res', res)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const verifyOtp = async (email, otp) => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}

	let response = []
	await axios
		.post(
			`${process.env.REACT_APP_API_URL}admincrm/verifyOtp/`,
			{
				email: email,
				otp: otp
			},
			config
		)
		.then((res) => {
			console.log('res', res)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const resetPassword = async (password, email) => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}

	let response = []
	await axios
		.post(
			`${process.env.REACT_APP_API_URL}admincrm/resetPassword/`,
			{
				password: password,
				email: email
			},
			config
		)
		.then((res) => {
			console.log('res', res)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const updateCustomerStatus = async (id, status) => {
	const token = sessionStorage.getItem('authorization')

	// console.log('status', id, status, selectedCustomerType, selectedProductGroupType)
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.patch(`${process.env.REACT_APP_API_URL}admincrm/updateCustomer?id=${id}`, {status: status}, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}
export const updateCustomer = async (id, selectedCustomerType, salespersoncode, selectedStatusValue) => {
	const token = sessionStorage.getItem('authorization')

	// console.log('status', id, status, selectedCustomerType, selectedProductGroupType)
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.patch(
			`${process.env.REACT_APP_API_URL}admincrm/updateCustomer?id=${id}`,
			{
				customer_type: selectedCustomerType,
				salespersoncode: salespersoncode,
				status: selectedStatusValue
			},
			config
		)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

/// Products

//////////////////////////  Banner Apis /////////////////////////////

export const getAllBanners = async (page, pageSize, search) => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(`${process.env.REACT_APP_API_URL}admincrm/getAllBanners/?page=${page}&pageSize=${pageSize}${search != '' ? `&search=${search}` : ''}`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const getAllBannersCount = async () => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(`${process.env.REACT_APP_API_URL}admincrm/getAllBannersStatusCount/`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

///// canpaigns

export const addBanner = async (title, images, discount, status, endDate) => {
	let response = []
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.post(
			`${process.env.REACT_APP_API_URL}admincrm/createBanner/`,
			{
				title: title,
				images: images,
				discount: discount,
				status: status,
				endDate: endDate
			},
			config
		)
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const updateBanner = async (bannerId, title, images, discount, status, endDate, productGroup) => {
	let response = []
	// let response = []
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.patch(
			`${process.env.REACT_APP_API_URL}admincrm/updateBanner/?id=${bannerId}`,
			{
				title: title,
				images: images,
				discount: discount,
				status: status,
				endDate: endDate,
				productGroup: productGroup
			},
			config
		)
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const updateBannerWithoutImg = async (bannerId, title, discount, status, endDate, productGroup) => {
	let response = []
	// let response = []
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.patch(
			`${process.env.REACT_APP_API_URL}admincrm/updateBanner/?id=${bannerId}`,
			{
				title: title,
				//  images: images,
				discount: discount,
				status: status,
				endDate: endDate,
				productGroup: productGroup
			},
			config
		)
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const deleteBanner = async (bannerId) => {
	// let response = []
	let response = []
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	try {
		const res = await axios.delete(`${process.env.REACT_APP_API_URL}admincrm/deleteBanner?id=${bannerId}`, config)
		console.log('response', res.data)
		response = res.data
	} catch (error) {
		console.error(error)
	}
	return response
}

/// Compaigns
export const getCompaigns = async (page, pageSize, search) => {
	let response = []
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(`${process.env.REACT_APP_API_URL}admincrm/getAllCompaigns/?page=${page}&pageSize=${pageSize}${search != '' ? `&search=${search}` : ''}`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const generateImageLink = async (image) => {
	let response = []
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.post(`${process.env.REACT_APP_API_URL}admincrm/upload/`, {base64: image}, config)
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const addCompaign = async (name, title, body, radio, image, email) => {
	let response = []
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.post(
			`${process.env.REACT_APP_API_URL}admincrm/createCompaign/`,
			{
				compaignName: name,
				compaignTitle: title,
				compaignBody: body,
				shareWith: radio === 'Email' ? '' : radio,
				shareWithEmail: radio === 'Email' ? email : [],
				imgUrl: image
			},
			config
		)
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const deleteCampaign = async (id) => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.delete(`${process.env.REACT_APP_API_URL}admincrm/deleteCompaign/?id=${id}`, config)
		.then((res) => {
			console.log('resasdasda', res)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const resendCampaign = async (id) => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.post(`${process.env.REACT_APP_API_URL}admincrm/resendCampaign/?id=${id}`, config)
		.then((res) => {
			console.log('resasdasda', res)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

/////////////////////////////

export const getAllOrders = async (date, salesPersonCode, page, pageSize) => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	// await axios
	// 	.get(
	// 		(salesPersonCode === 0 &&  date === null) && `${process.env.REACT_APP_API_URL}admincrm/getAllOrders/?page=${page}&pageSize=${pageSize}`
	// 		(salesPersonCode !== 0 && date == null)  && `${process.env.REACT_APP_API_URL}admincrm/getAllOrders/?page=${page}&pageSize=${pageSize}&salesPersonCode=${salesPersonCode}`
	// 		(date !== null && salesPersonCode === 0) && `${process.env.REACT_APP_API_URL}admincrm/getAllOrders/?page=${page}&pageSize=${pageSize}&date=${date}`
	// 		(date !== null && salesPersonCode !== 0) && `${process.env.REACT_APP_API_URL}admincrm/getAllOrders/?page=${page}&pageSize=${pageSize}&date=${date}&salesPersonCode=${salesPersonCode}`
	// 		, config
	// 	)

	await axios
		.get(
			`${process.env.REACT_APP_API_URL}admincrm/getAllOrders/?page=${page}&pageSize=${pageSize}` +
				(salesPersonCode === 0 && date === null
					? ''
					: salesPersonCode !== 0 && date == null
					? `&salesPersonCode=${salesPersonCode}`
					: date !== null && salesPersonCode === 0
					? `&date=${date}`
					: `&date=${date}&salesPersonCode=${salesPersonCode}`),
			config
		)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}
export const syncProductGroups = async () => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.post(`${process.env.REACT_APP_API_URL}admincrm/syncProductGroups/`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}
export const processAndStoreData = async () => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.post(`${process.env.REACT_APP_API_URL}admincrm/processAndStoreData/`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const updateProductStatus = async (id, status) => {
	let response = []
	const token = sessionStorage.getItem('authorization')

	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.patch(
			`${process.env.REACT_APP_API_URL}admincrm/updateProduct/?id=${id}`,
			{
				productAvailable: status
			},
			{
				timeout: 0 // Set timeout to 0 for no timeout
			},
			config
		)
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

/// sale person
export const getAllSalePerson = async (salesPersonCode, page, pageSize, search) => {
	let response = []
	const token = sessionStorage.getItem('authorization')

	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(
			salesPersonCode === 0
				? `${process.env.REACT_APP_API_URL}admincrm/getAllSalesPerson/?page=${page}&pageSize=${pageSize}${search != '' ? `&search=${search}` : ''}`
				: `${process.env.REACT_APP_API_URL}admincrm/getAllSalesPerson/?salesPersonCode=${salesPersonCode}&page=${page}&pageSize=${pageSize}${search != '' ? `&search=${search}` : ''}`,
			config
		)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const updateSalesPersonStatus = async (Id, status) => {
	let response = []
	const token = sessionStorage.getItem('authorization')

	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.patch(
			`${process.env.REACT_APP_API_URL}admincrm/updateSalesPerson/?id=${Id}`,
			{
				status: status
			},
			config
		)
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const deleteSalesPerson = async (Id) => {
	let response = []
	const token = sessionStorage.getItem('authorization')

	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.delete(`${process.env.REACT_APP_API_URL}admincrm/deleteSalesPerson/?id=${Id}`, config)
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const updateSalesPerson = async (Id, number, spCode) => {
	let response = []
	const token = sessionStorage.getItem('authorization')

	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.patch(
			`${process.env.REACT_APP_API_URL}admincrm/updateSalesPerson/?id=${Id}`,
			{
				phoneNumber: number,
				salesPersonCode: spCode
			},
			config
		)
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const addSalesPerson = async (fullName, email, salesPersonCode, phoneNumber) => {
	let response = []
	const token = sessionStorage.getItem('authorization')

	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.post(
			`${process.env.REACT_APP_API_URL}admincrm/createSalesPerson/`,
			{
				fullName,
				email,
				salesPersonCode,
				phoneNumber
			},
			config
		)
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const syncSalesPerson = async () => {
	let response = []
	const token = sessionStorage.getItem('authorization')

	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.post(`${process.env.REACT_APP_API_URL}admincrm/syncSalesPerson/`, config)
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const updateProductData = async (productId, updateData) => {
	let response = []
	const token = sessionStorage.getItem('authorization')

	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.patch(
			`${process.env.REACT_APP_API_URL}admincrm/updateProduct/?id=${productId}`,
			updateData,
			{
				timeout: 0 // Set timeout to 0 for no timeout
			},
			config
		)
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const syncProducts = async (groupList, dataOptions) => {
	const {productName, productDesc, productQuantity, defaultSellPrice, distributerPrice, onlinePowerHousePrice, retailorPrice} = dataOptions
	let response = []
	const token = sessionStorage.getItem('authorization')

	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.post(
			`${process.env.REACT_APP_API_URL}admincrm/syncProducts/`,
			{
				groupList,
				productName,
				productDesc,
				productQuantity,
				defaultSellPrice,
				distributerPrice,
				onlinePowerHousePrice,
				retailorPrice
			},
			config
		)
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const getProductsToSync = async (productGroup, page, pageSize) => {
	let response = []
	const token = sessionStorage.getItem('authorization')
	let queryString = ''
	productGroup.forEach((item, index) => {
		// Append the element to the query string
		queryString += `productGroup=${encodeURIComponent(item)}`

		// Add '&' if it's not the last element
		if (index < productGroup.length - 1) {
			queryString += '&'
		}
	})
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(
			productGroup.length > 0 ? `${process.env.REACT_APP_API_URL}admincrm/getProductsToSync/?page=${page}&pageSize=${pageSize}&${queryString}` : `${process.env.REACT_APP_API_URL}admincrm/getProductsToSync/?page=${page}&pageSize=${pageSize}`,
			{
				timeout: 0 // Set timeout to 0 for no timeout
			},
			config
		)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const deleteImageLink = async (image) => {
	console.log(' in apiiiiiimage :>> ', image)
	let response = []
	const token = sessionStorage.getItem('authorization')

	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.delete(`${process.env.REACT_APP_API_URL}user/deleteImage/`, {data: {imageUrl: image}}, config)
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const getAllSPCodes = async () => {
	let response = []
	const token = sessionStorage.getItem('authorization')

	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(`${process.env.REACT_APP_API_URL}admincrm/getAllSPCodes/`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const createUnleashedSalesOrder = async (id) => {
	let response = []
	const token = sessionStorage.getItem('authorization')

	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.post(`${process.env.REACT_APP_API_URL}admincrm/createUnleashedSalesOrder/?id=${id}`, config)
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const updateSettings = async (image, currentPassword, newPassword) => {
	let response = []
	const token = sessionStorage.getItem('authorization')

	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	//console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	console.log('image ####################################!!!!!!!!!!!!! :>> ', image)
	await axios
		.post(
			`${process.env.REACT_APP_API_URL}admincrm/updateSettings/`,
			{
				image,
				currentPassword,
				newPassword
			},
			config
		)
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}
export const updateOrderItem = async (data) => {
	let response = []
	const token = sessionStorage.getItem('authorization')

	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}

	await axios
		.post(
			`${process.env.REACT_APP_API_URL}admincrm/updateOrderItem/`,
			data,
			config
		)
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const getCustomerProfileStatus = async (customerID) => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(`${process.env.REACT_APP_API_URL}admincrm/getCustomerProfileStatus/?customerId=${customerID}`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const updateCollection = async (productGroupId, groupImg, status) => {
	console.log('groupImg :>> ', groupImg)
	let response = null
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}

	const apiUrl = `${process.env.REACT_APP_API_URL}admincrm/updateProductGroup/?id=${productGroupId}`

	try {
		if (groupImg.length > 0) {
			response = await axios.patch(
				apiUrl,
				{
					groupImg: groupImg,
					status: status
				},
				config
			)
		} else {
			response = await axios.patch(
				apiUrl,
				{
					status: status
				},
				config
			)
		}
		console.log('response', response.data)
		return response.data
	} catch (error) {
		console.error(error)
		return null
	}
}

export const getSalesPersonProfileStatus = async (salesPersonId) => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(`${process.env.REACT_APP_API_URL}admincrm/getSalesPersonProfileStatus/?salesPersonId=${salesPersonId}`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}


export const getOrderStatus = async () => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(`${process.env.REACT_APP_API_URL}admincrm/getDashboard/`,config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const getRecentActivity = async () => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
//	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(`${process.env.REACT_APP_API_URL}admincrm/getAllActivities/`,config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const setSeenNotifications = async () => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
//	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.post(`${process.env.REACT_APP_API_URL}admincrm/seenNotifications/`,{},config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const setClearNotifications = async () => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
//	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.post(`${process.env.REACT_APP_API_URL}admincrm/clearNotifications/`,{},config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}


export const getNotifications = async () => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(`${process.env.REACT_APP_API_URL}admincrm/getNotifications/`,config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}
export const getCreditDetails = async (customerId) => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(`${process.env.REACT_APP_API_URL}admincrm/getCreditDetails/?customerId=${customerId}`,config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}
export const updateCreditDetails = async (customerId, allowedCredits , totalPaidAmount) => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.patch(`${process.env.REACT_APP_API_URL}admincrm/updateCreditDetails/?customerId=${customerId}`,
		{
			allowedCredits,
			totalPaidAmount
		},config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}