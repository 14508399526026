import React, {useEffect, useState} from 'react'
import {Container, media} from 'styled-bootstrap-grid'
import {styled} from 'styled-components'
import {palette} from '../styles/colors'
import SearchSection from '../components/common/SearchSection'
import {Flexed, Spacer, Text} from '../styles/shared'
// import DropDown from "../components/common/DropDown";
import OrdersTable from '../components/tables/OrdersTable'
import CustomDropDown from '../components/common/CustomDropDown'
// import ProfileViewModal from "../components/modals/ProfileViewModal";
import OrdersDetailsModal from '../components/modals/OrdersDetailsModal'
import DateSelector from '../components/common/DateSelector'
import {getAllOrders, getAllSPCodes} from '../api/apis'
import {setIsLoading} from '../actions/authActions'
import {useDispatch} from 'react-redux'
import {toastError, toastSuccess} from '../styles/toasterStyle'
import Button from '../components/common/Button'
import {useSelector} from 'react-redux'
import { setlatestMessage } from '../actions/authActions';
const Orders = () => {
	const [openOrdersDetail, setOpenOrdersDetails] = useState(false)
	const latestMessage = useSelector((state) => state.auth.latestMessage);
	const recievedMessages = useSelector((state) => state.auth.recievedMessages);
	const [pageNumber, setPageNumber] = useState(1)
	const [pagesAmount, setPagesAmount] = useState(1)
	const [totalNumberOfOrders, setTotalNumberOfOrders] = useState(1)
	const [filterOptions, setFilterOptions] = useState([
		{label: '#8786436', value: 1},
		{label: '#12386436', value: 2},
		{label: '#34566436', value: 3}
	])
	const _dispatch = useDispatch()
	const [tableTest, setTableTest] = useState([])
	const [spCodes, setSpCodes] = useState(0)
	const [selectedProduct, setSelectedProduct] = useState(null)
	const [selectedDate, setSelectedDate] = useState(null)
	const doGetOrders = async () => {
		_dispatch(setIsLoading(true))

		const response = await getAllOrders(selectedDate===null ? selectedDate : formatDate(selectedDate), spCodes ,pageNumber, 6)
		// console.log("getorder/////respone",response)
		if (response?.success) {
			setPagesAmount(Math.ceil(response?.count / 6))
			setTotalNumberOfOrders(response?.count)
			const apiRows = response?.data?.map((order,index) => {
				return {
					id: (
						<Text type="medium" color="black" fontWeight={300}>
							{((pageNumber-1)*6)+index+1}
						</Text>
					),
					company: (
						
							<Text type="medium" color="black" fontWeight={300}>
								{order?.customer?.company_name}
							</Text>
					),
					email: (
						<Text type="medium" color="black" fontWeight={300}>
							{order?.customer?.customer_email}
						</Text>
					),
					items: (
						<Text type="medium" color="black" fontWeight={300}>
							{order?.items?.length}
						</Text>
					),
					orderValue: (
						<Text type="medium" color="black" fontWeight={300}>
							{order?.totalPrice}
						</Text>
					),
					date: (
     <Text type="medium" color="black" fontWeight={300}>
{formatDate(order?.createdAt)}
</Text>
					),
					status: <Status orderStatus={order?.orderStatus}>{order?.orderStatus}</Status>,
					icon: (
						<Flexed direction="row" align="center" gap={0.75} justify="flex-start">
							<div
							onClick={() => {
								if (order?.orderStatus === "Pending") {
									setOpenOrdersDetails(true);
									setSelectedProduct(order);
								} else {
									toastError("Order is already completed");
								}
							}}>
							<IconWrapper>
								<Icon src="icons/pencil.svg" />
							</IconWrapper>
							</div>
						</Flexed>
					)
				}
			})

			setTableTest(apiRows)
			// toastSuccess(response?.message);
		} else {
			toastError(response?.message)
		}

		_dispatch(setIsLoading(false))
	}

	const doGetSpCodes = async () => {
		_dispatch(setIsLoading(true))

		const response = await getAllSPCodes()

		//		console.log('responseresponse', response)
		// console.log('responseresponse', response)
		if (response?.success) {
			console.log('getSpcodes++++++++++++++++++++ :>> ', response?.data)
			setFilterOptions(response?.data?.map((item) => ({label: item, value: item})))
			// toastSuccess(response?.message)
		} else {
			// toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}

	// const formatDate = (dateString) => {
	// 	const date = new Date(dateString);
	// 	const formattedDate = date.toISOString().slice(0, 10); // Convert to yyyy-MM-dd format
	// 	return formattedDate;
	//   };

	  function formatDate(dateString) {
		const dateObj = new Date(dateString);
		const year = dateObj.getFullYear();
		const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
		const day = dateObj.getDate().toString().padStart(2, '0');
		return `${year}-${month}-${day}`;
	}

	useEffect(() => {
		doGetOrders()
		doGetSpCodes()
	}, [pageNumber, selectedDate, spCodes])


	useEffect(()=>{
		if(latestMessage==recievedMessages?.order)
			{
		doGetOrders()
		_dispatch(setlatestMessage(null))
			}
	},[latestMessage])


	return (
		<Wrapper>
			<Container fluid>
				<SearchSection title="Orders" noSearch={true}/>
				<Spacer height={0.625} />
				<Flexed direction="row" align="center" justify="flex-end">
					<Flexed direction="row" align="center" flexWrap="wrap" gap={1}>
						<Text type="xlarge" color="black" fontWeight={700}>
							Filter By:
						</Text>
						<Flexed direction="row" align="center" flexWrap="wrap" gap={0.5}>
						{(spCodes!==0 || selectedDate!==null) && (<>
										<Button
									ifClicked={() => {
										setSpCodes(0)
										setSelectedDate(null)
									}}
									small
									// width="11rem"
									// icon="case.svg"
									label="Show All"
								/>
									</>)}
						<CustomDropDown
										label="SP Code"
										styledHeight={6}
										// firstSelected={selectedEditProduct?.company?.company_name}
										hasChanged={async (value) => {
											// setSelectedDueDateError('')
											// if (value == '') {
											// 	setSelectedDueDateError('due is Required')
											// }
											setSpCodes(value)
										}}
										width="8"
										// required
										// error={selectedDueDateError}
										// errorMsg={selectedDueDateError}
										options={filterOptions}
									/>
							<DateSelector value={selectedDate} onChange={(value)=> {
								setSelectedDate(value);
							}} />
							{/* <CustomDropDown
                label="Date"
                styledHeight={6}
                // firstSelected={selectedEditProduct?.company?.company_name}
                hasChanged={(value) => {
                  // setSelectedDueDateError('')
                  // if (value == '') {
                  // 	setSelectedDueDateError('due is Required')
                  // }
                  // setSelectedDueDate(value)
                }}
                width="8"
                // required
                // error={selectedDueDateError}
                // errorMsg={selectedDueDateError}
                options={filterOptions}
              /> */}
						</Flexed>
					</Flexed>
				</Flexed>
				<Spacer height={0.625} />
				<TableWrapper>
					<OrdersTable tableTest={tableTest} pageNumber={pageNumber} setPageNumber={setPageNumber} pagesAmount={pagesAmount} totalNumberOfOrders={totalNumberOfOrders} />
				</TableWrapper>
			</Container>
			{openOrdersDetail && (
				<OrdersDetailsModal
				   selectedPro={selectedProduct}
				   doGetOrders={doGetOrders}
					onClose={() => {
						setOpenOrdersDetails(false)

					}}
				/>
			)}
		</Wrapper>
	)
}

const Status = styled.div`
	width: 86px;
	border-radius: 20px;
	font-weight: 500;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${({orderStatus}) => (orderStatus === 'Pending' ? palette.yellow : orderStatus === 'accepted' ? palette.white : orderStatus === 'Refund' ? palette.black : palette.black)};
	background-color: ${({orderStatus}) => (orderStatus === 'Pending' ? palette.black : orderStatus === 'accepted' ? palette.yellow : orderStatus === 'Refund' ? palette.customGrey : palette.customGrey)};
`

const Wrapper = styled.div`
	background-color: ${palette.lightGrey};
	min-height: 100vh;
	${media.xs`
	padding: 1rem 1rem;
	`}
	${media.sm`
	padding: 1rem 1rem;
	`}
	${media.md`
	padding: 1.625rem 1.25rem 2.625rem 1.25rem;
	`}
`

const TableWrapper = styled.div`
	border-radius: 0.625rem;
	background-color: ${palette.white};
	padding: 0.75rem;
`

const IconWrapper = styled.div`
	background-color: ${palette.black};
	border-radius: 0.625rem;
	width: 2.25rem;
	height: 2.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
`

const Icon = styled.img`
	width: 1.25rem;
	height: 1.25rem;
	cursor: pointer;
`

export default Orders
