import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {palette} from '../../styles/colors'
import {generateImageLink, updateBanner, updateBannerWithoutImg, getProductGroup} from '../../api/apis'
import {useDispatch} from 'react-redux'
import {setIsLoading} from '../../actions/authActions'
import {toastError, toastSuccess} from '../../styles/toasterStyle'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import {Flexed, Heading, Spacer, Text, VerticalSpacer} from '../../styles/shared'

import {IoMdClose} from 'react-icons/io'
import CustomDropDown from '../common/CustomDropDown'
import Button from '../common/Button'
import CheckBox from '../common/CheckBox'
import InputField from '../common/InputField'
import EditProductForm from '../EditProductForm'
import SuccessModal from './SuccessModal'
import Checkbox from '../common/CheckBox'
import CustomCheckBox from '../common/CustomCheckBox'
import DropDown from '../common/DropDown'

const EditBannerModal = ({onClose, editData, doGetBanners}) => {
	const [openSuccessModal, setOpenSuccessModal] = useState(false)
	const [minDate, setMinDate] = useState(() => {
		const today = new Date();
		return today.toISOString().split('T')[0];
	  });
	console.log('editData', editData)
	const [profilImage, setProfileImage] = useState('')
	const [showImage, setShowImage] = useState(null)
	const [checkValue, setCheckedValue] = useState(false)
	const [getImageLink, setImageLink] = useState('')
	const [label, setLabel] = useState('')
	const [discount, setDiscount] = useState('')
	const [expiry, setExpiry] = useState('')
	const _dispatch = useDispatch()
	const formatDate = (dateString) => {
		const date = new Date(dateString)
		const formattedDate = date.toISOString().slice(0, 10) // Convert to yyyy-MM-dd format
		return formattedDate
	}
	const [productGroupOptions, setProductGroupOptions] = useState([])
	const [productGroup, setProductGroup] = useState('')
	const [productGroupError, setProductGroupError] = useState('')

	const [labelError, setLabelError] = useState('')
	const [discountError, setDiscountError] = useState('')
	const [expiryError, setExpiryError] = useState('')

	const handleCapture = ({target}) => {
		const reader = new FileReader()
		reader.readAsDataURL(target.files[0])
		reader.onload = () => {
			if (reader.readyState === 2) {
				setShowImage(reader.result)
				setProfileImage(reader.result)
			}
		}
	}

	const doUploadImage = async () => {
		_dispatch(setIsLoading(true))

		const response = await generateImageLink(showImage)

		console.log('Response from addCompaign image:', response?.data)
		setImageLink(response?.data)
		console.log('image ka link show ho', getImageLink)
		if (response?.success) {
			doUpdateBanner(response?.data)
		} else {
			toastError(response?.message)
		}

		_dispatch(setIsLoading(false))
	}

	const [selectedProductGroup, setSelectedProductGroup] = useState('')

	const doGetProductGroup = async () => {
		_dispatch(setIsLoading(true))
		const response = await getProductGroup()

		console.log('getProductGroup', response)
		// console.log('responseresponse', response)
		if (response?.success) {
			const filterOptions = response.data.map((item) => ({
				label: item?.groupName?.split('App-')[1],
				value: item.id
			}))

			const arrangeSequence = filterOptions.find((item) => item.value == editData?.productGroup)
			setSelectedProductGroup(arrangeSequence?.label)
			// setProductGroup(selectedProductGroup?.label)
			setProductGroup(arrangeSequence?.value)
			console.log('filterOptions', filterOptions, selectedProductGroup)
			setProductGroupOptions(filterOptions)
			// toastSuccess(response?.message)
		} else {
			toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}

	const doUpdateBanner = async (imageUrl) => {
		_dispatch(setIsLoading(true))
		let response

		if (imageUrl !== '') {
			response = await updateBanner(editData?.id, label, imageUrl, discount, checkValue, formatDate(expiry), productGroup)
		} else {
			response = await updateBannerWithoutImg(editData?.id, label, discount, checkValue, formatDate(expiry), productGroup)
		}

		console.log('Response from editbanner:', response)

		if (response?.success) {
			setOpenSuccessModal(true)
		} else {
			toastError(response?.message)
		}

		_dispatch(setIsLoading(false))
	}

	useEffect(() => {
		setLabel(editData?.title)
		setDiscount(editData?.discount)
		setExpiry(formatDate(editData?.endDate))
		setCheckedValue(editData?.status)
		setShowImage(editData?.images)
		console.log('image check krwa', profilImage)
		setProductGroup(editData?.productGroup)
		doGetProductGroup()
	}, [])

	return (
		<>
			<Modal
				open={true}
				onClose={onClose}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
				showCloseIcon={false}>
				<ModalWrapper>
					<Container>
						<Row>
							<Col>
								<Heading isCentered color="black" fontSize={1.75} fontWeight="700">
									Edit Banner
								</Heading>
								<Spacer height={2} />
							</Col>
						</Row>
						<Row>
							<Col lg={6}>
								<InputField
									labelType="xlarge"
									value={label}
									label="Label"
									placeholder="Super Flash Sales"
									handleChange={(e) => {
										setLabelError('')
										if (e === '') {
											setLabelError('label is required')
										}
										setLabel(e)
									}}
									error={labelError}
									errorMsg={labelError}
									required
								/>
							</Col>
							<Col lg={6}>
								<DropDown
									height={3.1}
									label={'Product Group'}
									stype={true}
									firstSelected={selectedProductGroup}
									hasChanged={(value) => {
										setProductGroupError('')
										if (value == '') {
											setProductGroupError('Please Select Product Group')
										}
										setProductGroup(value)
									}}
									required
									error={productGroupError}
									errorMsg={productGroupError}
									options={productGroupOptions}
								/>
							</Col>
						</Row>
						<Spacer height={1} />
						<Row>
							<Spacer height={1} />
							<Col lg={6}>
								<InputField
									value={discount}
									labelType="xlarge"
									label="Discount"
									placeholder="50% OFF"
									handleChange={(e) => {
										setDiscountError('')
										if (e === '') {
											setDiscountError('discount is required')
										}
										setDiscount(e)
									}}
									error={discountError}
									errorMsg={discountError}
									required
								/>
								<Spacer height={1} />
							</Col>
							<Spacer height={1} />
							<Col lg={6}>
								<InputField
									value={expiry}
									labelType="xlarge"
									label="Expiry"
									type={`date`}
									minDate={minDate}
									placeholder="08-4-2024"
									handleChange={(e) => {
										setExpiryError('')
										if (e === '') {
											setExpiryError('Email is required')
										}
										setExpiry(e)
									}}
									error={expiryError}
									errorMsg={expiryError}
									required
								/>
							</Col>
						</Row>
						<Spacer height={1} />
						<Scroll>
							<CustomRow>
								<Col md={8} lg={8}>
									<Spacer height={0.5} />
									<Text color="black" fontSize="1.5" fontWeight="700">
										Upload Images
									</Text>
									<Spacer height={1} />
									<InputWrapper>
										<CustomUpload dashed>
											<UploadIcon showImage={showImage || profilImage} src={showImage ? showImage : profilImage ? process.env.REACT_APP_PUBLIC_IMAGE_URL + profilImage : '/icons/mountains.png'} />
											{/* <Text isCentered lineHeight={1} type="small" color="black">
                      Drop your imager here, or browse Jpeg, png are allowed
                    </Text> */}
											{/* <Icon src="icons/add.svg" /> */}
											{/* <FileInput
                      id="faceImage"
                      accept="image/jpeg/png"
                      type="file"
                      onChange={(e) => {
                        handleCapture(e);
                        // setShowImage('')
                      }}
                    /> */}
										</CustomUpload>
									</InputWrapper>
								</Col>
								<Col md={4} lg={4}>
									<Spacer height={1} />
									<InputWrapper>
										<CustomUploaded dashed>
											<PlaceholderIcon src={'/icons/mountains.png'} />
											<Text isCentered lineHeight={1} type="small" color="black">
												Drop your imager here, or browse Jpeg, png are allowed
											</Text>
											{/* <Icon src="icons/add.svg" /> */}
											<FileInput
												id="faceImage"
												accept="image/jpeg/png"
												type="file"
												onChange={(e) => {
													handleCapture(e)
													// setShowImage('')
												}}
											/>
										</CustomUploaded>
									</InputWrapper>
								</Col>
							</CustomRow>
							<Spacer height={2.25} />
							<CustomRow>
								<Col>
									{/* <Checkbox label="You want to live this banner" /> */}

									<CustomCheckBox right={1} height={1.5} width={1.5} setIsChecked={setCheckedValue} isChecked={checkValue} label="You want to live this banner" />
								</Col>
							</CustomRow>
							<Spacer height={2} />
							<CustomRow justifyContent="end">
								<Col lg={2.5}>
									<Button
										width="100%"
										hasBorder
										ifClicked={() => {
											onClose()
										}}
										label="Cancel"
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={2.5}>
									<Button
										width="100%"
										ifClicked={() => {
											console.log('profilImage', profilImage)
											if (profilImage === '') {
												doUpdateBanner('')
											} else doUploadImage()
										}}
										label="Update"
									/>
								</Col>
							</CustomRow>
						</Scroll>
					</Container>
				</ModalWrapper>
			</Modal>
			{openSuccessModal && (
				<SuccessModal
					label="Banner Edited successfully"
					onClose={() => {
						onClose()
						doGetBanners()
						setOpenSuccessModal(false)
					}}
				/>
			)}
		</>
	)
}

const CustomRow = styled(Row)`
	margin-left: 0 !important;
	margin-right: 0 !important;
`

const Scroll = styled.div`
	// overflow-y: auto;

	// height: 22rem;

	&::-webkit-scrollbar {
		width: 0.3rem !important;
		height: 0.1rem !important;
	}

	&::-webkit-scrollbar-track {
		background: #eee;
		border-radius: 1rem;
		border: 0.01rem solid ${palette.silver};
		border-width: 0rem 0.1rem 0rem 0rem;
	}

	&::-webkit-scrollbar-thumb {
		background: ${palette.yellow};
		border-radius: 1rem;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`
const LabelWrapper = styled.div`
	position: absolute;

	top: 8.5rem;
	color: white;

	//   background-color: ${palette.white};
	padding: 0 0.5rem;
	${media.sm`
  left: 2.5rem;
  `}
	${media.md`
  left: 2.5rem;
  `}
  ${media.lg`
  left: 2.5rem;
  `}
`
const PlaceholderIcon = styled.img`
  width: 3rem; /* Set specific width */
  height: 3rem; /* Set specific height */
`;
const InputWrapper = styled.div`
	position: relative;

	// height: 8.5rem;
`

const UploadIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain; /* This will maintain the aspect ratio of the image */
  ${({ showImage }) =>
    showImage &&
    `
    border-radius: 0.375rem;
  `}
`;
const FileInput = styled.input`
	display: none !important;
`

const CustomUploaded = styled.label`
	padding: 1rem;
	height: 12.5rem;
	//   border: 0.063rem dashed ${palette.input_border};
	border-radius: 0.375rem;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.7rem;
	flex-direction: column;
	cursor: pointer;
`
const CustomUpload = styled.label`
	padding: 1rem;
	height: 12.5rem;
	border: 0.063rem dashed ${palette.input_border};
	border-radius: 0.375rem;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.7rem;
	flex-direction: column;
	cursor: pointer;
`

const ShowImage = styled.img`
	width: 100%;
	height: 8.5rem;
	object-fit: cover;
	border-radius: 0.375rem;
`

const CrossIcon = styled(IoMdClose)`
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
`
const IconWrapper = styled.div``
const CustomCol = styled(Col)``

const ImgWrapper = styled.div`
	position: relative;
`

const CustomFlex = styled(Flexed)`
	width: 100% !important;
`

const ModalWrapper = styled.div`
	padding: 1.125rem 0rem;
	height: 100%;
	background: ${palette.white};
`

const Image = styled.img`
	width: 60%;
	// height:15.5rem;
`

export default EditBannerModal
