import React, {useEffect, useState} from 'react'
import {styled} from 'styled-components'
import {Flexed, Spacer, Text} from '../styles/shared'
import {ImExit} from 'react-icons/im'
import {palette} from '../styles/colors'
import SideButton from './common/SideButton'
import {AiOutlineHome} from 'react-icons/ai'
import {PiDatabaseLight} from 'react-icons/pi'
import {AiFillShopping} from 'react-icons/ai'
import { BiSolidCollection } from "react-icons/bi";
import {HiBars3CenterLeft} from 'react-icons/hi2'
import {CiSettings} from 'react-icons/ci'
import {Link, useLocation, useNavigate} from 'react-router-dom'
// import {useDispatch, useSelector} from 'react-redux'
// import Loader from './common/Loader'
import {media} from 'styled-bootstrap-grid'
// import {toastError} from '../styled/toasterStyle'
const SideBar = ({setMenuToggle, menuToggle}) => {
	// const loading = useSelector((state) => state.auth.isLoading)
	// const salesPointTotalAmount = useSelector((state) => state.auth.salesPointTotalAmount)
	// const FullName = useSelector((state) => state.auth.fullName)
	// const role = sessionStorage.getItem('role')
	// const FullName = sessionStorage.getItem('fullName')
	// const profileImage = sessionStorage.getItem('image')
	// const [selectedPage, setSelectedPage] = useState('')
	const _navigate = useNavigate()
	let role = JSON.parse(sessionStorage.getItem('role'))

	const {pathname} = useLocation()
	const handleRefreshClick = (selectedPage) => {
		if (pathname === selectedPage) {
			window.location.reload() // Refresh the page if already on the Banners page
		}
	}
	console.log('role', role)
	// const _dispatch = useDispatch()
	useEffect(() => {}, [])
	return (
		<>
			<WrapperIcon>
				<BarsIcon
					onClick={() => {
						setMenuToggle(!menuToggle)
					}}
				/>
			</WrapperIcon>
			<Wrapper>
				<UpperSection menuToggle={menuToggle}>
					<Flexed direction="row" align="center" justify="center">
						<ImgWrapper>
							<Img src={'icons/logo.png'} />
						</ImgWrapper>
					</Flexed>
				</UpperSection>
				<MenuSection menuToggle={menuToggle}>
					<Flexed gap={1.5}>
						<Scroll>
							<StyledLink
								to={'/dashboard'}
								onClick={() => {
									setMenuToggle(!menuToggle)
									handleRefreshClick('/dashboard')
								}}>
								<SideButton active={pathname === '/dashboard'} icon="icons/dashboard.svg" name="Dashboard" />
							</StyledLink>
							<StyledLink
								to={'/Customers'}
								onClick={() => {
									setMenuToggle(!menuToggle)

									handleRefreshClick('/Customers')
								}}>
								<SideButton active={pathname === '/Customers'} icon="icons/customers.svg" name="Customers" />
							</StyledLink>
							{role && (
								<>
									<StyledLink
										to={'/Products'}
										onClick={() => {
											setMenuToggle(!menuToggle)

											handleRefreshClick('/Products')
										}}>
										<SideButton active={pathname === '/Products'} icon="icons/products.svg" name="Products" />
									</StyledLink>
									<StyledLink
										to={'/collections'}
										onClick={() => {
											setMenuToggle(!menuToggle)

											handleRefreshClick('/collections')
										}}>
										<SideButton active={pathname === '/collections'} icon="icons/collection.svg" name="Collections" />
									</StyledLink>
									<StyledLink
										to={'/Banners'}
										onClick={() => {
											setMenuToggle(!menuToggle)

											handleRefreshClick('/Banners')
										}}>
										<SideButton active={pathname === '/Banners'} icon="icons/banners.svg" name="Banners" />
									</StyledLink>
									<StyledLink
										to={'/SalesPerson'}
										onClick={() => {
											setMenuToggle(!menuToggle)

											handleRefreshClick('/SalesPerson')
										}}>
										<SideButton active={pathname === '/SalesPerson'} icon="icons/salesPerson.svg" name="Sales Person" />
									</StyledLink>
									<StyledLink
										to={'/Campaign'}
										onClick={() => {
											setMenuToggle(!menuToggle)

											handleRefreshClick('/Campaign')
										}}>
										<SideButton active={pathname === '/Campaign'} icon="icons/campaigns.svg" name="Campaign" />
									</StyledLink>
								</>
							)}
							<StyledLink
								to={'/Orders'}
								onClick={() => {
									setMenuToggle(!menuToggle)

									handleRefreshClick('/Orders')
								}}>
								<SideButton active={pathname === '/Orders'} icon="icons/orders.svg" name="Orders" />
							</StyledLink>
							{role && (
								<StyledLink
									to={'/Feedbacks'}
									onClick={() => {
										setMenuToggle(!menuToggle)

										handleRefreshClick('/Feedbacks')
									}}>
									<SideButton active={pathname === '/Feedbacks'} icon="icons/feedbacks.svg" name="Feedbacks" />
								</StyledLink>
							)}
							<StyledLink
								to={'/Settings'}
								onClick={() => {
									setMenuToggle(!menuToggle)

									handleRefreshClick('/Settings')
								}}>
								<SideButton active={pathname === '/Settings'} icon="icons/settings.svg" name="Settings" />
							</StyledLink>
						</Scroll>
					</Flexed>
				</MenuSection>
				{/* <Loader visible={loading} /> */}
			</Wrapper>
		</>
	)
}
const Wrapper = styled.div`
	height: 100%;
`
const WrapperIcon = styled.div`
	${media.xs`
    display: block;
    padding:1rem  1rem 0 1rem;
    `}
	${media.sm`
    display: block;
    padding:1rem  1rem 0 1rem;
    `}
    ${media.md`
     display: block;
    padding:1rem  1rem 0 1rem;
    `}
	${media.lg`
		display: none;
		`}
`
const Scroll = styled.div`
	overflow-y: auto;
	height: calc(100vh - 205.01px);
	&::-webkit-scrollbar {
		width: 0.3rem !important;
		height: 0.1rem !important;
	}
	&::-webkit-scrollbar-track {
		background: #eee;
		border-radius: 1rem;
		border: 0.01rem solid ${palette.silver};
		border-width: 0rem 0.1rem 0rem 0rem;
	}
	&::-webkit-scrollbar-thumb {
		background: ${palette.yellow};
		border-radius: 1rem;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`
const MenuSection = styled.div`
	height: auto; /* Allow scrolling */
	${media.xs`
    display: ${({menuToggle}) => (menuToggle ? 'block' : 'none')};
    padding: 0 1rem;
    `}
	${media.sm`
    display: ${({menuToggle}) => (menuToggle ? 'block' : 'none')};
    padding:  0 1rem;
    `}
    ${media.md`
    display: ${({menuToggle}) => (menuToggle ? 'block' : 'none')};
    padding: 0 1rem;
    `}
	${media.lg`
		display: block;
		padding: 0 1rem;
		`}
    overflow: hidden;
`
const UpperSection = styled.div`
	${media.xs`
padding:  2.5rem 0 ;
display: ${({menuToggle}) => (menuToggle ? 'block' : 'none')};
/* padding: 3.75rem 1rem 0 1rem; */
`}
	${media.sm`
    display: ${({menuToggle}) => (menuToggle ? 'block' : 'none')};
    padding:  2.5rem 0 ;
    `}
    ${media.md`
     display: ${({menuToggle}) => (menuToggle ? 'block' : 'none')};
    padding:  2.5rem 0 ;
    `}
	${media.lg`
		display: block;
		padding:  2.5rem 0 ;
		`}
`
const BarsIcon = styled(HiBars3CenterLeft)`
	font-size: 2rem;
	color: ${palette.yellow};
	cursor: pointer;
`
const Earnings = styled.div`
	padding-bottom: 3.75rem;
	border-bottom: 0.063rem solid ${palette.border};
`
const Img = styled.img`
	width: 7.813rem;
	height: 7.813rem;
	border-radius: 100%;
`
const ImgWrapper = styled.div``
const ExitIcon = styled(ImExit)`
	width: 1.25rem;
	height: 1.25rem;
	cursor: pointer;
`
const StyledLink = styled(Link)`
	all: unset;
`
const SubButtonsContainer = styled.div`
	transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
	opacity: ${(props) => (props.show ? 1 : 0)};
	max-height: ${(props) => (props.show ? '100px' : '0')};
	overflow: hidden;
`
export default SideBar
