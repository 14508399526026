import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { palette } from "../../styles/colors";
import { Col, Container, Row, media } from "styled-bootstrap-grid";
import { addBanner, generateImageLink, getProductGroup } from "../../api/apis";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../actions/authActions";
import { toastError, toastSuccess } from "../../styles/toasterStyle";
import {
  Flexed,
  Heading,
  Spacer,
  Text,
  VerticalSpacer,
} from "../../styles/shared";

import { IoMdClose } from "react-icons/io";
import CustomDropDown from "../common/CustomDropDown";
import Button from "../common/Button";

import InputField from "../common/InputField";
import EditProductForm from "../EditProductForm";
import SuccessModal from "./SuccessModal";
import Checkbox from "../common/CheckBox";
import CustomCheckBox from "../common/CustomCheckBox";
import DropDown from "../common/DropDown";

const AddBannerModal = ({ onClose, doGetBanners, setOpenAddBannerModal }) => {
  const _dispatch = useDispatch();
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [checkValue, setCheckedValue] = useState(false);
  const [profilImage, setProfileImage] = useState("");
  const [showImage, setShowImage] = useState(null);
  const [getImageLink, setImageLink] = useState("");
  // const [images, setImages] = useState(["https://www.the74million.org/wp-content/uploads/2021/08/Untitled-design-2021-08-31T164813.873.png"]);
  const [productGroupOptions, setProductGroupOptions] = useState([]);
  const [productGroup, setProductGroup] = useState("");
  const [label, setLabel] = useState("");
  const [discount, setDiscount] = useState("");
  const [expiry, setExpiry] = useState("");
  const [selectedProductGroup, setSelectedProductGroup] = useState("");
  const [labelError, setLabelError] = useState("");
  const [discountError, setDiscountError] = useState("");
  const [expiryError, setExpiryError] = useState("");
  const [productGroupError, setProductGroupError] = useState("");
  const [minDate, setMinDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toISOString().slice(0, 10); // Convert to yyyy-MM-dd format
    return formattedDate;
  };

  const doGetProductGroup = async () => {
    _dispatch(setIsLoading(true));
    const response = await getProductGroup();

    console.log("getProductGroup", response);
    // console.log('responseresponse', response)
    if (response?.success) {
      const filterOptions = response.data.map((item) => ({
        label: item?.groupName?.split("App-")[1],
        value: item.id,
      }));

      console.log("filterOptions", filterOptions);
      setProductGroupOptions(filterOptions);
      // toastSuccess(response?.message)
    } else {
      toastError(response?.message);
    }
    _dispatch(setIsLoading(false));
  };

  const handleCapture = ({ target }) => {
    const reader = new FileReader();
    reader.readAsDataURL(target.files[0]);
    reader.onload = () => {
      if (reader.readyState === 2) {
        setShowImage(reader.result);
        setProfileImage(reader.result);
      }
    };
  };
  const doUploadImage = async () => {
    _dispatch(setIsLoading(true));

    const response = await generateImageLink(showImage);

    console.log("Response from addCompaign image:", response?.data);
    setImageLink(response?.data);
    console.log("image ka link show ho", getImageLink);
    if (response?.success) {
      setOpenSuccessModal(true);
      doAddBanner(response?.data);
    } else {
      toastError(response?.message);
    }

    _dispatch(setIsLoading(false));
  };
  const doAddBanner = async (imageUrl) => {
    _dispatch(setIsLoading(true));

    const response = await addBanner(
      label,
      imageUrl,
      discount,
      checkValue,
      formatDate(expiry),
      productGroup
    );

    console.log("Response from addbanner:", response);

    if (response?.success) {
      setLabel("");
      setDiscount("");
      setExpiry("");
      setCheckedValue("");
      // doGetBanners()

      setOpenSuccessModal(true);
    } else {
      toastError(response?.message);
    }

    _dispatch(setIsLoading(false));
  };

  const checkValidationOnClick = () => {
    let isValid = true;
    if (label === "") {
      setLabelError("label is required");
      isValid = false;
    }

    if (discount === "") {
      setDiscountError("discount is required");
      isValid = false;
    }
    if (expiry === "") {
      setExpiryError("expiry date is required");
      isValid = false;
    }
    if (productGroup === "") {
      setProductGroupError("Please Select Product Group");
      isValid = false;
    }

    return isValid;
  };

  useEffect(() => {
    doGetProductGroup();
  }, []);
  useEffect(() => {
    const arrangeSequence = productGroupOptions.find(
      (item) => item.value == productGroup
    );
    setSelectedProductGroup(arrangeSequence?.label);
  }, [productGroup]);
  return (
    <>
      <Modal
        open={true}
        onClose={onClose}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
        }}
        showCloseIcon={false}
      >
        <ModalWrapper>
          <Container>
            <Row>
              <Col>
                <Heading
                  isCentered
                  color="black"
                  fontSize={1.75}
                  fontWeight="700"
                >
                  Add Banner
                </Heading>
                <Spacer height={2} />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <InputField
                  labelType="xlarge"
                  value={label}
                  label="Label"
                  placeholder="Super Flash Sales"
                  handleChange={(e) => {
                    setLabelError("");
                    if (e === "") {
                      setLabelError("label is required");
                    }
                    setLabel(e);
                  }}
                  error={labelError}
                  errorMsg={labelError}
                  required
                />
              </Col>
              <Col lg={6}>
                <DropDown
                  height={3.1}
                  label={"Product Group"}
                  stype={true}
                  firstSelected={selectedProductGroup}
                  hasChanged={(value) => {
                    setProductGroupError("");
                    if (value == "") {
                      setProductGroupError("Please Select Product Group");
                    }
                    setProductGroup(value);
                  }}
                  required
                  error={productGroupError}
                  errorMsg={productGroupError}
                  options={productGroupOptions}
                />
              </Col>
            </Row>
            <Spacer height={1} />
            <Row>
              <Spacer height={1} />
              <Col lg={6}>
                <InputField
                  value={discount}
                  labelType="xlarge"
                  label="Discount"
                  placeholder="50% OFF"
                  handleChange={(e) => {
                    setDiscountError("");
                    if (e === "") {
                      setDiscountError("discount is required");
                    }
                    setDiscount(e);
                  }}
                  error={discountError}
                  errorMsg={discountError}
                  required
                  allowOnlyNumbers
                />
                <Spacer height={1} />
              </Col>
              <Spacer height={1} />
              <Col lg={6}>
                <InputField
                  value={expiry}
                  labelType="xlarge"
                  type={`date`}
                  label="Expiry"
                  minDate={minDate}
                  placeholder="08-4-2024"
                  handleChange={(e) => {
                    setExpiryError("");
                    if (e === "") {
                      setExpiryError("expiry date is required");
                    }
                    setExpiry(e);
                  }}
                  error={expiryError}
                  errorMsg={expiryError}
                  required
                />
              </Col>
            </Row>
            <Spacer height={1} />
            <Row>
              <Col>
                <Text color="black" fontSize="1.5" fontWeight="700">
                  Upload Image
                </Text>
                <Spacer height={1} />
                <InputWrapper>
                  {!showImage && !profilImage && (
                    <LabelWrapper>
                      <Text
                        isCentered={true}
                        lineHeight={1}
                        type="small"
                        color="black"
                      >
                        Drop your image here, or browse. Jpeg, png are allowed
                      </Text>
                    </LabelWrapper>
                  )}
                  <CustomUpload dashed>
                    {showImage || profilImage ? (
                      <UploadIcon
                        showImage={showImage || profilImage}
                        src={
                          showImage
                            ? showImage
                            : profilImage
                            ? process.env.REACT_APP_PUBLIC_IMAGE_URL +
                              profilImage
                            : "/icons/mountains.png"
                        }
                      />
                    ) : (
                      <PlaceholderIcon src="/icons/mountains.png" />
                    )}
                    {/* <Icon src="icons/add.svg" /> */}
                    <FileInput
                      id="faceImage"
                      accept="image/jpeg/png"
                      type="file"
                      onChange={(e) => {
                        handleCapture(e);
                        // setShowImage('')
                      }}
                    />
                  </CustomUpload>
                </InputWrapper>
              </Col>
            </Row>
            <Spacer height={2.25} />
            <Row>
              <Col>
                <CustomCheckBox
                  right={1}
                  height={1.5}
                  width={1.5}
                  setIsChecked={() => setCheckedValue(!checkValue)}
                  isChecked={checkValue ? true : false}
                  label="You want to live this banner"
                />
              </Col>
            </Row>
            <Spacer height={2} />
            <Row justifyContent="end">
              <Col lg={2.5}>
                <Button
                  width="100%"
                  hasBorder
                  ifClicked={() => {
                    onClose();
                  }}
                  label="Cancel"
                />
                <Spacer height={1} />
              </Col>
              <Col lg={2.5}>
                <Button
                  width="100%"
                  ifClicked={() => {
                    if (checkValidationOnClick()) doUploadImage();
                    // setOpenSuccessModal(true);
                  }}
                  label="Add"
                />
              </Col>
            </Row>
          </Container>
        </ModalWrapper>
      </Modal>
      {openSuccessModal && (
        <SuccessModal
          label="Banner Added successfully"
          onClose={() => {
            doGetBanners();
            setOpenSuccessModal(false);
            setOpenAddBannerModal(false);
          }}
        />
      )}
    </>
  );
};

const LabelWrapper = styled.div`
  position: absolute;
  top: 10rem;
  color: white;
  display: center;
  justify-content: center;
  width: 100%;
  // flex-direction: ${({ direction }) => direction};
  // align-items: ${({ align }) => align};
  //   background-color: ${palette.white};
  // padding: 0 0.5rem;
  // left: 35%;
`;

const InputWrapper = styled.div`
  position: relative;

  // height: 8.5rem;
`;

const UploadIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain; /* This will maintain the aspect ratio of the image */
  ${({ showImage }) =>
    showImage &&
    `
    border-radius: 0.375rem;
  `}
`;
const FileInput = styled.input`
  display: none !important;
`;

const CustomUpload = styled.label`
  padding: 1rem;
  height: 12.5rem;
  border: 0.063rem dashed ${palette.input_border};
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
  flex-direction: column;
  cursor: pointer;
`;
const PlaceholderIcon = styled.img`
  width: 3rem; /* Set specific width */
  height: 3rem; /* Set specific height */
`;
const ShowImage = styled.img`
  width: 100%;
  height: 8.5rem;
  object-fit: cover;
  border-radius: 0.375rem;
`;

const CrossIcon = styled(IoMdClose)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;
const IconWrapper = styled.div``;
const CustomCol = styled(Col)``;

const ImgWrapper = styled.div`
  position: relative;
`;

const CustomFlex = styled(Flexed)`
  width: 100% !important;
`;

const ModalWrapper = styled.div`
  padding: 1.125rem 0rem;
  height: 100%;
  background: ${palette.white};
`;

const Image = styled.img`
  width: 60%;
  // height:15.5rem;
`;

export default AddBannerModal;
