import React, {useEffect, useState} from 'react'
import {Container, media} from 'styled-bootstrap-grid'
import {styled} from 'styled-components'
import {palette} from '../styles/colors'
import SearchSection from '../components/common/SearchSection'
import {Flexed, Spacer, Text} from '../styles/shared'
import Button from '../components/common/Button'
import Toggle from '../components/common/Toggle'
import CampaignsTable from '../components/tables/CampaignsTable'
import AddCompaign from '../components/AddCompaign'
import EditCompaign from '../components/EditCompaign'
import DeleteModal from '../components/modals/DeleteModal'
import {deleteCampaign, getCompaigns, resendCampaign} from '../api/apis'
import {useDispatch} from 'react-redux'
import {setIsLoading} from '../actions/authActions'
import {toastError, toastSuccess} from '../styles/toasterStyle'

const Campaign = () => {
	const [search, setSearch] = useState('')
	const [showAddCompaignForm, setShowAddCompaignForm] = useState(false)
	const [deleteModalOpen, setDeleteModalOpen] = useState(false)
	const [showEditCompaignForm, setShowEditCompaignForm] = useState(false)
	const [editData, setEditData] = useState({})
	const [tableTest, setTableTest] = useState([])
	const [deleteCampaignId, setDeleteCampaignId] = useState(null)
	
	const [resendCampaignId, setResendCampaignId] = useState(null)
	const [pageNumber, setPageNumber] = useState(1)
	const [pagesAmount, setPagesAmount] = useState(1)
	const [totalNumberOfCampaigns, setTotalNumberOfCampaigns] = useState(1)
    console.log("resend compaign",resendCampaignId)
	useEffect(() => {
		doGetCompaigns()
	}, [pageNumber,search])

	const doGetCompaigns = async () => {
		_dispatch(setIsLoading(true))

		const response = await getCompaigns(pageNumber, 6,search)
		//	console.log('Response from getCompaigns:', response)

		if (response?.success) {
			setPagesAmount(Math.ceil(response?.count / 6))
			setTotalNumberOfCampaigns(response?.count)
			const apiRows = response?.data.map((compaign, index) => ({
				no: (
					<Text type="medium" color="black" fontWeight={300}>
						{((pageNumber-1)*6)+index+1}
					</Text>
				),
				notificationName: (
					<Text type="medium" color="black" fontWeight={300}>
						{compaign?.compaignName}
					</Text>
				),
				notificationText: (
					<Text type="medium" color="black" fontWeight={300}>
						{compaign?.compaignBody}
					</Text>
				),
				notificationTitle: (
					<Text type="medium" color="black" fontWeight={300}>
						{compaign?.compaignTitle}
					</Text>
				),
				creationDate: (
					<Text type="medium" color="black" fontWeight={300}>
						{compaign?.createdAt}
					</Text>
				),
				icon: (
					<Flexed direction="row" align="center" gap={0.75} justify="flex-start">
							<IconWrapper
							onClick={() => {
								// setResendCampaignId(compaign?.id)
								doReloadCampaign(compaign?.id)
							}}>
							<Icon src="icons/refresh.png" />
						</IconWrapper>
						
						<IconWrapper
							onClick={() => {
								setEditData(compaign)
								setShowEditCompaignForm(true)
							}}>
							<Icon src="icons/eye.svg" />
						</IconWrapper>
						<IconWrapper
							onClick={() => {
								setDeleteCampaignId(compaign?.id)
								setDeleteModalOpen(true)
							}}>
							<Icon src="icons/delete.svg" />
						</IconWrapper>
					</Flexed>
				)
			}))
			setTableTest(apiRows)
			// toastSuccess(response?.message)
		} else {
			toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}

	const _dispatch = useDispatch()

	const doDeleteCampaign = async () => {
		_dispatch(setIsLoading(true))
		const response = await deleteCampaign(deleteCampaignId)

		console.log('responseresponse', response)
		// console.log('responseresponse', response)
		if (response?.success) {
			setDeleteModalOpen(false)
			doGetCompaigns()
			setDeleteCampaignId(null)

			toastSuccess(response?.message)
		} else {
			toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}

	const doReloadCampaign = async (id) => {
		_dispatch(setIsLoading(true))
		const response = await resendCampaign(id)

		console.log('responseresponse', response)
		// console.log('responseresponse', response)
		if (response?.success) {
			// setDeleteModalOpen(false)
			doGetCompaigns()
			// setResendCampaignId(null)
			toastSuccess(response?.message)
		} else {
			toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}


	return (
		<Wrapper>
			<Container fluid>
				<SearchSection title="Campaign" search={search} setSearch={setSearch} />
				<Spacer height={0.625} />
				{showAddCompaignForm === false && showEditCompaignForm === false ? (
					<>
						<Button
							ifClicked={() => {
								setShowAddCompaignForm(true)
							}}
							small
							width="11rem"
							icon="campaignsButton.svg"
							label="Add Campaign"
						/>

						<Spacer height={0.625} />
						<TableWrapper>
							<CampaignsTable tableTest={tableTest} pageNumber={pageNumber} setPageNumber={setPageNumber} pagesAmount={pagesAmount} totalNumberOfCampaigns={totalNumberOfCampaigns} />
						</TableWrapper>
					</>
				) : (
					<>
						{showAddCompaignForm === true && (
							<>
								<Spacer height={2.5} />
								<AddCompaign
									doGetCompaigns={doGetCompaigns}
									showAddCompaignForm={() => {
										setShowAddCompaignForm(false)
									}}
								/>
							</>
						)}

						{showEditCompaignForm === true && (
							<>
								<Spacer height={2.5} />
								<EditCompaign
									editData={editData}
									setEditData={setEditData}
									setShowEditCompaignForm={() => {
										setShowEditCompaignForm(false)
									}}
								/>
							</>
						)}
					</>
				)}
			</Container>
			{deleteModalOpen && (
				<DeleteModal
					onSubmit={() => {
						doDeleteCampaign()
					}}
					label="Are you sure you want to delete this campaign"
					onClose={() => {
						setDeleteModalOpen(false)
					}}
				/>
			)}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	background-color: ${palette.lightGrey};
	min-height: 100vh;
	${media.xs`
	padding: 1rem 1rem;
	`}
	${media.sm`
	padding: 1rem 1rem;
	`}
	${media.md`
	padding: 1.625rem 1.25rem 2.625rem 1.25rem;
	`}
`

const ProductImage = styled.img`
	width: 75px;
	height: 75px;
`

const TableWrapper = styled.div`
	border-radius: 0.625rem;
	background-color: ${palette.white};
	padding: 0.75rem;
`

const IconWrapper = styled.div`
	background-color: ${palette.black};
	border-radius: 0.625rem;
	width: 2.25rem;
	height: 2.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
`

const Icon = styled.img`
	width: 1.25rem;
	height: 1.25rem;
	cursor: pointer;
`

export default Campaign
