import './App.css'
import {Route, Routes, json} from 'react-router-dom'
import RenderedComponents from './pages/RenderedComponents'
import {styled} from 'styled-components'
import PageLayout from './components/PageLayout'
import DashBoard from './pages/DashBoard'
import Customers from './pages/Customers'
import Campaign from './pages/Campaign'
import Collections from './pages/Collections'
import Orders from './pages/Orders'
import Settings from './pages/Settings'
import Feedbacks from './pages/Feedbacks'
import Products from './pages/Products'
import SalesPerson from './pages/SalesPerson'
import Banners from './pages/Banners'
import Login from './pages/Login'
import ForgotPassword from './pages/ForgotPassword'
import NewPassword from './pages/NewPassword'
import BacktoLogin from './pages/BacktoLogin'
import Otp from './pages/Otp'
import Loader from './components/common/Loader'
import {useSelector} from 'react-redux'
import ProtectedRoutes from './components/ProtectedRoutes'
import {useEffect, useState} from 'react'
import { setlatestMessage, setNotificationsMessages } from './actions/authActions';
import { useDispatch } from 'react-redux';
import { requestFirebaseNotificationPermission,handleFirebaseMessages } from './firebase/messaging'

import { onMessage } from 'firebase/messaging';

import { messaging } from './firebase/firebase';


function App() {
	const dispatch = useDispatch();
	const userName = useSelector((state) => state.auth.userName)
	const loading = useSelector((state) => state.auth.isLoading)
	const latestMessage = useSelector((state) => state.auth.latestMessage);
	let isUserLogIn = sessionStorage.getItem('authorization')
	let User = sessionStorage.getItem('User')
	let role = JSON.parse(sessionStorage.getItem('role'))
	// let isUserLogIn = true

	useEffect(() => {
		requestFirebaseNotificationPermission()
		  .then((token) => {
			console.log('FCM token:', token);
			localStorage.setItem('fcmToken', token)
		  })
		  .catch((err) => {
			console.error('Error obtaining FCM token:', err);
		  });
	
		  handleFirebaseMessages();
		  
	  }, []);

	useEffect(() => {
		isUserLogIn = sessionStorage.getItem('authorization')
		role = JSON.parse(sessionStorage.getItem('role'))
	}, [sessionStorage.getItem('authorization')])
	// const isUserLogIn = true



	const [messages, setMessages] = useState([]);
	const [socket, setSocket] = useState(null);
  
	useEffect(() => {
		if(isUserLogIn)
			{
	  // Create WebSocket connection.
	  const ws = new WebSocket(`wss://${process.env.REACT_APP_WS_URL}/ws/notifications/${User}/`);
	  setSocket(ws);
  
	  // Connection opened
	  ws.onopen = () => {
		console.log('Connected to the WebSocket server');
	  };
  
	  // Listen for messages
	  ws.onmessage = (event) => {
		const message = event.data;
		const test= JSON.parse(message)
		dispatch(setlatestMessage(test?.message))
		dispatch(setNotificationsMessages(true))
		setMessages((prevMessages) => [...prevMessages, message]);
	  };

  
	  // Connection closed
	  ws.onclose = () => {
		console.log('Disconnected from the WebSocket server');
	  };
  
	  // Handle errors
	  ws.onerror = (error) => {
		console.error('WebSocket error', error);
	  };
  
	  // Cleanup on component unmount
	  return () => {
		ws.close();
	  };
	}
	  console.log('userName', userName)
	}, [isUserLogIn]);
  
	const sendMessage = (msg) => {
	  if (socket && socket.readyState === WebSocket.OPEN) {
		socket.send(msg);
	  } else {
		console.error('WebSocket is not open');
	  }
	};

	return (
		<>
			<Wrapper>
				<PageLayout>
					<Routes>
						<Route
							path="/dashboard"
							element={
								<ProtectedRoutes isLoggedIn={isUserLogIn}>
									<DashBoard />
								</ProtectedRoutes>
							}></Route>
						<Route path="/" element={<Login />}></Route>
						<Route path="/forgotpassword" element={<ForgotPassword />}></Route>
						<Route path="/newpassword" element={<NewPassword />}></Route>
						<Route path="/backtologin" element={<BacktoLogin />}></Route>
						<Route path="/enterotp" element={<Otp />}></Route>
						<Route
							path="/Customers"
							element={
								<ProtectedRoutes isLoggedIn={isUserLogIn}>
									<Customers />
								</ProtectedRoutes>
							}></Route>
						<Route
							path="/Products"
							element={
								<ProtectedRoutes isLoggedIn={isUserLogIn && role}>
									<Products />
								</ProtectedRoutes>
							}></Route>
								<Route
							path="/collections"
							element={
								<ProtectedRoutes isLoggedIn={isUserLogIn && role}>
									<Collections />
								</ProtectedRoutes>
							}></Route>
						<Route
							path="/SalesPerson"
							element={
								<ProtectedRoutes isLoggedIn={isUserLogIn && role}>
									<SalesPerson />
								</ProtectedRoutes>
							}></Route>
						<Route
							path="/Banners"
							element={
								<ProtectedRoutes isLoggedIn={isUserLogIn && role}>
									<Banners />
								</ProtectedRoutes>
							}></Route>
						<Route
							path="/Campaign"
							element={
								<ProtectedRoutes isLoggedIn={isUserLogIn && role}>
									<Campaign />
								</ProtectedRoutes>
							}></Route>
						<Route
							path="/Orders"
							element={
								<ProtectedRoutes isLoggedIn={isUserLogIn}>
									<Orders />
								</ProtectedRoutes>
							}></Route>
						<Route
							path="/Feedbacks"
							element={
								<ProtectedRoutes isLoggedIn={isUserLogIn && role}>
									<Feedbacks />
								</ProtectedRoutes>
							}></Route>
						<Route
							path="/Settings"
							element={
								<ProtectedRoutes isLoggedIn={isUserLogIn}>
									<Settings />
								</ProtectedRoutes>
							}></Route>
					</Routes>
				</PageLayout>
			</Wrapper>
			<Loader visible={loading} />
		</>
	)
}

const Wrapper = styled.div`
	height: 100%;
	width: 100%;
`

export default App
