// firebase/messaging.js
import { getToken, onMessage } from 'firebase/messaging';

import { messaging } from './firebase';



// Request permission and get FCM token
export const requestFirebaseNotificationPermission = () => {
    return new Promise((resolve, reject) => {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          getToken(messaging, { vapidKey: 'BIoOoU3ugqOeCFaz-EYmvnmfSXJC_nBxkOYaI2Pj1b8ezJiTL6twsPMICWLErDDGWsXZX0L2fYWT-m6N_fgo-0U' })
            .then((currentToken) => {
              if (currentToken) {
                resolve(currentToken);
              } else {
                reject('No registration token available.');
              }
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          reject('Permission denied.');
        }
      });
    });
  };
  
  // Handle incoming messages
  export const handleFirebaseMessages = () => {
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
  
      // Customize the notification here (e.g., using Notification API)
      const { title, body } = payload.notification;
      new Notification(title, { body });
    });
  };
