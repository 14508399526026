import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { palette } from "../../styles/colors";
import { Col, Container, Row } from "styled-bootstrap-grid";
import {
  Flexed,
  Heading,
  Spacer,
  Text,
  VerticalSpacer,
} from "../../styles/shared";


const WaitgettingProductModal = ({ onClose, label }) => {
 
  return (
    <>
      <Modal
      closeOnOverlayClick ={false}
      closeOnEsc={false}
        open={true}
        onClose={onClose}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "successModal",
        }}
        showCloseIcon={false}
      >
        <ModalWrapper>
          
              <Spacer height={2.5} />
          <Wrapper>
            <Flexed direction="column" justify="center" align="center">
              <Image src="./icons/Waiting.gif" alt="profile" />
              <Spacer height={4.125} />
              <CustomText
                isCentered
                color="black"
                fontWeight={750}
                fontSize={1.5}
                lineHeight={2}
               
              >
                Please wait we are getting product from unleased. It will take
                2~3 minutes.
              </CustomText>
              <Spacer height={2.5} />
            </Flexed>
          </Wrapper>
        </ModalWrapper>
      </Modal>
    </>
  );
};


const Wrapper = styled(Container)`
  padding: 0;
`;

const ModalWrapper = styled.div`
  padding: 1.125rem 0rem;
  height: 100%;
  background: ${palette.white};
`;
const Image = styled.img``;

const CustomText=styled(Text)`
width:80%;
`
const Icon = styled.img`
  width: 0.875rem;
  height: 0.875rem;
  position: absolute; /* Make sure to specify a position */
  right: 3rem; /* Adjust this value to move the icon further to the right */
cursor:  pointer;
`;
export default WaitgettingProductModal;
