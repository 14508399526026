import {Navigate} from 'react-router-dom'
import DashBoard from '../pages/DashBoard'
const ProtectedRoutes = ({isLoggedIn, children}) => {
	console.log('children', isLoggedIn, children?.type?.name)
	if (!isLoggedIn) {
		return <Navigate to="/" replace />
	}

	return children
}
export default ProtectedRoutes
