import React, {useState} from 'react'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import {styled} from 'styled-components'
import {palette} from '../styles/colors'
import SearchSection from '../components/common/SearchSection'
import InputField from './common/InputField'
import {Flexed, Heading, Spacer} from '../styles/shared'
import Button from './common/Button'
import SuccessModal from './modals/SuccessModal'
import { addSalesPerson } from '../api/apis'
import {useDispatch} from 'react-redux'
import {setIsLoading} from '../actions/authActions'
import {toastError, toastSuccess} from '../styles/toasterStyle'

const AddSalesPerson = ({setShowAddSalesPersonForm , doGetSalesPerson}) => {
	const _dispatch = useDispatch()
	const [salesPersonName, setSalesPersonName] = useState('')
	const [salesPersonEmail, setSalesPersonEmail] = useState('')
	
	const [salesPersonCode, setSalesPersonCode] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	

	const [salesPersonNameError, setSalesPersonNameError] = useState('')
	const [salesPersonEmailError, setSalesPersonEmailError] = useState('')

	const [salesPersonCodeError, setSalesPersonCodeError] = useState('')
	const [phoneNumberError, setPhoneNumberError] = useState('')

	const [openSuccessModal, setOpenSuccessModal] = useState(false)

	const checkValidationOnClick = () => {
		let isValid = true;
	
		if (salesPersonName === "") {
			setSalesPersonNameError("name is required");
		  isValid = false;
		}
		if (salesPersonCode === "") {
		  setSalesPersonCodeError("code is required");
		  isValid = false;
		}
		if (salesPersonEmail === "") {
		  setSalesPersonEmailError("email is required");
		  isValid = false;
		}
		if (phoneNumber === "") {
		  setPhoneNumberError("phone number is required");
		  isValid = false;
		}
	
		return isValid;
	  };

	  const doAddSalesPerson = async () => {
		_dispatch(setIsLoading(true))
		const response = await addSalesPerson(salesPersonName,salesPersonEmail, salesPersonCode,  phoneNumber)

		console.log('responseresponse', response)
		// console.log('responseresponse', response)
		if (response?.success) {
			setOpenSuccessModal(true)
			doGetSalesPerson();
			// toastSuccess(response?.message)

		} else {
			toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}


	return (
		<>
			<Form>
				<Spacer height={1}/>
				<Heading color="black" fontSize={1.5} >
					Add Sales Person
				</Heading>
				<Spacer height={2.125} />
				<Flexed direction="column" justify="space-between" height={`100%`}>

		      <CustomFlexed direction="column">
				<Row>
					<Col lg={6} md={12}>
						<InputField
							labelType="xlarge"
							label="Enter Your Name"
							value={salesPersonName}
							placeholder="Name"
							handleChange={(e) => {
								setSalesPersonNameError('')
								if (e === '') {
									setSalesPersonNameError('name is required')
								}
								setSalesPersonName(e)
							}}
							error={salesPersonNameError}
							errorMsg={salesPersonNameError}
							required
						/>

						<Spacer height={1} />
					</Col>
					<Col lg={6} md={12}>
						<InputField
							labelType="xlarge"
							label="Enter Your Email"
							value={salesPersonEmail}
							placeholder="Email"
							handleChange={(e) => {
								setSalesPersonEmailError('')

								const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
								if (e && e.match(isValidEmail)) {
									setSalesPersonEmailError('')
									setSalesPersonEmail(e)
								} else {
									setSalesPersonEmail(e)
									setSalesPersonEmailError('email is not correct')
								}
								if (e === '') {
									setSalesPersonEmailError('email is required')
								}
							}}
							
							error={salesPersonEmailError}
							errorMsg={salesPersonEmailError}
							required
						/>
					</Col>
				</Row>

				<Spacer height={1} />
				<Row>
					<Col lg={6} md={12}>
						<InputField
							labelType="xlarge"
							label="Sales Person Code"
							value={salesPersonCode}
							placeholder="Code"
							handleChange={(e) => {
								setSalesPersonCodeError('')
								if (e === '') {
									setSalesPersonCodeError('code is required')
								}
								setSalesPersonCode(e)
							}}
							error={salesPersonCodeError}
							errorMsg={salesPersonCodeError}
							required
							allowOnlyNumbers
						/>
					</Col>
					<Col lg={6} md={12}>
					<CustomSpacer height={1} />
						<InputField
							labelType="xlarge"
							label="Enter Phone Number"
							value={phoneNumber}
							
							placeholder="Phone Number"
							handleChange={(e) => {
								setPhoneNumberError('')
								if (e === '') {
									setPhoneNumberError('phone number is required')
								}
								setPhoneNumber(e)
							}}
							error={phoneNumberError}
							errorMsg={phoneNumberError}
							required
							maxLength={11}
							allowPhoneNumberOnly
						/>
					</Col>
				</Row>
				</CustomFlexed>
                 <CustomSpacer height={2}/>
				<CustomRow justifyContent="around">
					<Col lg={2.5}>
						<Button
							label="Cancel"
							hasBorder
							ifClicked={() => {
								setShowAddSalesPersonForm()
							}}
							width="100%"
						/>
					</Col>
					<Col lg={2.5}>
							<CustomSpacer height={1}/>
						<Button
							label="Add Sales Person"
							ifClicked={() => {
						if(checkValidationOnClick())
								{doAddSalesPerson()}
							}}
							width="100%"
						/>
					</Col>
				</CustomRow>
				</Flexed>
			</Form>
			{openSuccessModal && (
				<SuccessModal
					onClose={() => {
						setOpenSuccessModal(false)
						setShowAddSalesPersonForm()
					}}
					label="New sales person add successfully"
				/>
			)}
		</>
	)
}

const Form = styled.div`
	background-color: ${palette.white};
	border-radius: 10px;
	height: 80vh;

	${media.xs`
  padding: 1rem 1rem;
  	height:auto;
  `}
	${media.sm`
  padding: 1rem 1rem;
  height:auto;
  `}
  ${media.md`
  padding: 2.625rem 3rem 2.625rem 3rem;
    	min-height: 80vh;
  `}
    ${media.lg`

    	height: 80vh;
  `}
`
const CustomFlexed=styled(Flexed)`
// @media screen and (max-width: 1050px) {
// 	display:flex;

//   }
s
`
const CustomSpacer = styled(Spacer)`
display:none;
	${media.xs`
display:block;
  `}
	${media.sm`
	display:block;
  `}
  ${media.md`
  display:block;
  `}
  ${media.lg`
  display:none;
  `}
`
const CustomRow=styled(Row)`
padding-bottom:2rem;
`
const Wrapper = styled.div`
	background-color: ${palette.lightGrey};
	height: 100vh;
	${media.xs`
	padding: 1rem 1rem;
	`}
	${media.sm`
	padding: 1rem 1rem;
	`}
	${media.md`
	padding: 1.625rem 1.25rem 2.625rem 1.25rem;
	`}
`

const TableWrapper = styled.div`
	border-radius: 0.625rem;
	background-color: ${palette.white};
	padding: 0.75rem;
`

const IconWrapper = styled.div`
	background-color: ${palette.black};
	border-radius: 0.625rem;
	width: 2.25rem;
	height: 2.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
`

const Icon = styled.img`
	width: 1.25rem;
	height: 1.25rem;
`
export default AddSalesPerson
