import React from 'react'
import CanvasJSReact from '@canvasjs/react-charts'
//var CanvasJSReact = require('@canvasjs/react-charts');

var CanvasJS = CanvasJSReact.CanvasJS
var CanvasJSChart = CanvasJSReact.CanvasJSChart

const StatsChart = () => {
	const options = {
		animationEnabled: true,
		title: {
			// text: 'Nuclear Electricity Generation in US'
		},
		axisX: {
			tickColor: 'none',
			lineThickness: 0
		},
		axisY: {
			// title: 'Net Generation (in Billion kWh)',
			suffix: '',
			tickColor: 'none',
			lineThickness: 0,
			gridDashType: 'dash',
			gridColor: '#BDBDBD'
		},
		data: [
			{
				type: 'splineArea',
				markerType: 'none',
				color: '#FDEBCF',
				xValueFormatString: 'YYYY',
				yValueFormatString: '#,##0.## bn kW⋅h',
				showInLegend: false,
				animationEnabled: true,
				animationDuration: 2000,
				// legendText: 'kWh = one kilowatt hour',
				dataPoints: [
					{x: new Date(2008, 0), y: 30.735},
					{x: new Date(2009, 0), y: 40.102},
					{x: new Date(2010, 0), y: 50.569},
					{x: new Date(2011, 0), y: 62.743},
					{x: new Date(2012, 0), y: -10.381},
					{x: new Date(2013, 0), y: -21.406},
					{x: new Date(2014, 0), y: -33.163},
					{x: new Date(2015, 0), y: 74.27},
					{x: new Date(2016, 0), y: 72.525},
					{x: new Date(2017, 0), y: 73.121}
				]
			}
		]
	}
	return (
		<div>
			<CanvasJSChart
				options={options}
				/* onRef={ref => this.chart = ref} */
			/>
			{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
		</div>
	)
}

export default StatsChart
