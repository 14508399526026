import React from 'react'
import {MDBDataTable, MDBIcon} from 'mdbreact'
import {FaArrowRight} from 'react-icons/fa' // Assuming you're using Font Awesome icons
import {palette} from '../../styles/colors'
import {styled} from 'styled-components'
import CustomPagination from '../CustomPagination'
import {Spacer} from '../../styles/shared'

const CampaignsTable = ({tableTest, pageNumber, pagesAmount, totalNumberOfCampaigns, setPageNumber}) => {
	const data = {
		columns: [
			{
				label: 'No',
				field: 'no'
				// width: 150
			},

			{
				label: 'Notification Title',
				field: 'notificationTitle'
				// width: 150
			},
			{
				label: 'Notification Text',
				field: 'notificationText'
				// width: 150
			},
			{
				label: 'Notification Name',
				field: 'notificationName'
				// width: 250
			},
			{
				label: 'Creation Date',
				field: 'creationDate'
				// width: 40
			},

			{
				label: 'Actions',
				field: 'icon'
				// width: 10
			}
		],
		rows: tableTest
	}

	const paginationIcons = [<MDBIconCustom icon="angle-left" size="lg" />, <MDBIconCustom icon="angle-right" size="lg" />]

	return (
		<>
		<MDBDataTable paginationLabel={paginationIcons} displayEntries={false} responsive entries={6} responsiveSm noBottomColumns={true} hover small data={data} sortable={false} searching={false} />
		<Spacer height={1.3} />
			<CustomPagination tableData={tableTest} totalData={totalNumberOfCampaigns} pagesAmount={pagesAmount} pageNumber={pageNumber} setPageNumber={setPageNumber} /></>
	)
}

const MDBIconCustom = styled(MDBIcon)`
	background: ${palette.black};
	padding: 0 !important;
	width: 34.19px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 37.03px;
	color: ${palette.yellow};
`

export default CampaignsTable
