import React, {useEffect, useState} from 'react'
import {Container, media} from 'styled-bootstrap-grid'
import {styled} from 'styled-components'
import {palette} from '../styles/colors'
import SearchSection from '../components/common/SearchSection'
import {Flexed, Spacer, Text} from '../styles/shared'
import Button from '../components/common/Button'
import CustomersTable from '../components/tables/CustomersTable'
import Toggle from '../components/common/Toggle'
import DropDown from '../components/common/DropDown'
import CustomDropDown from '../components/common/CustomDropDown'
import ProfileViewModal from '../components/modals/ProfileViewModal'
import DeleteModal from '../components/modals/DeleteModal'
import AddCustomer from '../components/AddCustomer'
import {useDispatch} from 'react-redux'
import {setIsLoading} from '../actions/authActions'
import {toastError, toastSuccess} from '../styles/toasterStyle'
import {deleteCustomer, getCustomer, updateCustomer, updateCustomerStatus, getAllSPCodes} from '../api/apis'
import CustomToggleBar from '../components/CustomToggleBar'
import {useSelector} from 'react-redux'
import { setlatestMessage } from '../actions/authActions';

const Customers = () => {
	const [search, setSearch] = useState('')
	const latestMessage = useSelector((state) => state.auth.latestMessage);
	const recievedMessages = useSelector((state) => state.auth.recievedMessages);
	const [isProfileViewModalOpen, setIsProfileViewModalOpen] = useState(false)
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
	const [deleteCustomerId, setDeleteCustomerId] = useState(null)
	const [showAddCustomerForm, setShowAddCustomerForm] = useState(false)
	const [selectedProfile, setSelectedProfile] = useState(null)
	const [totalNumberOfCustomers, setTotalNumberOfCustomers] = useState(0)
	const [spCodes, setSpCodes] = useState(0)
	const [selectedStatusValue, setSelectedStatusValue] = useState(false)
	const [pageNumber, setPageNumber] = useState(1)
	const [pagesAmount, setPagesAmount] = useState(1)
	const _dispatch = useDispatch()

	const [filterOptions, setFilterOptions] = useState([])

	const [customerTableData, setCustomerTableData] = useState([])

	const doGetCustomer = async () => {
		_dispatch(setIsLoading(true))
		console.log('spCodes :>> ', spCodes)
		const response = await getCustomer(spCodes, pageNumber, 6, search)

		console.log('responseresponse', response)
		// console.log('responseresponse', response)
		if (response?.success) {
			console.log('response?.count :>> ', response?.count)
			setPagesAmount(Math.ceil(response?.count / 6))
			setTotalNumberOfCustomers(response?.count)
			console.log('object!!!!! :>> ', pagesAmount)
			const apiRows = response?.data?.map((customer,index) => {
				// const [toggle, setToggle] = useState(customer?.status)
				return {
					id: (
						<Text type="medium" color="black" fontWeight={300}>
							{((pageNumber-1)*6)+index+1}
						</Text>
					),
					customerName: (
						// <div
						// 	onClick={() => {
						// 		setSelectedProfile(customer)
						// 		setIsProfileViewModalOpen(true)
						// 	}}>
						<Text type="medium" color="black" fontWeight={300}>
							{customer?.customer_name}
						</Text>
						// </div>
					),
					companyName: (
						<Text type="medium" color="black" fontWeight={300}>
							{customer?.company_name}
						</Text>
					),
					email: (
						<Text type="medium" color="black" fontWeight={300}>
							{customer?.customer_email}
						</Text>
					),
					spCode: (
						<Text type="medium" color="black" fontWeight={300}>
							{customer?.salespersoncode}
						</Text>
					),
					status: (
						<CustomToggleBar
							id={customer?.id}
							customer={customer}
							customerTableData={customerTableData}
							customSubmit={() => {
								if (customer?.status === false && !customer?.customer_type?.customerType) {
									setSelectedProfile(customer)
									setIsProfileViewModalOpen(true)
								}
							}}
							submit={doUpdateCustomer}
							value={customer?.status}
						/>
					),
					icon: (
						<Flexed direction="row" align="center" gap={0.75} justify="flex-start">
							<IconWrapper
								onClick={() => {
									if (customer?.status) {
										setSelectedProfile(customer)
										setIsProfileViewModalOpen(true)
										setSelectedStatusValue(true)
									} else {
										toastError('Please set Customer Status to Active first')
									}
								}}>
								<Icon src="icons/pencil.svg" />
							</IconWrapper>
							<IconWrapper
								onClick={() => {
									setDeleteCustomerId(customer?.id)
									setIsDeleteModalOpen(true)
								}}>
								<Icon src="icons/delete.svg" />
							</IconWrapper>
						</Flexed>
					)
				}
			})
			setCustomerTableData(apiRows)
			// toastSuccess(response?.message)
		} else {
			toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}

	const doGetSpCodes = async () => {
		_dispatch(setIsLoading(true))

		const response = await getAllSPCodes()

		//		console.log('responseresponse', response)
		// console.log('responseresponse', response)
		if (response?.success) {
			console.log('getSpcodes++++++++++++++++++++ :>> ', response?.data)
			setFilterOptions(response?.data?.map((item) => ({label: item, value: item})))
			// toastSuccess(response?.message)
		} else {
			// toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}

	const doUpdateCustomer = async (id, status, customer) => {
		if (status === false || customer?.customer_type?.customerType) {
			_dispatch(setIsLoading(true))
			console.log('asdasid', id)

			const response = await updateCustomerStatus(id, status)

			console.log('responseresponse', response)
			// console.log('responseresponse', response)
			if (response?.success) {
				doGetCustomer()
				toastSuccess(response?.message)
			} else {
				toastError(response?.message)
			}
			_dispatch(setIsLoading(false))
		} else {
			setSelectedStatusValue(true)
		}
	}
	const doDeleteCustomer = async () => {
		setIsDeleteModalOpen(false)
		_dispatch(setIsLoading(true))
		console.log('deleteCustomerId', deleteCustomerId)
		const response = await deleteCustomer(deleteCustomerId)

		console.log('responseresponse', response)
		// console.log('responseresponse', response)
		if (response?.success) {
			doGetCustomer()
			setDeleteCustomerId(null)
			_dispatch(setIsLoading(true))
			toastSuccess(response?.message)
		} else {
			toastError(response?.message)
		}
	}
// useEffect(()=>{console.log('Search ########################## :>> ', search);})
	useEffect(() => {
		doGetCustomer()
		doGetSpCodes()
		console.log('latestMessage from Customers :>> ', latestMessage);
	}, [])
	useEffect(() => {
		doGetCustomer()
	}, [spCodes, pageNumber, isProfileViewModalOpen === false, search])

	useEffect(()=>{
		console.log('latestMessage from Customers :>> ', latestMessage);
		if(latestMessage==recievedMessages?.customers)
			{
		doGetCustomer()
		_dispatch(setlatestMessage(null))
			}
	},[latestMessage])
	return (
		<>
			<Wrapper>
				<Container fluid>
					<SearchSection title="Customers" search={search} setSearch={setSearch}/>
					<Spacer height={0.625} />

					{showAddCustomerForm === false ? (
						<>
							{' '}
							<Flexed direction="row" align="center" justify="space-between" flexWrap="wrap" gap="1">
								<Button
									ifClicked={() => {
										setShowAddCustomerForm(true)
									}}
									small
									width="11rem"
									icon="case.svg"
									label=" Add Customer"
								/>
								<Flexed direction="row" align="center" flexWrap="wrap" gap={1}>
									<Text type="xlarge" color="black" fontWeight={700}>
										Filter By:
									</Text>
									{spCodes!==0 && (<>
										<Button
									ifClicked={() => {
										setSpCodes(0)
									}}
									small
									// width="11rem"
									// icon="case.svg"
									label="Show All"
								/>
									</>)}
									<CustomDropDown
										label="SP Code"
										styledHeight={6}
										// firstSelected={selectedEditProduct?.company?.company_name}
										hasChanged={async (value) => {
											// setSelectedDueDateError('')
											// if (value == '') {
											// 	setSelectedDueDateError('due is Required')
											// }
											setSpCodes(value)
										}}
										width="8"
										// required
										// error={selectedDueDateError}
										// errorMsg={selectedDueDateError}
										options={filterOptions}
									/>
								</Flexed>
							</Flexed>
							<Spacer height={0.625} />
							<TableWrapper>
								<CustomersTable totalNumberOfCustomers={totalNumberOfCustomers} setPageNumber={setPageNumber} pageNumber={pageNumber} pagesAmount={pagesAmount} customerTableData={customerTableData} />
							</TableWrapper>
						</>
					) : (
						<>
							<Spacer height={2.5} />
							<AddCustomer
								getCustomer={doGetCustomer}
								onClose={() => {
									setShowAddCustomerForm(false)
								}}
							/>
						</>
					)}
				</Container>
			</Wrapper>
			{isProfileViewModalOpen && (
				<ProfileViewModal
					doGetCustomer={doGetCustomer}
					selectedProfile={selectedProfile}
					selectedStatusValue={selectedStatusValue}
					onClose={() => {
						// doGetCustomer()
						setIsProfileViewModalOpen(false)
					}}
				/>
			)}
			{isDeleteModalOpen && (
				<DeleteModal
					onSubmit={() => {
						doDeleteCustomer()
					}}
					label="Are you sure you want to delete this user?"
					onClose={() => {
						setIsDeleteModalOpen(false)
					}}
				/>
			)}
		</>
	)
}

const Wrapper = styled.div`
	background-color: ${palette.lightGrey};
	min-height: 100vh;
	${media.xs`
	padding: 1rem 1rem;
	`}
	${media.sm`
	padding: 1rem 1rem;
	`}
	${media.md`
	padding: 1.625rem 1.25rem 2.625rem 1.25rem;
	`}
`

const TableWrapper = styled.div`
	border-radius: 0.625rem;
	background-color: ${palette.white};
	padding: 0.75rem;
`

const IconWrapper = styled.div`
	background-color: ${palette.black};
	border-radius: 0.625rem;
	width: 2.25rem;
	height: 2.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
`

const Icon = styled.img`
	width: 1.25rem;
	height: 1.25rem;
	cursor: pointer;
`
export default Customers
