import React, {useEffect, useState} from 'react'
import {Container, media} from 'styled-bootstrap-grid'
import {styled} from 'styled-components'
import {palette} from '../styles/colors'
import SearchSection from '../components/common/SearchSection'
import {Flexed, Spacer, Text} from '../styles/shared'
import Button from '../components/common/Button'
import Toggle from '../components/common/Toggle'
import DropDown from '../components/common/DropDown'
import SalesPersonTable from '../components/tables/SalesPersonTable'
import CustomDropDown from '../components/common/CustomDropDown'
import AddSalesPerson from '../components/AddSalesPerson'
import DeleteModal from '../components/modals/DeleteModal'
import EditSalesPersonModal from '../components/modals/EditSalesPersonModal'
import {getAllSalePerson, getSalePerson, updateSalesPersonStatus, updateSalesPerson, deleteSalesPerson, syncSalesPerson, getAllSPCodes} from '../api/apis'
import {useDispatch} from 'react-redux'
import {setIsLoading} from '../actions/authActions'
import {toastError, toastSuccess} from '../styles/toasterStyle'
import CustomToggleBar from '../components/CustomToggleBar'

const SalesPerson = () => {
	const [search, setSearch] = useState('')
	const [showAddSalesPersonForm, setShowAddSalesPersonForm] = useState(false)
	const [isProfileViewModalOpen, setIsProfileViewModalOpen] = useState(false)
	const [idtoDelete, setIdtoDelete] = useState(null)
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
	const [selectedProfile, setSelectedProfile] = useState(null)
	const [tableTest, setTableTest] = useState([])
	const [pageNumber, setPageNumber] = useState(1)
	const [pagesAmount, setPagesAmount] = useState(1)
	const [selectedStatusValue, setSelectedStatusValue] = useState(false)
	const [spCodes, setSpCodes] = useState(0)
	const [totalNumberofSales, setTotalNumberOfSales] = useState(1)
	const [filterOptions, setFilterOptions] = useState([])
	const _dispatch = useDispatch()
	useEffect(() => {
		doGetSalesPerson()
	}, [search,spCodes,pageNumber, isProfileViewModalOpen === false])
	useEffect(() => {
		
		doGetSpCodes()
	}, [])
	const doGetSalesPerson = async () => {
		_dispatch(setIsLoading(true))

		const response = await getAllSalePerson(spCodes,pageNumber, 6,search)
		console.log('Response from getSalePerson:', response)

		if (response?.success) {
			setPagesAmount(Math.ceil(response?.count / 6))
			setTotalNumberOfSales(response?.count)
			const apiRows = response?.data?.map((person, index) => {
				return {
					id: (
						<Text type="medium" color="black" fontWeight={300}>
							{((pageNumber-1)*6)+index+1}
						</Text>
					),
					salesPerson: (
						<Text type="medium" color="black" fontWeight={300}>
							{person?.fullName}
						</Text>
					),
					// companyName: (
					// 	<Text type="medium" color="black" fontWeight={300}>
					// 		{person?.categories?.[0]?.groupName}
					// 	</Text>
					// ),
					email: (
						<Text type="medium" color="black" fontWeight={300}>
							{person?.email}
						</Text>
					),
					spCode: (
						<Text type="medium" color="black" fontWeight={300}>
							{person?.salesPersonCode}
						</Text>
					),
					// status: (
					// 	<CustomToggleBar
					// 		customer={person}
					// 		customerTableData={tableTest}
					// 		customSubmit={() => {
					// 			if (person?.status === false && !person?.customerType?.customerType) {
					// 				setSelectedProfile(person)
					// 				setIsProfileViewModalOpen(true)
					// 			}
					// 		}}
					// 		id={person?.id}
					// 		value={person?.status}
					// 		submit={handleToggle}
					// 	/>
					// ),
					icon: (
						<Flexed direction="row" align="center" gap={0.75} justify="flex-start">
							<IconWrapper
								onClick={() => {
										setSelectedProfile(person)
										setIsProfileViewModalOpen(true)
									// if (person?.status) {
									// 	setSelectedProfile(person)
									// 	setIsProfileViewModalOpen(true)
									// } else {
									// 	toastError('Please set Sales Person Status to Active first')
									// }
								}}>
								<Icon src="icons/pencil.svg" />
							</IconWrapper>
							<IconWrapper
								onClick={() => {
									setIdtoDelete(person?.id)
									setIsDeleteModalOpen(true)
								}}>
								<Icon src="icons/delete.svg" />
							</IconWrapper>
						</Flexed>
					)
				}
			})
			setTableTest(apiRows)
			// toastSuccess(response?.message)
		} else {
			toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}

	async function handleToggle(id, value, person) {
		if (value === false || person?.customerType?.customerType) {
			_dispatch(setIsLoading(true))
			const response = await updateSalesPersonStatus(id, value)
			if (response?.success) {
				doGetSalesPerson()
				toastSuccess(response?.message)
			} else {
				toastError(response?.message)
			}
			_dispatch(setIsLoading(false))
		} else {
			setSelectedStatusValue(true)
		}
	}

	async function doDeleteSalesPerson() {
		_dispatch(setIsLoading(true))
		setIsDeleteModalOpen(false)
		const response = await deleteSalesPerson(idtoDelete)
		if (response?.success) {
			toastSuccess(response?.message)
			setIdtoDelete(null)
		} else {
			toastError(response?.message)
		}
		doGetSalesPerson();
		_dispatch(setIsLoading(false))
	}
  
	
	async function SyncSalesPerson() {
		_dispatch(setIsLoading(true))
	
		const response = await syncSalesPerson()
		if (response?.success) {
			toastSuccess(response?.message)
			
		} else {
			toastError(response?.message)
		}
		doGetSalesPerson();
		_dispatch(setIsLoading(false))
	}
	console.log('showAddSalesPersonForm', showAddSalesPersonForm)

	
	const doGetSpCodes = async () => {
		_dispatch(setIsLoading(true))

		const response = await getAllSPCodes()

		//		console.log('responseresponse', response)
		// console.log('responseresponse', response)
		if (response?.success) {
			console.log('getSpcodes++++++++++++++++++++ :>> ', response?.data)
			setFilterOptions(response?.data?.map((item) => ({label: item, value: item})))
			// toastSuccess(response?.message)
		} else {
			// toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}

	return (
		<Wrapper>
			<Container fluid>
				<SearchSection title="Sales Persons" search={search} setSearch={setSearch}/>
				<Spacer height={0.625} />

				{showAddSalesPersonForm === false ? (
					<>
						<Flexed direction="row" align="center" justify="space-between" flexWrap="wrap" gap="1">
							<Button
								ifClicked={() => {
									// setShowAddSalesPersonForm(true)
									SyncSalesPerson();

								}}
								small
								width="11rem"
								icon="refresh.png"
								label="Sync Sales Person"
							/>
							{/* <Flexed direction="row" align="center" flexWrap="wrap" gap={1}>
								<Text type="xlarge" color="black" fontWeight={700}>
									Filter By:
								</Text>
								<CustomDropDown
									label="SP Code"
									styledHeight={6}
									// firstSelected={selectedEditProduct?.company?.company_name}
									hasChanged={(value) => {
										// setSelectedDueDateError('')
										// if (value == '') {
										// 	setSelectedDueDateError('due is Required')
										// }
										setSpCodes(value)
									}}
									width="8"
									// required
									// error={selectedDueDateError}
									// errorMsg={selectedDueDateError}
									options={filterOptions}
								/>
							</Flexed> */}
						</Flexed>
						<Spacer height={0.625} />
						<TableWrapper>
							<SalesPersonTable tableTest={tableTest} pageNumber={pageNumber} setPageNumber={setPageNumber} pagesAmount={pagesAmount} totalNumberofSales={totalNumberofSales} />
						</TableWrapper>
					</>
				) : (
					<>
						<Spacer height={2.5} />
						<AddSalesPerson
							doGetSalesPerson={doGetSalesPerson}
							setShowAddSalesPersonForm={() => {
								setShowAddSalesPersonForm(false)
							}}
						/>
					</>
				)}
			</Container>

			{isProfileViewModalOpen && (
				<EditSalesPersonModal
					selectedStatusValue={selectedStatusValue}
					doGetSalesPerson={doGetSalesPerson}
					selectedProfile={selectedProfile}
					salesPersonView={true}
					onClose={() => {
						setIsProfileViewModalOpen(false)
					}}
				/>
			)}
			{isDeleteModalOpen && (
				<DeleteModal
					onSubmit={doDeleteSalesPerson}
					label="Are you sure you want to delete this sales person?"
					onClose={() => {
						setIsDeleteModalOpen(false)
					}}
				/>
			)}

		</Wrapper>
	)
}

const Wrapper = styled.div`
	background-color: ${palette.lightGrey};
	min-height: 100vh;
	${media.xs`
	padding: 1rem 1rem;
	`}
	${media.sm`
	padding: 1rem 1rem;
	`}
	${media.md`
	padding: 1.625rem 1.25rem 2.625rem 1.25rem;
	`}
`

const TableWrapper = styled.div`
	border-radius: 0.625rem;
	background-color: ${palette.white};
	padding: 0.75rem;
`

const IconWrapper = styled.div`
	background-color: ${palette.black};
	border-radius: 0.625rem;
	width: 2.25rem;
	height: 2.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
`

const Icon = styled.img`
	width: 1.25rem;
	height: 1.25rem;
	cursor: pointer;
`

export default SalesPerson
