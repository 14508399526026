import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { palette } from "../../styles/colors";
import { Col, Container, Row } from "styled-bootstrap-grid";
import {
  Flexed,
  Spacer,
  Text,
} from "../../styles/shared";


const GalleryModal = ({ onClose, label ,setImage }) => {





  const handleCapture = ({ target }) => {
    const reader = new FileReader();
    reader.readAsDataURL(target.files[0]);
    reader.onload = () => {
      if (reader.readyState === 2) {
        const uploadedImage = reader.result;
       
        // Pass the uploaded image to the parent component
        setImage(uploadedImage)
        onClose()
      }
    };
  };
  
  return (
    <>
      <Modal
        open={true}
        onClose={onClose}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "successModal",
        }}
        showCloseIcon={false}
      >
        <ModalWrapper>
          <Icon src="./icons/crossicon.svg" alt="loading" onClick={onClose} />
          <Spacer height={2.5} />
          <Wrapper>
            <Flexed direction="column" align="center">
              <Text
                isCentered
                color="black"
                fontWeight={950}
                fontSize={1.5}
                lineheight={1.5}
              >
                {label}
              </Text>
              <Spacer height={2.5} />
            </Flexed>
            {/* <Flexed justify="space-between" ></Flexed> */}

            <Row>
              <CustomCol xl={6}>
                <InputWrapper>
                  <CustomUpload>
                    <UploadIcon
                     
                      src={
                        "/icons/mountains.png"
                      }
                    />
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <Text
                      isCentered
                      color="black"
                      fontWeight={700}
                      fontSize={1.5}
                      lineheight={1.5}
                    >
                      Gallery
                    </Text>
                    
                    <FileInput
                      id="faceImage"
                      accept="image/jpeg/png"
                      type="file"
                      onChange={(e) => {
                        handleCapture(e);
                      
                      }}
                    />
                  </CustomUpload>
                </InputWrapper>
              </CustomCol>
              <CustomCol xl={6}>
                <InputWrapper>
                  <CustomUpload>
                    <UploadIcon
                     
                      src={
                        
                         "/icons/camera.png"
                      }
                    />{" "}
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <Text
                      isCentered
                      color="black"
                      fontWeight={700}
                      fontSize={1.5}
                      lineheight={1.5}
                    >
                      Take photo
                    </Text>
                    {/* <Icon src="icons/add.svg" /> */}
                   
                  </CustomUpload>
                </InputWrapper>
              </CustomCol>
            </Row>
          </Wrapper>
        </ModalWrapper>
      </Modal>
    </>
  );
};
const CustomCol = styled(Col)`
  display: flex;
  justify-content: center;
`;
const FileInput = styled.input`
  display: none !important;
`;
const UploadIcon = styled.img`
  width: ${({ showImage }) => (showImage ? "2rem" : "2rem")};
  height: ${({ showImage }) => (showImage ? "2rem" : "2rem")};
  //   border-radius: 100%;
  /* font-size: 2.5rem; */
  /* color: ${palette.black}; */
`;
const CustomUpload = styled.label`
  padding: 1rem;
  height: 5rem;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  // gap: 0.7rem;
  //   flex-direction: column;
  cursor: pointer;
`;

const InputWrapper = styled.div`
  position: relative;

  // height: 8.5rem;
`;
const LabelWrapper = styled.div`
  position: absolute;
  top: 5.8rem;
  color: white;
  // left: 4.5rem;
  //   background-color: ${palette.white};
  padding: 0 0.5rem;
`;
const Wrapper = styled(Container)`
  padding: 0;
`;
const StyledCol = styled(Col)`
  /* padding: 0; */
`;

const ModalWrapper = styled.div`
  padding: 1.125rem 0rem;
  height: 100%;
  background: ${palette.white};
`;
const Icon = styled.img`
  width: 0.875rem;
  height: 0.875rem;
  position: absolute; /* Make sure to specify a position */
  right: 3rem; /* Adjust this value to move the icon further to the right */
  cursor: pointer;
`;
export default GalleryModal;
