import React, {useEffect, useState} from 'react'
import {Col, Row, media} from 'styled-bootstrap-grid'
import {Flexed, Spacer, Text} from '../../styles/shared'
import {ImExit} from 'react-icons/im'
import {styled} from 'styled-components'
import {palette} from '../../styles/colors'
// import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import InputField from './InputField'
import InputSearch from './InputSearch'
import {useSelector} from 'react-redux'
import {getNotifications, setSeenNotifications, setClearNotifications} from '../../api/apis'
import { setlatestMessage , setNotificationsMessages } from '../../actions/authActions';
import {useDispatch} from 'react-redux'

const SearchSection = ({title, noSearch ,search,setSearch}) => {
	const _dispatch = useDispatch()
	// const userName = useSelector((state) => state.auth.userName)
	const userName = sessionStorage.getItem('userName')
	const admin = sessionStorage.getItem('role')
	const latestMessage = useSelector((state) => state.auth.latestMessage);
	const recievedMessages = useSelector((state) => state.auth.recievedMessages);
	const notificationMessages = useSelector((state) => state.auth.notificationMessages);
	const valuesArray = Object.values(recievedMessages);
	const halfName = userName?.split(' ')
	const _navigate = useNavigate()

		const [isOpen, setIsOpen] = useState(false);
		const [isOpenShown, setIsOpenShown] = useState(false);
		const [notifications, setNotifications] = useState([]);
	
		const toggleDropdown =  async () => {
			setIsOpen(!isOpen);
		};

		
		const dogetNotifications = async () => {
			const response = await getNotifications();
			if (response?.success) {
				setNotifications(response);
				response?.count > 0 && setIsOpenShown(true)
				
		//console.log('notifications :>> ', response?.data);
			} 
		  };
		
		const doSetSeenNotifications = async () => {
			const response = await setSeenNotifications();
			if (response?.success) {
			//	dogetNotifications()
		console.log('notifications :>> ', response?.data);
			} 
		  };

		  const doSetClearNotifications = async () => {
			const response = await setClearNotifications();
			if (response?.success) {
				dogetNotifications()

		console.log('notifications :>> ', response?.data);
			} 
		  };

		  useEffect(()=>{

			dogetNotifications();
		  },[])
		  useEffect(()=>{
			console.log('notificationMessages', notificationMessages)
			if(notificationMessages==true)
				{
					dogetNotifications();
				_dispatch(setNotificationsMessages(false))
			}
		  },[notificationMessages])


		  function timeAgo(timestamp) {
			const parsedTime = new Date(timestamp);
			const now = new Date();
		
			const diff = now - parsedTime; // Difference in milliseconds
		
			const seconds = Math.floor(diff / 1000);
			const minutes = Math.floor(seconds / 60);
			const hours = Math.floor(minutes / 60);
			const days = Math.floor(hours / 24);
		
			if (days > 0) {
				return days === 1 ? "1 day ago" : `${days} days ago`;
			} else if (hours > 0) {
				return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
			} else if (minutes > 0) {
				return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
			} else if (seconds > 0) {
				return seconds === 1 ? "1 second ago" : `${seconds} seconds ago`;
			} else {
				return "just now";
			}
		}

	return (
		<Row justifyContent="between" alignItems="center">
			<Col xl={9}>
				<Spacer height={1} />

				<BgWrapper>
					<CustomRow justifyContent="between" alignItems="center">
						{/* <Spacer height={1} /> */}
						<Col xl={3} md={4} sm={6}>
							<Text fontSize={2} color="white" fontWeight={400} lineHeight={2.1}>
								{title}
							</Text>
						</Col>

						<DisplayDiv xs={true} sm={true} md={true} lg={false}>
						<Col xl={2} lg={12} md={12}>
							{/* <Spacer height={0.5} /> */}
							<CustomFlex direction="row" justify="flex-end">
							<NotificationContainer>
						{(notifications?.count>0 && isOpenShown) &&(<>		<NotificationDot>{notifications?.count}</NotificationDot></>)}
									<NotificationWrapper onClick={toggleDropdown}>
										<Icon src="icons/bell.svg" />
									</NotificationWrapper>
									{isOpen && (
                <Dropdown>
                    <DropdownList>
					<DropdownItem>
					<Flexed direction="row" gap="0.2" >
							<Text color='black' fontWeight="600" fontSize='1'>Mark all as Read</Text>
							<Text color='black' fontSize='0.9' maxWidth='20rem'>Clear</Text>
							</Flexed>
					</DropdownItem>
						{
						notifications?.data?.length > 0 ? (
						notifications?.data?.map((item, index) => (
                        <DropdownItem >
								<Flexed direction="row" justify="space-between" gap="1.5">
								<Flexed direction="column" gap="0.2" >
							<Text color='black' fontWeight="600" fontSize='1'>{item?.notificationType}</Text>
							<Text color='black' fontSize='0.9' maxWidth='20rem'>{item?.notification}</Text>
							</Flexed>
							<Text minWidth="5" maxWidth="5"  fontWeight="600" color='black'>{timeAgo(item?.createdAt)}</Text>	
							</Flexed>
							</DropdownItem>
						))
					) : (
						<DropdownItem>No New Notifications</DropdownItem>
					)
					}


                    </DropdownList>
                </Dropdown>
            )}
							
							</NotificationContainer>
							</CustomFlex>
						</Col>
						</DisplayDiv>

						{!noSearch && (
							<Col xl={6} md={12}>
								{/* <Spacer height={0.5} /> */}
								<InputSearch
									handleChange={(e) => {
										setSearch(e)
									}}
									placeholder="Search"
								/>
							</Col>
						)}
						<DisplayDiv xs={false} sm={false} md={false} lg={true}>
						<Col xl={2} lg={12} md={12}>
							{/* <Spacer height={0.5} /> */}
							<CustomFlex direction="row" justify="flex-end">
							<NotificationContainer>
						{(notifications?.count>0 && isOpenShown) &&(<>		<NotificationDot>{notifications?.count}</NotificationDot></>)}
									<NotificationWrapper onClick={toggleDropdown}>
										<Icon src="icons/bell.svg" />
									</NotificationWrapper>
									{isOpen && (
                <Dropdown onMouseLeave={()=>setIsOpen(false)}>
                    <DropdownList>
						<DropdownItemFirst>
					<Flexed justify="flex-end" direction="row" gap="0.2" >
							<Text color='black'  fontSize='1' onClick={async()=>{await doSetSeenNotifications();
							await dogetNotifications();
			setIsOpenShown(false)}}>Mark All as Read</Text>
							<Text color='black'  fontSize='1'>|</Text> 
							<Text color='black' fontSize='1' maxWidth='20rem' onClick={()=>{
								doSetClearNotifications()
							}}>Clear All</Text>
							</Flexed>
					</DropdownItemFirst>
						{
						notifications?.data?.length > 0 ? (
						notifications?.data?.map((item, index) => (
                        <DropdownItem notify={admin ? !item?.isAdminSeen : !item?.isSalesPersonSeen} onClick={() => {
							if (item?.notificationType === 'New Order') {
								_navigate('/orders');
								setIsOpen(false);
							} else if (item?.notificationType === 'New Feedback') {
								_navigate('/Feedbacks');
								setIsOpen(false);
							} else if (item?.notificationType === 'New Customer') {
								_navigate('/Customers');
								setIsOpen(false);
							}
						}}>
								<Flexed direction="row" justify="space-between" gap="1.5">
								<Flexed direction="column" gap="0.2" >
							<Text color='black' fontWeight="600" fontSize='1'>{item?.notificationType}</Text>
							<Text color='black' fontSize='0.9' maxWidth='20rem'>{item?.notification}</Text>
							</Flexed>
							<Text minWidth="5" maxWidth="5"  fontWeight="600" color='black'>{timeAgo(item?.createdAt)}</Text>	
							</Flexed>
							</DropdownItem>
						))
					) : (
						<DropdownItem>No New Notifications</DropdownItem>
					)
					}


                    </DropdownList>
                </Dropdown>
            )}
							
							</NotificationContainer>
							</CustomFlex>
						</Col>
						</DisplayDiv>
					</CustomRow>
				</BgWrapper>
			</Col>
			
			<CustomCol xl={3}>
				<Spacer height={1} />
				<ProfileWrapper>
					<Flexed direction="row" gap={0.75}>
						<div>
							<ProfilePic>
								<Icon src="icons/profile.png" />
							</ProfilePic>
						</div>
						<CustomDiv>
							<Text fontWeight={600} fontSize={1.5} lineHeight={1.813} color="pureBlack">
								{userName && halfName[0]}
							</Text>
							<Text typee="xsmall" color="pureBlack">
								Super Admin
							</Text>
						</CustomDiv>
					</Flexed>
					<div>
						<ExitWrapper
							onClick={() => {
								sessionStorage.removeItem('authorization')

								_navigate('/')
							}}>
							<Icon src="icons/exit.svg" />
						</ExitWrapper>
					</div>
				</ProfileWrapper>
			</CustomCol>
		</Row>
	)
}

const CustomFlex = styled(Flexed)`
	width: 100%;
	cursor: pointer;
`
const CustomCol = styled(Col)`
	/* padding: 0; */
`
const BgWrapper = styled.div`
	background-color: ${palette.black};
	border-radius: 0.625rem;
`

const ProfileWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 72.02px;
	background-color: ${palette.white};
	border-radius: 0.625rem;
	padding: 0.75rem;
`

const ExitWrapper = styled.div`
	height: 2.875rem;
	width: 2.875rem;
	background-color: ${palette.black};
	padding: 0.563rem;
	border-radius: 0.625rem;
	cursor: pointer;
`
const NotificationWrapper = styled.div`
	background-color: ${palette.yellow};
	border-radius: 0.625rem;
	padding: 0.675rem;
	width: 3.375rem;
	height: 3.375rem;
`

const Icon = styled.img`
	width: 100%;
	height: 100%;
`

const ProfilePic = styled.div`
	height: 3rem;
	width: 3rem;
`

const CustomRow = styled(Row)`
	padding: 0.563rem 15px 0.563rem 15px;
	/* background-color: ${palette.black}; */
	@media (max-width: 1200px) {
		gap: 1rem;
	}
`
const StyledFlex = styled(Flexed)`
	cursor: pointer;

	&:hover {
		color: ${palette.yellow};
	}
	&:hover ${Text} {
		color: ${palette.yellow};
	}
`
const CustomDiv=styled.div`
display:block;
${media.xs`
display: none;

`}
${media.sm`
display: block;

`}
${media.md`
display: block;
`}
${media.lg`
display: block;
`}
`
const DisplayDiv = styled.div`
  display: ${({ xs }) => (xs ? 'block' : 'none')};

  ${media.xs`
    display: ${props => (props.xs ? 'block' : 'none')};
  `}
  ${media.sm`
    display: ${props => (props.sm ? 'block' : 'none')};
  `}
  ${media.md`
    display: ${props => (props.md ? 'block' : 'none')};
  `}
  ${media.lg`
    display: ${props => (props.lg ? 'block' : 'none')};
  `}
`;

const NotificationContainer = styled.div`
    position: relative;
    display: inline-block;
`;

const NotificationIcon = styled.div`
    cursor: pointer;
    font-size: 24px;
`;
const NotificationDot = styled.div`
position: absolute; 
top: -5px;
display:flex;
align-items:center;
justify-content:center;
color:black;
right:-10px;
   width:25px;
   height:25px;
   border-radius:50px;
   background-color:silver;
`;

const Dropdown = styled.div`
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #ffffffe0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 8px 16px rgba(0,0,0,0.2);
    z-index: 1;
    width: 400px;
	max-height:400px;
	overflow-y: auto;

   /* Custom scrollbar styles */
    ::-webkit-scrollbar {
        width: 8px; /* Width of the scrollbar */
    }

    ::-webkit-scrollbar-track {
        background: transparent; /* No background for the track */
    }

    ::-webkit-scrollbar-thumb {
        background: #000; /* Black color for the thumb */
        border-radius: 4px; /* Round corners */
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #333; /* Darker black color for the thumb on hover */
    }

    /* For Firefox */
    scrollbar-width: thin; /* Thin scrollbar */
    scrollbar-color: #000 transparent; /* Black thumb, transparent track */

    /* For IE and Edge */
    -ms-overflow-style: -ms-autohiding-scrollbar; /* Auto-hiding scrollbars */
`;

const DropdownList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

const DropdownItem = styled.li`
    padding: 10px;
    border-bottom: 1px solid #d1cecee0;
	min-height:3.8rem;
	cursor: pointer;
	 background-color: ${({notify}) => (notify ? `#fdc56ce6` : '')};
    &:hover {
        background-color: #d1cecee0;
    }

    &:last-child {
        border-bottom: none;
    }
`;

const DropdownItemFirst = styled.li`
    padding: 10px;
    border-bottom: 1px solid #d1cecee0;
	
   
`;

// NotificationDropdown Component

export default SearchSection
