import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {palette} from '../../styles/colors'
import {Col, Container, Row} from 'styled-bootstrap-grid'
import {Flexed, Heading, Spacer, Text, VerticalSpacer} from '../../styles/shared'
import CustomDropDown from '../common/CustomDropDown'
import Button from '../common/Button'
import SuccessModal from './SuccessModal'

const OrderRefundModal = ({onClose, label}) => {
  const [openSuccessModal,setOpenSuccessModal] = useState(false)
	return (
		<>
			<Modal
				open={true}
				onClose={onClose}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'successModal'
				}}
				showCloseIcon={false}>
				<ModalWrapper>
					<Wrapper>
						<Flexed direction="column" align="center">
							<Text isCentered color="black" fontWeight={450} fontSize={1.5} lineheight={1.5}>
                            Refund the remaining amount?
							</Text>
							<Spacer height={2.5} />
                            <Text isCentered color="black" fontWeight={450} fontSize={1} lineheight={1.5}>
                            Do you want to refund for 1 item? 
							</Text>
                            <Spacer height={2.5} />
                            <Text isCentered color="black" fontWeight={450} fontSize={1.5} lineheight={1.5}>
                                Refund amount  &nbsp; <InnetText>£22500</InnetText>       
							</Text>
						</Flexed>
						<Spacer height={2.5} />
						<Flexed direction="row" justify="space-around" align="center">
							<Button
								label="No"
                                hasBorder
								ifClicked={() => {
									onClose()
								}}
								width="13.75rem"
							/>
							<Button
								label="Yes"
								ifClicked={() => {
                                    setOpenSuccessModal(true)
								}}
								width="13.75rem"
							/>
						</Flexed>
                        {
                            openSuccessModal && (

                                <SuccessModal
                                label="Your order placed successfully!"
                                 onClose={()=>{
                                    setOpenSuccessModal(false)
                                    onClose()
                                 }}
                                />
                            )
                        }
					</Wrapper>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const InnetText = styled.span`
	font-size: 1.25rem;
	color: ${palette.danger};
`
const Wrapper = styled(Container)`
	padding: 0;
`


const ModalWrapper = styled.div`
	padding: 1.125rem 0rem;
	height: 100%;
	background: ${palette.white};
`

export default OrderRefundModal
