import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Flexed, Text} from '../styles/shared'
import {palette} from '../styles/colors'

const CustomPagination = ({pagesAmount, tableData, totalData, pageNumber, setPageNumber}) => {
	const [activePage, setActivePage] = useState(pageNumber)

	useEffect(() => {
		setActivePage(pageNumber)
	}, [pageNumber])

	const nextPage = () => {
		setPageNumber((prevPage) => Math.min(prevPage + 1, pagesAmount))
	}

	const prevPage = () => {
		setPageNumber((prevPage) => Math.max(prevPage - 1, 1))
	}

	const setPage = (pageNumber) => {
		setPageNumber(pageNumber)
	}

	// Calculate the range of pages to display based on the current page number
	const startPage = Math.max(1, activePage - 1)
	const endPage = Math.min(startPage + 2, pagesAmount)
	const resultsStart = (pageNumber - 1) * 6 + 1
    const resultsEnd = Math.min(pageNumber * 6, totalData)

	return (
		<>
		{tableData?.length > 0 && (
		<CustomFlexed direction="row" align="center" justify="space-between" gap="1">
			<Flexed direction="row">
				<Text type="normal">
				Showing Results {resultsStart} to {resultsEnd} of {totalData}
				</Text>
			</Flexed>

			<Flexed gap={0.5} justify="flex-end">
				<Div border={2} color="black" onClick={prevPage}>
					<Image src="/icons/leftArror.svg" />
				</Div>
				{/* Display only 3 pages at a time */}
				{[...Array(endPage - startPage + 1)].map((_, index) => (
					<Div key={startPage + index} onClick={() => setPage(startPage + index)} active={startPage + index === activePage}>
						<Text fontSize="1" color={startPage + index === activePage ? 'customOrange' : 'customGrey'}>
							{startPage + index < 10 ? `0${startPage + index}` : startPage + index}
						</Text>
					</Div>
				))}
				<Div border={2} color="black" onClick={nextPage}>
					<Image src="/icons/rightArrow.svg" />
				</Div>
			</Flexed>
		</CustomFlexed>
		)}
		</>
	)
}

const Image = styled.img`
	height: 1rem;
`

const CustomFlexed = styled(Flexed)`
	width: 100%;
	flex-wrap:wrap;

`

const Div = styled.div`
	height: 2.5rem;
	width: 2.5rem;
	border-radius: 8px;
	border: ${({border}) => (border ? `${border}px solid #1D1D1D` : '2px solid #CCCBCB')};
	background: ${({color}) => (color ? palette[color] : 'white')};
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	${(props) =>
		props.active &&
		`
    border-color: black;
    & > ${Text} {
      color: #f49c10;
    }
  `}
`

export default CustomPagination
