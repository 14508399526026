import {React, useState} from 'react'
import {Col, Container, Row} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import Button from '../components/common/Button'
import {Spacer, Text} from '../styles/shared'
import InputField from '../components/common/InputField'
import DropDown from '../components/common/DropDown'

const RenderedComponents = () => {
	const [privacyOption, setPrivacyOption] = useState([
		{value: 'Public', label: 'Public'},
		{value: 'Favorites', label: 'Favorites'},
		{value: 'Private', label: 'Private'}
	])
	return (
		<>
			<Container>
				<Wrapper>
					<Button label="Wishlist" hasBorder width="290px" large type="grey" icon="buttonHeart.svg" ifClicked={() => {}} />
					<Spacer height={1} />
					<Button label="View Look" width="236px" large type="black" ifClicked={() => {}} />
					<Spacer height={1} />
					<Button label="Add to cart" hover width="236px" large type="black" ifClicked={() => {}} />
					<Spacer height={1} />
					<Button label="Add to Bag" hover width="290px" borderRadius="12px" hasBorder medium type="transparent" ifClicked={() => {}} />
					<Spacer height={1} />
					<Button label="Send" hover borderRadius="12px" large type="black" ifClicked={() => {}} />
					<Spacer height={1} />
					<Button label="Shop Now" borderRadius="12px" hasBorder width="119px" small type="transparent" ifClicked={() => {}} />
				</Wrapper>
				<Text>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</Text>
				<Spacer height={1} />
				<Row>
					<Col lg={4}>
						<InputField label="password" type="password" placeholder="Share your wisdom here..." handleChange={(e) => {}} value={'asd'} required />
					</Col>
					<Col lg={4}>
						<InputField label="email" type="email" placeholder="Share your wisdom here..." handleChange={(e) => {}} required />
					</Col>
					<Col lg={4}>
						<InputField label="Text" placeholder="Share your wisdom here..." handleChange={(e) => {}} required />
					</Col>
					<Col lg={4}>
						<Spacer height={1} />
						<InputField type="search" label="search" placeholder="Share your wisdom here..." handleChange={(e) => {}} required />
					</Col>
					<Col lg={4}>
						<Spacer height={1} />
						<DropDown
							label="Privacy"
							// firstSelected={}
							name="ProductCategory"
							hasChanged={(value) => {}}
							required
							// error={privacyOptionsError}
							// errorMsg={privacyOptionsError}
							options={privacyOption}
						/>
					</Col>
				</Row>
			</Container>
		</>
	)
}

const Wrapper = styled.div`
	padding: 40px;
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
`

export default RenderedComponents
