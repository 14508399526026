import React, {useState} from 'react'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import {palette} from '../../styles/colors'
import {IoIosArrowDown} from 'react-icons/io'
import 'react-datepicker/dist/react-datepicker.css'

const DateButton = ({value, onChange}) => {
	const [showCalendar, setShowCalendar] = useState(false)
	const [selectedDate, setSelectedDate] = useState(value)

	const handleDateChange = (date) => {
		setSelectedDate(date)
		onChange(date)
		setShowCalendar(false)
	}

	return (
		<ButtonContainer>
			<StyledButton onClick={() => setShowCalendar(!showCalendar)}>
				{selectedDate ? selectedDate.toLocaleDateString() : 'Select Date'} <Icon id="icon"> {!showCalendar ? <ArrowDown /> : <ArrowUp />} </Icon>
			</StyledButton>
			{showCalendar && (
				<CalendarContainer>
					<DatePicker selected={selectedDate} onChange={handleDateChange} inline />
				</CalendarContainer>
			)}
		</ButtonContainer>
	)
}

const ButtonContainer = styled.div`
	position: relative;
`

const StyledButton = styled.button`
	font-family: 'Public Sans';

	line-height: 1.5rem;
	outline: none;
	font-weight: 600;
	text-align: left;
	font-size: 0.92rem;
	border-radius: 0.375rem;
	padding: 0.2rem 0.5rem;
	border: 1px solid #1d1d1d;
	color: ${palette.white};
	background: ${palette.black};
	cursor: ${({disabled}) => (disabled ? `no-drop` : `pointer`)};
	&:focus {
		border: 1px solid ${({error, disabled}) => (disabled ? 'none' : error ? palette.danger : palette.grey)};
	}
`

const Icon = styled.i`
	color: ${palette.text_color};
	font-size: 0.7rem;
	margin-left: 1rem;
`

const CalendarContainer = styled.div`
	position: absolute;
	z-index: 100;
	top: calc(100% + 5px);
	right: 0;
	@media screen and (max-width: 376px) {
		left: 3px;
	  }
	
`
const ArrowUp = styled(IoIosArrowDown)`
	transform: rotate(180deg);
	/* color: ${palette.grey}; */
`
const ArrowDown = styled(IoIosArrowDown)`
	/* color: ${palette.grey}; */
`

export default DateButton
