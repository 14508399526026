import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {palette} from '../../styles/colors'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import {Flexed, Heading, Spacer, Text, VerticalSpacer} from '../../styles/shared'
import CustomDropDown from '../common/CustomDropDown'
import Button from '../common/Button'
import DropDown from '../common/DropDown'
import {setIsLoading} from '../../actions/authActions'
import {getCustomerType, getProductGroup, updateCustomer, getAllSPCodes, getCustomerProfileStatus} from '../../api/apis'
import {toastError, toastSuccess} from '../../styles/toasterStyle'
import {useDispatch} from 'react-redux'
import MultiSelectDropDown from '../common/MultiSelectDropDown'
import InputField from '../common/InputField'
import IncreaseAvailLimitModal from './IncreaseAvailLimitModal'
import {getCreditDetails,updateCreditDetails} from '../../api/apis'
import SuccessModal from './SuccessModal'
const AllowCreditModal = ({salesPersonView, selectedProfile, doGetCustomer, selectedStatusValue, onClose}) => {
	const _dispatch=useDispatch()
	const [filterOptions, setFilterOptions] = useState([])
	const [selectedSpCode, setSelectedSpCode] = useState(null)
	const [profileStatus, setProfileStatus] = useState([])
	const [	settleCredit, setSettleCredit] = useState("")
	const [	settleCreditError, setSettleCreditError] = useState("")
	const [	allowedCredit, setAllowedCredit] = useState("")
	const [	allowedCreditError, setAllowedCreditError] = useState("")
    const [increaseAvailLimitOpen,setIncreaseAvailLimitOpen]=useState(false)
    const [customerCreditDatails,setCustomerCreditDatails]=useState({})
	const [openSuccessModal,setOpenSuccessModal]= useState(false)

	const dogetCreditDetails = async () => {
		_dispatch(setIsLoading(true))

		const response = await getCreditDetails(selectedProfile?.id)
		if (response?.success) {
		setCustomerCreditDatails(response?.data)
		setAllowedCredit(response?.data?.allowedCredits)
	//	console.log('response?.data :>> ', response?.data);
		} 
		_dispatch(setIsLoading(false))
	}
	const doupdateCreditDetails = async () => {
		_dispatch(setIsLoading(true))

		const response = await updateCreditDetails(selectedProfile?.id, parseInt(allowedCredit), parseInt(settleCredit))
		if (response?.success) {
			setOpenSuccessModal(true)
		//toastSuccess(response?.message)
	//	console.log('response?.data :>> ', response?.data);
		} 
		else{
			toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}

	useEffect(()=>{
		dogetCreditDetails()
	},[selectedProfile])

	const checkValidationOnClick = () => {
		let isValid = true;
		if (allowedCredit === "") {
		  setAllowedCreditError("Credit Amount is required");
		  isValid = false;
		}
	
		if (settleCredit === "") {
		  setSettleCreditError("Settle Credit is required");
		  isValid = false;
		}
	
		return isValid;
	  };
	
	return (
		<>
			<Modal
				open={true}
				onClose={onClose}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
				showCloseIcon={false}
				>
				<ModalWrapper>
					<Cross src="/icons/modalCross.svg" onClick={() => {
										onClose()
									}} />
					<Container>
					<Text color="black" isCentered  fontWeight={700} fontSize="2" lineHeight={2}>
												Allow Credit Detail
											</Text>
											<Spacer height={6.813} />
						   	<Flexed flexWrap="wrap" direction="row" justify="space-evenly">
								<Flexed width="30%" direction="column" justify="space-between" gap={0.5}>
								  <Flexed gap={0.5} >
								<Text color="black" fontWeight={700} fontSize="1.25" lineHeight={2}>
								Allowed Credit 
								</Text>
								<img src="./icons/pencil1.svg" alt="load" width={`20px`}
								onClick={()=>setIncreaseAvailLimitOpen(!increaseAvailLimitOpen)}
								/>
								</Flexed>	
								{increaseAvailLimitOpen ? (<>
									<InputField
									value={allowedCredit}
									width="10"
									// labelType="xlarge"
									// label="Expiry"
									// type={`date`}
									// minDate={minDate}
									placeholder={allowedCredit}
									handleChange={(e) => {
										setAllowedCreditError('')
										if (e === '') {
											setAllowedCreditError('Allowed Credits is required')
										}
										setAllowedCredit(e)
									}}
									error={allowedCreditError}
									errorMsg={allowedCreditError}
									required
								/>
								</>) : (
									<>
									<Text color="black" fontWeight={700} fontSize="1.25" lineHeight={2}>
											{allowedCredit}
											</Text>
									</>
								)}
											
								</Flexed>
								<Flexed width="30%" direction="column" justify="space-between" gap={0.5}>
								<Text color="black" fontWeight={700} fontSize="1.25" lineHeight={2}>
								Available Limit
											</Text>
											<Text color="black" fontWeight={700} fontSize="1.25" lineHeight={2}>
											{customerCreditDatails?.availableCredits}
											</Text>
								</Flexed>
								<Flexed width="30%" direction="column" justify="space-between" gap={0.5}>
								<Text color="black" fontWeight={700} fontSize="1.25" lineHeight={2}>
								Total Credit Orders
											</Text>
											<Text color="black" fontWeight={700} fontSize="1.25" lineHeight={2}>
											{customerCreditDatails?.totalCreditsOrders}
											</Text>
								</Flexed>
							</Flexed>
							<Spacer height="7.5"/>
							<Flexed flexWrap="wrap" direction="row" justify="space-evenly">	
								<Flexed width="30%" direction="column" justify="space-between" gap={0.5}>
								<Text color="black" fontWeight={700} fontSize="1.25" lineHeight={2}>
								Settle Credit
											</Text>
											<InputField
									value={settleCredit}
									// labelType="xlarge"
									// label="Expiry"
									// type={`date`}
									// minDate={minDate}
									placeholder="£20000"
									handleChange={(e) => {
										setSettleCreditError('')
										if (e === '') {
											setSettleCreditError('Email is required')
										}
										setSettleCredit(e)
									}}
									error={settleCreditError}
									errorMsg={settleCreditError}
									required
								/>
								</Flexed>
								<Flexed width="30%" direction="column" justify="space-between" gap={0.5}>
								<Text color="black" fontWeight={700} fontSize="1.25" lineHeight={2}>
								Total Paid Amount
											</Text>
											<Text color="black" fontWeight={700} fontSize="1.25" lineHeight={2}>
											{customerCreditDatails?.totalPaidAmount}

											</Text>
								</Flexed>
								<Flexed width="30%" direction="column" justify="space-between" gap={0.5}>
								{/* <Text color="black" fontWeight={700} fontSize="1.25" lineHeight={2}>
							
											</Text>
											<Text color="black" fontWeight={700} fontSize="1.25" lineHeight={2}>
										
											</Text> */}
								</Flexed>
								
							</Flexed>

							
						<CustomRow justifyContent="center">
							<Col lg={3}>
								<Spacer height={11.25} />
								<Button
									width="100%"
									label="Allow Credit"
									ifClicked={() => {
										checkValidationOnClick && doupdateCreditDetails()
										
									}}
								/>
								<Spacer height={1} />
							</Col>
						</CustomRow>
						{/* </Scroll> */}

					</Container>
				</ModalWrapper>
			</Modal>
			{openSuccessModal && (
        <SuccessModal
          label="Credit Allowed successfully"
          onClose={() => {
            onClose()
			setOpenSuccessModal(false)
          }}
        />
      )}
		</>
	)
}

const Scroll = styled.div`
	overflow-y: auto;

	height: 22rem;

	&::-webkit-scrollbar {
		width: 0.3rem !important;
		height: 0.1rem !important;
	}

	&::-webkit-scrollbar-track {
		background: #eee;
		border-radius: 1rem;
		border: 0.01rem solid ${palette.silver};
		border-width: 0rem 0.1rem 0rem 0rem;
	}

	&::-webkit-scrollbar-thumb {
		background: ${palette.yellow};
		border-radius: 1rem;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`
const CustomCol = styled(Col)`
	display: flex;
	align-items: center;
`
const CustomFlex = styled(Flexed)`
	width: 100% !important;
	display: flex;
	justify-content: space-between;
	align-items: center;
`
const CustomFlexed = styled(Flexed)`
	width: 100% !important;
	// gap: 3rem;
`

const StatusHeading = styled(Heading)`
	${media.xs`
display: flex;
width: 100%;
align-items: center;
height: 5rem;
`}
	${media.sm`
display: flex;
width: 100%;
align-items: center;
height: 7rem;
`}
${media.md`
display: flex;
width: 100%;
align-items: center;
height: 7rem;
border-right: 1px solid #cccbcb;
`}
${media.lg`

display: flex;

	align-items: center;
	width: 100%;
	border-right: 1px solid #cccbcb;
	height: 7rem;

`}
`

const ProfilStatus = styled.div`
	height: auto;
	display: flex;
	flex-direction: row;
`

const CustomRow = styled(Row)`
	padding: 0% !important;
	margin: 0% !important;
	gap: ${({gap}) => `${gap}rem`};
`

const InnetText = styled.span`
	font-size: 1.25rem;
	color: black;
	font-weight: 400;
`
const Phone = styled(Text)``
const Email = styled(Text)``

const ModalWrapper = styled.div`
	padding: 1.125rem 0rem;
	height: 100%;
	background: ${palette.white};
`
const Cross = styled.img`
  height: 0.85rem;
  cursor: pointer;
  position: absolute;
//   top: 50%;
  right: 2.5rem;
//   transform: translateY(-50%);
`;

const Image = styled.img`
	height: 9.25rem;
	border: 2px solid #f49c10;
	border-radius: 50%;
`
const ProfileData = styled.div`
	border-bottom: 1px solid #cccbcb;
	width: 100%;
	//   height: 11.5rem;
`

export default AllowCreditModal
