import React, { useState,useEffect } from "react";
import styled from "styled-components";
import { palette } from "../styles/colors";
import { Container, Row, Col, media } from "styled-bootstrap-grid";
import { Flexed, Spacer, Text } from "../styles/shared";
import Button from "../components/common/Button";
import { useSelector } from "react-redux";
import { toastError, toastSuccess } from "../styles/toasterStyle";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../actions/authActions";
import ForgotPassword from "./ForgotPassword";

import { forgetPassword, verifyOtp } from "../api/apis";
const Otp = () => {
    const [loginOtp, setLoginOtp] = useState(["", "", "", "", "",""]);
    const [loginOtperror, setLoginOtpError] = useState([]);
    
    const  _navigate=useNavigate()
    const  _dispatch=useDispatch()
    const email = useSelector((state) => state.auth.email)
console.log("email",email)
useEffect(() => {
  if (email===null) {
    
    _navigate("/forgotpassword");
  }
}, []);
    const handleChange = (index, value, e) => {
      const newOtp = [...loginOtp];
      newOtp[index] = value;
      setLoginOtp(newOtp);
    
      // Move focus to the next input field if a digit is entered
      if (value.length === 1 && index < loginOtp.length - 1) {
        const nextInput = document.getElementById(`loginOtp-input-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }
  
      // Remove entry if backspace is pressed and the input field is empty
      if (e.key === "Backspace" && value.length === 0 && index > 0) {
        const prevInput = document.getElementById(`loginOtp-input-${index - 1}`);
        if (prevInput) {
          prevInput.focus();
        }
      }
    };


const handleOtp = async () => {
  _dispatch(setIsLoading(true))
  const otpString = loginOtp.join("");
  const response = await verifyOtp(email,parseInt(otpString))
  console.log('response', response)
  if (response?.success) {  
    _navigate('/newpassword')
    toastSuccess(response?.message);
  } else {
    toastError(response?.message);
  }
  _dispatch(setIsLoading(false))
}

const handleForget = async () => {

  _dispatch(setIsLoading(true))
  // _dispatch(setEmail(emailAddress))

    const response = await forgetPassword(email);
    console.log("response", response);
    if (response?.success) {
      // sessionStorage.setItem(
      //   "authorization",
      //   response?.data?.admin_details?.auth_token
      // );
      // sessionStorage.setItem(
      //   "fullName",
      //   response?.data?.admin_details?.fullName
      // );

      console.log("toastSuccess>>>>?????????", toastSuccess);
      toastSuccess(response?.message);

      // router.push("/otp");
      // Router.push({
      //   pathname: '/Enterotp',
      //   query: { prop: emailAddress},
      // });
      } else {
      toastError(response?.message);
    }
    _dispatch(setIsLoading(false))
  } 

const checkValidationOnClick = () => {
  let isValid = true
  if (loginOtp.some(digit => digit === '' )) {
    setLoginOtpError('otp is required');
    isValid = false;
  }

  console.log("isValid",isValid)
  return isValid
}
  return (
      <>
   <CustomContainer fluid>
            <RowWrapper justifyContent="center" alignItems="center">
              <Wrapper>
                <ColWrapper lg={8}>
                  <HandleWidth direction="column" justify="space-evenly" >
                    <Flexed justify="center">
                    <Logo src="/icons/login.png" alt="logo" />
                   
                    </Flexed>
    
                    <Flexed direction="column">
                    <Text fontSize={2.5} lineHeight="2.5" fontWeight="600" color="black">
                    Enter One Time Code
                    </Text>
    
                      <Spacer height={2} />
    
                      <CustomFlexed direction="row" justify="space-between" flexWrap="wrap">
                {loginOtp.map((digit, index) => (

                  <Input
                    key={index}
                    id={`loginOtp-input-${index}`}
                    type="text"
                    maxLength={1}
                    value={digit}
                    onKeyDown={(e) => {
                      if (!/^\d$/.test(e.key) && e.key !== "Backspace") {
                        e.preventDefault();
                      }
                      handleChange(index, e.target.value, e);
                    }}
                    onChange={(e) =>{
                      setLoginOtpError('')
                      if (e === '') {
                        setLoginOtpError(' required')
                      }
                      handleChange(index, e.target.value, e)}}
                  />
                ))}
              </CustomFlexed>
                    <Spacer height={1} />
                    {/* {
                      loginOtp === '' &&  (
                        <Text fontSize={0.625} type="small" color="danger" textTransform="lowercase">
                        Otp is required
                      </Text>
                      )
                    } */}
            {loginOtperror && (
  <Text fontSize={0.625} type="small" color="danger" textTransform="lowercase">
    {loginOtperror}
  </Text>
)}

           

                    <Spacer height={1} />
                  
        <Flexed justify="end">
        <Text color="yellow" fontWeight="400" fontSize="0.875" onClick={handleForget}> Resend again ?</Text>
          </Flexed>           
      
                      <Spacer height={1} />
                      <Button
                        width="100%"
                        ifClicked={() => {
                          if(checkValidationOnClick())
                         handleOtp()
                        }}
                        label="Next"
                      />
                    </Flexed>
                  </HandleWidth>
                </ColWrapper>
                {/* <ImageWrapper> */}
                <CustomhideCol lg={4}>
                  <Image src="/icons/getstarted.png" />
                </CustomhideCol>
                {/* </ImageWrapper> */}
              </Wrapper>
            </RowWrapper>
          </CustomContainer>
 
        </>
  );
};

const CustomFlexed = styled(Flexed)`
width:100%;
`
const HandleWidth=styled(Flexed)`
width:70%;
${media.xs`
width:95%;
`}
  ${media.sm`
  width:70%;
`}
${media.md`
width:65%;
`}
${media.lg`
width:70%;
`}
`
const Input = styled.input`
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius:7px;

  &:focus {
    border-color: black;
  }
`;
// const CustomCol = styled(Col)`
//   display: flex;

//   gap: 20px;
// `;
const CustomhideCol = styled(Col)`
  ${media.xs`
display:none;
`}
  ${media.sm`
display:none;
`}
${media.md`
display:none;
`}
${media.lg`
padding:0px;
display:block;
height: 80vh;
width: 100%;
border-radius: 0rem 2rem 2rem 0rem;
`}
`;
// const ImageWrapper = styled.div`
//   ${media.xs`
// display:none;
// `}
//   ${media.sm`
// display:none;
// `}
// ${media.md`
// display:none;
// `}
// ${media.lg`

// display:block;
// height: 90vh;
// width: 100%;
// border-radius: 0rem 2rem 2rem 0rem;
// `}
// `;

const Image = styled.img`
  height: 80vh;
  width: 100%;
  border-radius: 0rem 2rem 2rem 0rem;
`;
const Logo = styled.img`
  width: 25%;
`;

const CustomContainer = styled(Container)`
  background-color: ${palette.black};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; 
  align-items: center; 
`;

const RowWrapper = styled(Row)`
  width: 100%;

  margin-left: 0px !important;
`;

const ColWrapper = styled(Col)`
  display: flex;

  justify-content: center; 
`;

const Wrapper = styled.div`
  width: 85%;
  height: 80vh;
  background-color: ${palette.white};
  border-radius: 2.106rem;
  display: flex;
  box-shadow: 0px 0px 15px 0px #F49C10;
`;
export default Otp;
