import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {palette} from '../../styles/colors'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import {Flexed, Heading, Spacer, Text, VerticalSpacer} from '../../styles/shared'
import CustomDropDown from '../common/CustomDropDown'
import Button from '../common/Button'
import DropDown from '../common/DropDown'
import {setIsLoading} from '../../actions/authActions'
import {getCustomerType, getProductGroup, updateCustomer, getAllSPCodes, getCustomerProfileStatus} from '../../api/apis'
import {toastError, toastSuccess} from '../../styles/toasterStyle'
import {useDispatch} from 'react-redux'
import MultiSelectDropDown from '../common/MultiSelectDropDown'
import AllowCreditModal from '../../components/modals/AllowCreditModal'

const ProfileViewModal = ({salesPersonView, selectedProfile, doGetCustomer, selectedStatusValue, onClose}) => {
	const [filterOptions, setFilterOptions] = useState([])
	const [selectedSpCode, setSelectedSpCode] = useState(null)
	const [profileStatus, setProfileStatus] = useState([])
	const [allowCreditModelOpen, setAllowCreditModelOpen] = useState(false)

	console.log('selectedProfile', selectedProfile)
	const [customerTypeOptions, setCustomerTypeOptions] = useState([
		{label: 'Distributer', value: 1},
		{label: 'Shopkeeper', value: 2}
	])
	const [productGroupOptions, setProductGroupOptions] = useState([
		{label: 'Distributer', value: 1},
		{label: 'Shopkeeper', value: 2}
	])
	useEffect(() => {
		doGetSpCodes()
		doGetCustomerType()
		setSelectedSpCode(selectedProfile?.salespersoncode)
		// doGetProductGroup()
		doGetProfileStatus()
	}, [])
	

	const doGetSpCodes = async () => {
		_dispatch(setIsLoading(true))

		const response = await getAllSPCodes()

		//		console.log('responseresponse', response)
		// console.log('responseresponse', response)
		if (response?.success) {
			console.log('getSpcodes++++++++++++++++++++ :>> ', response?.data)
			setFilterOptions(response?.data?.map((item) => ({label: item, value: item})))
			// toastSuccess(response?.message)
		} else {
			// toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}

	const doGetProfileStatus = async () => {
		_dispatch(setIsLoading(true))

		const response = await getCustomerProfileStatus(selectedProfile?.id)
		if (response?.success) {
			setProfileStatus(response?.data)
		} else {
			// toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}

	const [selectedProductGroupType, setSelectedProductGroupType] = useState(selectedProfile?.categories.map((item) => item.id))
	const [selectedCustomerType, setSelectedCustomerType] = useState(selectedProfile?.customer_type?.id)

	const _dispatch = useDispatch()

	const doGetCustomerType = async () => {
		_dispatch(setIsLoading(true))
		const response = await getCustomerType()

		console.log('getCustomerType', response)
		// console.log('responseresponse', response)
		if (response?.success) {
			const filterOptions = response.data.map((item) => ({
				label: item.customerType.substring(0, 12),
				value: item.id
			}))

			setCustomerTypeOptions(filterOptions)
			// toastSuccess(response?.message)
		} else {
			toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}
	const doGetProductGroup = async () => {
		_dispatch(setIsLoading(true))
		const response = await getProductGroup()

		console.log('getProductGroup', response)
		// console.log('responseresponse', response)
		if (response?.success) {
			const filterOptions = response.data.map((item) => ({
				label: item?.groupName?.split('App-')[1],
				value: item.id
			}))

			console.log('filterOptions', filterOptions)
			setProductGroupOptions(filterOptions)
			// toastSuccess(response?.message)
		} else {
			toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}

	const doUpdateCustomer = async (id) => {
		_dispatch(setIsLoading(true))
		console.log('asdasid', id)

		const response = await updateCustomer(id, selectedCustomerType, selectedSpCode, true)

		console.log('responseresponse', response)
		// console.log('responseresponse', response)
		if (response?.success) {
			doGetCustomer()
			onClose()
			toastSuccess(response?.message)
		} else {
			toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}

	return (
		<>
			<Modal
				open={true}
				onClose={onClose}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
				showCloseIcon={false}>
				<ModalWrapper>
					<Container>
						<CustomRow gap={1.5} justifyContent="center">
							<Col lg={2.25}>
								<Image src="/icons/circlePic.png" />
							</Col>
							<Col lg={8}>
								<Flexed
									pointer
									justify="flex-end"
									onClick={() => {
										onClose()
									}}>
									<Cross src="/icons/modalCross.svg" />
								</Flexed>
								<Spacer height={1} />
								<ProfileData>
								<Flexed justify="space-between">
									<Text fontWeight="700" fontSize="2" lineHeight="2" color="black">
										{selectedProfile?.customer_name}
										
									</Text>
									<Flexed gap="0.5" onClick={()=>{setAllowCreditModelOpen(true)}}>
									<CustomText fontWeight="700" fontSize="1.4" lineHeight="2" color="black" >
									Allow Credit
									</CustomText>
									<Img src="./icons/allowcredit.svg" alt="load"/>
									</Flexed>
								</Flexed>
									<Spacer height={2.75} />
									<Flexed gap={4} flexWrap="wrap">
										<Phone fontWeight="650" fontSize="1.5" lineHeight="1.5" color="black">
											Phone &nbsp; <InnetText>{selectedProfile?.phone_no}</InnetText>
										</Phone>
										<Email fontWeight="650" fontSize="1.5" lineHeight="1.5" color="black">
											Email &nbsp;
											<InnetText>{selectedProfile?.customer_email}</InnetText>
										</Email>
									</Flexed>
									<Spacer height={2.875} />
								</ProfileData>
							</Col>
						</CustomRow>
						{/* <Scroll> */}
						<CustomRow justifyContent={salesPersonView ? 'center' : 'end'}>
							<Col lg={salesPersonView ? 10.25 : 8.5}>
								<Spacer height={1} />
								<Row>
									<Col lg={salesPersonView ? 3 : 3.5} md={salesPersonView ? 3 : 3.5}>
										<StatusHeading fontWeight="650" fontSize={1.5} lineHeight={2}>
											Profile Status
										</StatusHeading>
									</Col>
									<Col lg={salesPersonView ? 9 : 7.5} md={salesPersonView ? 9 : 7.5}>
										<Flexed justify="space-around" gap={0.5} flexWrap="wrap">
											<Flexed direction="column">
												<Text fontWeight="600" isCentered color="black" fontSize="2" lineHeight={3}>
													 {profileStatus?.completedOrders} 
													
												</Text>
												<Text color="black" fontSize="1.25" lineHeight={3}>
													Completed
												</Text>
											</Flexed>
											<Flexed direction="column">
												<Text fontWeight="600" isCentered color="black" fontSize="2" lineHeight={3}>
												 {profileStatus?.activeOrders} 
												
												</Text>
												<Text  color="black" fontSize="1.25" lineHeight={3}>
													Active Order
												</Text>
											</Flexed>
											<Flexed direction="column">
												<Text isCentered fontWeight="600" color="black" fontSize="2" lineHeight={3}>
												 {profileStatus?.cancelledOrders} 
												
												</Text>
												<Text color="black" fontSize="1.25" lineHeight={3}>
													Cancelled Order
												</Text>
											</Flexed>
											{salesPersonView && (
												<>
													<Flexed direction="column">
														<Text isCentered color="black" fontSize="2" lineHeight={3}>
															£710006
														</Text>
														<Text color="black" fontSize="1.25" lineHeight={3}>
															Total Sales
														</Text>
													</Flexed>
													<Flexed direction="column">
														<Text isCentered color="black" fontSize="2" lineHeight={3}>
															11
														</Text>
														<Text color="black" fontSize="1.25" lineHeight={3}>
															Total Active Customers
														</Text>
													</Flexed>
												</>
											)}
										</Flexed>
									</Col>
								</Row>
							</Col>
						</CustomRow>
						<Spacer height={2.5} />

						<CustomRow justifyContent="center">
							<Col lg={10.5}>
								<Heading lineHeight={2} color="black" fontSize={2}>
									Registered Customer Details
								</Heading>
								<Spacer height={2.625} />
							</Col>
						</CustomRow>

						<CustomRow justifyContent="center">
							<Col lg={5.5} md={12} sm={12} xs={12}>
								<CustomFlexed gap="2">
									<Flexed direction="column" gap={0.5} flexWrap="wrap">
										<Flexed flexWrap="wrap" gap={1}>
											<Text minWidth={10} color="black" fontWeight={700} fontSize="1.25" lineHeight={2}>
												Company Name
											</Text>
											 <Text color="black" fontSize="1.05" lineHeight={2}>
											{selectedProfile?.company_name }
										</Text>
										</Flexed>
										<Flexed gap={1}>
											<Text color="black" minWidth={10} fontWeight={700} fontSize="1.25" lineHeight={2}>
												Company Email
											</Text>
											<Text color="black" fontSize="1.05" lineHeight={2}>
											{selectedProfile?.company_email}
										</Text>
										</Flexed>
										<Flexed gap={1}>
											<Text color="black" minWidth={10} fontWeight={700} fontSize="1.25" lineHeight={2}>
												Delivery Address
											</Text>
											<Text color="black" fontSize="1.05" lineHeight={2}>
											{selectedProfile?.deliveryAddress?.address}
										</Text>
										</Flexed>
									</Flexed>
									{/* <Flexed direction="column" justify="space-between" gap={0.5}>
										<Text color="black" fontSize="1.05" lineHeight={2}>
											{selectedProfile?.company_name }
										</Text>
										<Text color="black" fontSize="1.05" lineHeight={2}>
											{selectedProfile?.company_email}
										</Text>
										<Text color="black" fontSize="1.05" lineHeight={2}>
											{selectedProfile?.billing_address}
										</Text>
									</Flexed> */}
								</CustomFlexed>
							</Col>

							<CustomCol lg={5} md={12} sm={12} xs={12}>
								<CustomFlex>
									<Flexed direction="column" justify="space-between" gap={0.5}>
										<Flexed>
											<Text color="black" fontWeight={700} fontSize="1.25" lineHeight={2}>
												SP Code
											</Text>
										</Flexed>
										<Flexed>
											<Text color="black" fontWeight={700} fontSize="1.25" lineHeight={2}>
												Customer Type
											</Text>
										</Flexed>
										{/* <Flexed>
											<Text color="black" fontWeight={700} fontSize="1.25" lineHeight={2}>
												Product Group
											</Text>
										</Flexed> */}
									</Flexed>
									<Flexed direction="column" justify="center" align="center" gap={0.5}>
										{/* <Text color="black" fontSize="1.05" lineHeight={2}>
											{selectedProfile?.salespersoncode}
										</Text> */}
										<DropDown
											styledHeight={6}
											firstSelected={selectedSpCode}
											hasChanged={(value) => {
												// setSelectedDueDateError('')
												// if (value == '') {
												// 	setSelectedDueDateError('due is Required')
												// }
												setSelectedSpCode(value)
											}}
											width="8"
											// required
											// error={selectedDueDateError}
											// errorMsg={selectedDueDateError}
											options={filterOptions}
										/>
										<DropDown
											styledHeight={6}
											firstSelected={selectedProfile?.customer_type?.customerType}
											hasChanged={(value) => {
												// setSelectedDueDateError('')
												// if (value == '') {
												// 	setSelectedDueDateError('due is Required')
												// }
												setSelectedCustomerType(value)
											}}
											width="8"
											// required
											// error={selectedDueDateError}
											// errorMsg={selectedDueDateError}
											options={customerTypeOptions}
										/>
										{/* <MultiSelectDropDown
											styledHeight={6}
											selectedProductGroupType={selectedProductGroupType}
											firstSelected={selectedProfile?.categories?.map((item) => item.groupName?.split('App-')[1])}
											hasChanged={(value) => {
												// setSelectedDueDateError('')
												// if (value == '') {
												// 	setSelectedDueDateError('due is Required')
												// }

												console.log('setSelectedProductGroupType', value)
												setSelectedProductGroupType(value)
											}}
											width="8"
											// required
											// error={selectedDueDateError}
											// errorMsg={selectedDueDateError}
											options={productGroupOptions}
										/> */}
									</Flexed>
								</CustomFlex>
							</CustomCol>
						</CustomRow>

						<CustomRow justifyContent="center">
							<Col lg={3}>
								<Spacer height={2} />
								<Button
									width="100%"
									label="Update User"
									ifClicked={() => {
										console.log('selectedStatusValue', selectedStatusValue, selectedCustomerType)
										if (selectedStatusValue && selectedCustomerType) doUpdateCustomer(selectedProfile?.id)
										else {
											toastError('Please Selected Customer Type First')
										}
									}}
								/>
								<Spacer height={1} />
							</Col>
						</CustomRow>
						{/* </Scroll> */}
					</Container>
				</ModalWrapper>
			</Modal>
			{allowCreditModelOpen && (<>
			<AllowCreditModal onClose={()=>{setAllowCreditModelOpen(false)}} selectedProfile={selectedProfile} />
			</>)}
		</>
	)
}

const Scroll = styled.div`
	overflow-y: auto;

	height: 22rem;

	&::-webkit-scrollbar {
		width: 0.3rem !important;
		height: 0.1rem !important;
	}

	&::-webkit-scrollbar-track {
		background: #eee;
		border-radius: 1rem;
		border: 0.01rem solid ${palette.silver};
		border-width: 0rem 0.1rem 0rem 0rem;
	}

	&::-webkit-scrollbar-thumb {
		background: ${palette.yellow};
		border-radius: 1rem;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`
const CustomCol = styled(Col)`
	display: flex;
	align-items: center;
`
const CustomFlex = styled(Flexed)`
	width: 100% !important;
	display: flex;
	justify-content: space-between;
	align-items: center;
`
const CustomFlexed = styled(Flexed)`
	width: 100% !important;
	// gap: 3rem;
`

const StatusHeading = styled(Heading)`
	${media.xs`
display: flex;
width: 100%;
align-items: center;
height: 5rem;
`}
	${media.sm`
display: flex;
width: 100%;
align-items: center;
height: 7rem;
`}
${media.md`
display: flex;
width: 100%;
align-items: center;
height: 7rem;
border-right: 1px solid #cccbcb;
`}
${media.lg`

display: flex;

	align-items: center;
	width: 100%;
	border-right: 1px solid #cccbcb;
	height: 7rem;

`}
`

const ProfilStatus = styled.div`
	height: auto;
	display: flex;
	flex-direction: row;
`

const CustomRow = styled(Row)`
	padding: 0% !important;
	margin: 0% !important;
	gap: ${({gap}) => `${gap}rem`};
`

const InnetText = styled.span`
	font-size: 1.25rem;
	color: black;
	font-weight: 400;
`
const Phone = styled(Text)``
const Email = styled(Text)``

const ModalWrapper = styled.div`
	padding: 1.125rem 0rem;
	height: 100%;
	background: ${palette.white};
`
const Cross = styled.img`
	height: 0.85rem;
	cursor:pointer;
`
const Img = styled.img`
	cursor:pointer;
`

const Image = styled.img`
	height: 9.25rem;
	border: 2px solid #f49c10;
	border-radius: 50%;
`
const ProfileData = styled.div`
	border-bottom: 1px solid #cccbcb;
	width: 100%;
	//   height: 11.5rem;
`
const CustomText=styled(Text)`
cursor:pointer;
`
export default ProfileViewModal
