import React, {useState, useEffect, useRef} from 'react'
import styled from 'styled-components'
import {palette} from '../../styles/colors'
import {Text} from '../../styles/shared'
import {IoMdArrowDropdown} from 'react-icons/io'

const DropDown = ({firstSelected, setIsAllowToOrderModalOpen, modal, label, subLable, options, name, hasChanged, error, required, errorMsg, disabled, width, height, stype = false}) => {
	const myRef = useRef()

	const [isOpen, setIsOpen] = useState(false)
	const [selectedOption, setSelectedOption] = useState('')
	useEffect(() => {
		setSelectedOption(firstSelected)
	}, [firstSelected])

	DropDown['handleClickOutside_' + name] = () => {
		setIsOpen(false)
	}

	const toggling = () => {
		if (options?.length > 0) {
			setIsOpen(!isOpen)
		}
	}

	const onOptionClicked = (option) => () => {
		setSelectedOption(option.label)
		hasChanged(option.value)
		setIsOpen(false)
	}

	const handleClickOutside = (e) => {
		if (!myRef.current.contains(e.target)) {
			setIsOpen(false)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => document.removeEventListener('mousedown', handleClickOutside)
	})

	return (
		<>
			{label && (
				<Label type={stype ? `xlarge` : `small`} margin="0rem 0rem 0.19rem 0rem">
					{label}
					{required ? <Mandatory>*</Mandatory> : ''}
				</Label>
			)}
			<DropDownContainer ref={myRef} width={width} height={height} disabled={disabled || options?.length == 0} error={disabled || options?.length == 0 ? false : error}>
				<DropDownHeader disabled={disabled} onClick={() => toggling()}>
					<div> </div> {selectedOption?.substring(0, 10) || <DefaultValue>Select</DefaultValue>} <Icon id="icon"> {!isOpen ? <ArrowDown /> : <ArrowUp />} </Icon>
				</DropDownHeader>
				{isOpen && (
					<DropDownListContainer disabled={disabled}>
						<DropDownList>
							{options?.map((option) => (
								<ListItem onClick={onOptionClicked(option)} key={Math.random()}>
									{option.label}
								</ListItem>
							))}
						</DropDownList>
					</DropDownListContainer>
				)}
			</DropDownContainer>
			{required && error && !disabled && (
				<Text fontSize={0.625} type="small" color="danger" textTransform="lowerCase">
					{errorMsg}
				</Text>
			)}
		</>
	)
}

const Label = styled(Text)`
	font-weight: 400;
	color: ${palette.black};
	/* text-transform: capitalize; */
`

const Mandatory = styled.span`
	color: ${palette.black};
	margin-left: 0.3rem;
`

const DropDownContainer = styled('button')`
	font-family: 'Helvetica Neue', sans-serif;
	position: relative;
	width: ${({width}) => (width ? `${width}rem` : '100%')};
	height: ${({height}) => (height ? `${height}rem` : '2.5rem')};
	line-height: 1.5rem;
	outline: none;
	font-weight: 400;
	text-align: left;
	font-size: 0.875rem;
	border-radius: 0.375rem;
	padding: 0.2rem 0.5rem;
	border: 1px solid ${({error, disabled}) => (disabled ? `${palette.lightGrey}` : error ? `${palette.danger}` : `${palette.black}`)};
	color: ${({disabled}) => (disabled ? `${palette.grey}` : `${palette.black}`)};
	background: ${({disabled, bgTransparent}) => (bgTransparent ? 'transparent' : disabled ? `${palette.white}` : `${palette.white}`)};
	cursor: ${({disabled}) => (disabled ? `no-drop` : `pointer`)};
	&:focus {
		border: 1px solid ${({error, disabled}) => (disabled ? 'none' : error ? palette.danger : palette.grey)};
	}
	&::placeholder {
		color: ${({disabled}) => (disabled ? `${palette.grey}` : `${palette.black}`)};
		opacity: 0.5; /* Firefox */
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: ${({disabled}) => (disabled ? `${palette.grey}` : `${palette.black}`)};
	}

	&::-ms-input-placeholder {
		/* Microsoft Edge */
		color: ${palette.grey};
	}
`

const Icon = styled.i`
	color: ${palette.text_color};
	font-size: 0.7rem;
	margin-left: 1rem;
`

const DropDownHeader = styled('div')`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-family: 'Helvetica Neue', sans-serif;
	font-weight: 500;
	/* text-transform: capitalize; */
	color: ${({disabled, isDarkTheme}) => (disabled ? `${palette.grey}` : isDarkTheme ? `${palette.black}` : `${palette.black}`)};
`
const DefaultValue = styled.span`
	opacity: 0.5;
`

const DropDownListContainer = styled('div')`
	display: ${({isdisabled}) => (isdisabled ? `none` : `block`)};
	position: absolute;
	left: 0;
	right: 0;
	top: 2.3rem;
	z-index: 99;
`

const DropDownList = styled('ul')`
	box-shadow: 0 0 6px 0 rgba(38, 140, 234, 0.25);
	margin: 0;
	padding: 0.5rem 0;
	font-family: 'Helvetica Neue', sans-serif;
	width: 100%;
	line-height: 1.5rem;
	outline: none;
	/* max-height: 10rem; */
	overflow-y: auto;
	font-weight: 400;
	text-align: left;
	font-size: 0.875rem;
	border-radius: 0.375rem;
	/* border: 1px solid ${({error, disabled}) => (disabled ? `${palette.lightGrey}` : error ? `${palette.danger}` : `${palette.black}`)}; */
	color: ${({disabled}) => (disabled ? `${palette.grey}` : `${palette.black}`)};
	background: ${({disabled, bgTransparent}) => (bgTransparent ? 'transparent' : disabled ? `${palette.white}` : `${palette.white}`)};

	&:focus {
		/* border: 1px solid ${({error, disabled}) => (disabled ? 'none' : error ? palette.danger : palette.black)}; */
	}
	&::placeholder {
		color: ${({disabled}) => (disabled ? `${palette.grey}` : `${palette.black}`)};
		opacity: 0.5; /* Firefox */
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: ${({disabled}) => (disabled ? `${palette.grey}` : `${palette.black}`)};
	}

	&::-ms-input-placeholder {
		/* Microsoft Edge */
		color: ${palette.grey};
	}
`

const ListItem = styled('li')`
	list-style: none;
	line-height: 1.5rem;
	white-space: nowrap;
	padding: 0.5rem 0.8rem;
	/* border-bottom: 1px solid ${palette.grey}; */
	font-family: 'Helvetica Neue', sans-serif;
	text-transform: normal;
	color: ${palette.heading_color};
	&:hover {
		background: ${palette.lightYellow};
		color: ${palette.black};
	}

	/* &:first-child {
		border-top-left-radius: 0.375rem;
		border-top-right-radius: 0.375rem;
	}
	&:last-child {
		border-bottom-left-radius: 0.375rem;
		border-bottom-right-radius: 0.375rem;
	}
	&:last-child {
		border-bottom: 0;
	} */
`

const ArrowUp = styled(IoMdArrowDropdown)`
	transform: rotate(180deg);
	/* color: ${palette.grey}; */
`
const ArrowDown = styled(IoMdArrowDropdown)`
	/* color: ${palette.grey}; */
`

export default DropDown
