import React from 'react'
import {Spacer, Text} from '../../styles/shared'
import {styled} from 'styled-components'
import {palette} from '../../styles/colors'

const Box = ({icon, text, count}) => {
	return (
		<Wrapper>
			<div>
				<IconWrapper>
					<Icon src={icon} />
				</IconWrapper>
			</div>
			<Spacer height={1} />
			<Text type="xlarge" color="black">
				{text}
			</Text>
			<Spacer height={0.375} />
			<Text type="xlarge" color="black">
				{count}
			</Text>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	padding: 1.375rem;
	background-color: ${palette.white};
	border-radius: 0.625rem;
`

const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 3.125rem;
	height: 3.125rem;
	border-radius: 0.625rem;
	background-color: ${palette.black};
`

const Icon = styled.img`
	width: 30px;
	height: 30px;
`

export default Box
