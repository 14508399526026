import React, {useEffect, useState} from 'react'
import {Container, media} from 'styled-bootstrap-grid'
import {styled} from 'styled-components'
import {palette} from '../styles/colors'
import SearchSection from '../components/common/SearchSection'
import {Flexed, Spacer, Text} from '../styles/shared'
import Button from '../components/common/Button'
import CustomersTable from '../components/tables/CustomersTable'
import Toggle from '../components/common/Toggle'
import DropDown from '../components/common/DropDown'
import {useDispatch} from 'react-redux'
import ProductsTable from '../components/tables/ProductsTable'
import EditProductsModal from '../components/modals/EditProductsModal'
import SyncModal from '../components/modals/SyncModal'
import {getProductGroup, updateProduct, getProducts, updateProductStatus, syncProductGroups, processAndStoreData, getProductsToSync} from '../api/apis'
import {setIsLoading} from '../actions/authActions'
import {toastError, toastSuccess} from '../styles/toasterStyle'
import DeleteModal from '../components/modals/DeleteModal'
import WaitgettingProductModal from '../components/modals/WaitgettingProductModal'

const Products = () => {
	const [search, setSearch] = useState('')
	const [openEditProductModal, setOpenEditProductModal] = useState(false)
	const [updateProduct, setUpdateProduct] = useState({})
	const [openSyncProductModal, setOpenSyncProductModal] = useState(false)
	const [pageNumber, setPageNumber] = useState(1)
	const [pagesAmount, setPagesAmount] = useState(1)
	const [totalNumberOfProducts, setTotalNumberOfProducts] = useState(1)
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
	const [isWaiting, setIsWaiting] = useState(false)
	const [modelProductsData, setmodelProductsData] = useState([])
	const [selectedProductsGroup, setSelectedProductsGroup] = useState([])
	const [productGroup, setProductGroup] = useState([])
	const _dispatch = useDispatch()

	useEffect(() => {
		doGetProducts()
	}, [pageNumber,search])

	const doGetSyncProducts = async () => {
		setIsWaiting(true)
		const response = await syncProductGroups()

	//	console.log('responseresponse aa gya sync model step 1 ', response)
		// console.log('responseresponse', response)
		if (response?.success) {
			setProductGroup(response?.data)
			const syncresponse = await processAndStoreData()
			if (syncresponse?.success) {
			//	console.log('responseresponse aa gya sync model step 2 ', syncresponse)
				const productsSyncResponse = await getProductsToSync(selectedProductsGroup,1, 6)
				if (productsSyncResponse?.success) {
					 //toastSuccess(syncresponse?.message)
					setmodelProductsData(productsSyncResponse)
					setIsWaiting(false)
					setOpenSyncProductModal(true)
				} else {
					toastError(syncresponse?.message)
					setIsWaiting(false)
				}
			} else {
				toastError(response?.message)
				setIsWaiting(false)
			}
		} else {
			toastError(response?.message)
			setIsWaiting(false)
		}
	}

	async function handleToggle(id, value) {
		_dispatch(setIsLoading(true))
		const response = await updateProductStatus(id, value)
		if (response?.success) {
			toastSuccess(response?.message)
			doGetProducts()
		} else {
			toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}

	const [productsData, setProductsData] = useState([])

	const doGetProducts = async () => {
		_dispatch(setIsLoading(true))

		const response = await getProducts(pageNumber, 6, search)

	//	console.log('responseresponse aa gya ', response)
		// console.log('responseresponse', response)
		if (response?.success) {
			setPagesAmount(Math.ceil(response?.count / 6))
			setTotalNumberOfProducts(response?.count)
			console.table('amount ', pagesAmount, totalNumberOfProducts)
			const apiRows = response?.data?.map((product,index) => ({
				pid: (
					<Text type="medium" color="black" fontWeight={300}>
						{((pageNumber-1)*6)+index+1}
					</Text>
				),
				name: (
					<div>
						<Text type="medium" color="black" fontWeight={300}>
							{product?.productName}
						</Text>
					</div>
				),
				description: (
					<Text type="medium" color="black" fontWeight={300}>
						{product?.productDesc}
					</Text>
				),
				qty: (
					<Text type="medium" color="black" fontWeight={300}>
						{product?.productQuantity}
					</Text>
				),
				retailPrice: (
					<Text type="medium" color="black" fontWeight={300}>
						{product?.retailorPrice}
					</Text>
				),
				status: <Toggle value={product?.productAvailable} submit={handleToggle} id={product?.id} />,
				icon: (
					<Flexed direction="row" align="center" gap={0.75} justify="flex-start">
						<IconWrapper
							onClick={() => {
								setUpdateProduct(product)
								setOpenEditProductModal(true)
							}}>
							<Icon src="icons/pencil.svg" />
						</IconWrapper>
						{/* <IconWrapper
							onClick={() => {
								setIsDeleteModalOpen(true)
							}}>
							<Icon src="icons/delete.svg" />
						</IconWrapper> */}
					</Flexed>
				)
			}))
			setProductsData(apiRows)
			// toastSuccess(response?.message)
		} else {
			toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}

	return (
		<>
			<Wrapper>
				<Container fluid>
					<SearchSection title="Products" search={search} setSearch={setSearch} />
					<Spacer height={0.625} />
					<Button
						ifClicked={() => {
							doGetSyncProducts()
						}}
						small
						width="11rem"
						icon="productsSycn.svg"
						label="Sync Products"
					/>

					<Spacer height={0.625} />
					<TableWrapper>
						<ProductsTable totalNumberOfProducts={totalNumberOfProducts} setPageNumber={setPageNumber} pageNumber={pageNumber} productsData={productsData} pagesAmount={pagesAmount} />
					</TableWrapper>
				</Container>
			</Wrapper>
			{openEditProductModal && (
				<EditProductsModal
					doGetProducts={doGetProducts}
					updateProduct={updateProduct}
					onClose={() => {
						setOpenEditProductModal(false)
					}}
				/>
			)}
			{openSyncProductModal && (
				<SyncModal
					doGetProducts={doGetProducts}
					productsGroup={productGroup}
					productsData={modelProductsData}
					onClose={() => {
						setOpenSyncProductModal(false)
					}}
				/>
			)}

			{isWaiting && (
				<WaitgettingProductModal
					onClose={() => {
						setIsWaiting(false)
					}}
				/>
			)}

			{isDeleteModalOpen && (
				<DeleteModal
					onSubmit={() => {
						// doDeleteCustomer()
					}}
					label="Are you sure you want to delete this product?"
					onClose={() => {
						setIsDeleteModalOpen(false)
					}}
				/>
			)}
		</>
	)
}

const Wrapper = styled.div`
	background-color: ${palette.lightGrey};
	min-height: 100vh;
	${media.xs`
	padding: 1rem 1rem;
	`}
	${media.sm`
	padding: 1rem 1rem;
	`}
	${media.md`
	padding: 1.625rem 1.25rem 2.625rem 1.25rem;
	`}
`

const ProductImage = styled.img`
	width: 75px;
	height: 75px;
`

const TableWrapper = styled.div`
	border-radius: 0.625rem;
	background-color: ${palette.white};
	padding: 0.75rem;
`

const IconWrapper = styled.div`
	background-color: ${palette.black};
	border-radius: 0.625rem;
	width: 2.25rem;
	height: 2.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
`

const Icon = styled.img`
	width: 1.25rem;
	height: 1.25rem;
	cursor: pointer;
`
export default Products
