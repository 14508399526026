import React, {useState} from 'react'
import styled from 'styled-components'
import {palette} from '../../styles/colors'
import {Text} from '../../styles/shared'

// const Toggle = ({setToggle, toggle}) => {
const Toggle = ({id, value, submit}) => {
	const [toggle, setToggle] = useState(value)
	console.log('id', id)
	return (
		<Wrapper
			toggle={toggle}
			onClick={() => {
				if (submit) submit(id, !toggle)
				setToggle(!toggle)
			}}>
			{toggle && <Text color="yellow">Active</Text>}
			<Dot toggle={toggle} />
			{!toggle && <Text color="darkGrey">Inactive</Text>}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: ${({toggle}) => (toggle ? 'flex-end' : 'flex-start')};
	background-color: ${({toggle}) => (toggle ? palette.black : '#CCCBCB')};
	gap: ${({toggle}) => (toggle ? '0.5rem' : '0.5rem')};
	width: 5.375rem;
	height: 2rem;
	border-radius: 20px;
	padding: 0.313rem 0.313rem;
	transition: justify-content 2s, transform 2s;
	/* border: 0.063rem solid ${({toggle}) => (toggle ? palette.fbBg : palette.fbBg)}; */
	cursor: pointer;
`

const Dot = styled.div`
	width: 22px;
	height: 22px;
	border-radius: 100%;
	background-color: ${({toggle}) => (toggle ? palette.yellow : '#3F3F3F')};
`
export default Toggle
