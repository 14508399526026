import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { palette } from "../../styles/colors";

import { Col, Container, Row, media } from "styled-bootstrap-grid";
import {
  Flexed,
  Heading,
  Spacer,
  Text,
  VerticalSpacer,
} from "../../styles/shared";
import CustomDropDown from "../common/CustomDropDown";
import Button from "../common/Button";
import DropDown from "../common/DropDown";
import CheckBox from "../common/CheckBox";
import Toggle from "../common/Toggle";
import CustomersTable from "../tables/CustomersTable";
import OrderDetailsTable from "../tables/OrderDetailsTable";
import OrderRefundModal from "./OrderRefundModal";
import CustomCheckBox from "../common/CustomCheckBox";
import {createUnleashedSalesOrder, updateOrderItem} from "../../api/apis"
import {setIsLoading} from '../../actions/authActions'
import {useDispatch} from 'react-redux'
import {toastError, toastSuccess} from '../../styles/toasterStyle'
import SuccessModal from "./SuccessModal";

const OrdersDetailsModal = ({ onClose,  selectedPro , doGetOrders }) => {
  const [selectedProduct,setSelectedProduct]= useState([]) 
  const [openRefundModal, setOpenRefundModal] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [selectedProducts,setSelectedProducts]= useState([])
console.log('selectedProduct', selectedProduct)
const _dispatch = useDispatch()

const handleKeyDown = (e) => {
  // Prevent any key press to ensure the input is read-only
  e.preventDefault();
};

useEffect(() => {
  setSelectedProduct(selectedPro)
  selectedPro?.items?.forEach((item) => {
      setSelectedProducts((previousValue) => {
        // Check if the item.id already exists in the previousValue array
        if (!previousValue.includes(item.id)) {
          // If it doesn't exist, add it to the array
          return [...previousValue, item.id];
        } else {
          // If it does exist, return the original array
          return previousValue;
        }
      });
    });
  
}, [selectedPro]); 

  const [orderDetails, setOrderDetails] = useState([]);


  useEffect(()=>{
    const newOrderDetails = selectedProduct?.items?.map((item,index) => {
      return {
        id: <CustomCheckBox height={1} isChecked={(selectedProducts.includes(item.id)) ? true :false} setIsChecked={(value)=>{
          handleToggleValue(item?.id)
        }}/>,
        productName: (
        
            <Text type="medium" color="black" fontWeight={300}>
              {item.productName}
            </Text>
    
        ),
        qty: (
          <InputText
          type="number"
          value={selectedProduct?.items[index]?.productQty}
          onChange={(e) => replaceProductQty(index, parseInt(e.target.value))}
          min="0" // Set minimum value to avoid negative quantities
          onKeyDown={handleKeyDown}
          max={selectedPro?.items[index]?.productQty}
        />
        ),
        price: (
          <Text type="medium" color="black" fontWeight={300}>
            {item.productPrice}
          </Text>
        ),
        total: (
          <Text type="medium" color="black" fontWeight={300}>
            {(item.productPrice * item.productQty).toFixed(2)}
          </Text>
        ),
      };
    });

    setOrderDetails(newOrderDetails);
  },[selectedProduct,selectedProducts])

  const handleToggleValue = async (id) => {
    if (selectedProducts.includes(id)) {
      // If value is already in the array, remove it
      setSelectedProducts(selectedProducts.filter((item) => item !== id));
    } else {
      // Otherwise, add it to the array
      setSelectedProducts([...selectedProducts, id]);
    }
  };

  const replaceProductQty = (itemIndex, newQty) => {
    console.log('newQty :>> ', newQty);
    setSelectedProduct({
      ...selectedProduct,
      items: selectedProduct.items.map((item, index) => {
        if (index === itemIndex) {
          // Replace the productQty with the new value
          return { ...item, productQty: newQty };
        }
        return item; // If the index doesn't match, keep the item unchanged
      }),
    });
  };
  

  const doProceedOrder = async () => {
			_dispatch(setIsLoading(true))
      let LatestItems= []
      selectedProduct?.items?.map((item) => {
        if (selectedProducts.includes(item?.id)) {
          LatestItems.push({itemId:item?.id, productQty:item?.productQty})
        }
      });
      let data = {
        orderId: selectedProduct?.id,
        items: LatestItems,
        total:(calculateSubTotalAmount()+(calculateSubTotalAmount()*0.2)+selectedProduct?.shippingCharges),
        vat:(calculateSubTotalAmount()*0.2)
      };

     const updateOrder = await updateOrderItem(data)
        if(updateOrder?.success)
          {
            const response = await createUnleashedSalesOrder(selectedProduct?.id)

            //	console.log('responseresponse', response)
              // console.log('responseresponse', response)
              if (response?.success) {
                setOpenSuccessModal(true)
                doGetOrders()
                
                toastSuccess(response?.message)
                onClose()
                
              } else {
                toastError(response?.message)
              }
          }
        else {
          toastError(updateOrder?.message)
        }
		
			_dispatch(setIsLoading(false))
		
	}
  function calculateSubTotalAmount() {
    let totalAmount = 0;

    selectedProducts?.map((item) => {
        selectedProduct?.items?.map((iteminner) => {
            if (iteminner?.id === item) {
                totalAmount += iteminner?.productQty * iteminner?.productPrice;
            }
        });
    });

    return totalAmount;
}

function doTestRes(){
  let LatestItems= []
  selectedProduct?.items?.map((item) => {
    if (selectedProducts.includes(item?.id)) {
      LatestItems.push({id:item?.id, productQty:item?.productQty})
    }
  });
  let data = {
    orderId: selectedProduct?.id,
    items: LatestItems,
    total:(calculateSubTotalAmount()+(calculateSubTotalAmount()*0.2)+selectedProduct?.shippingCharges),
    vat:(calculateSubTotalAmount()*0.2)
  };
 
  console.log('data :>> ', data);
}
  return (
    <>
      <Modal
        open={true}
        onClose={onClose}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
        }}
        showCloseIcon={false}
      >
        <ModalWrapper>
          <Container>
            <Row>
              <CustomCol lg={7}>
                <Wrapper>
                  <Heading color="black" fontSize={1.5}>
                    Order Details
                  </Heading>
                  <Spacer height={1.5} />
                
                  <Scroll height={15}>
                    <TableWrapper>
                      <OrderDetailsTable OrderDetails={orderDetails} />
                    </TableWrapper>
                  </Scroll>
                </Wrapper>
                <Spacer height={3} />
                <CustomerWrapper>
                  <Heading color="black" fontSize={1.5}>
                    Paid by Customer
                  </Heading>
                  <Spacer height={1.5} />
                  <Billing>
                    <CustomFlex justify="space-between" align="end">
                      <CustomFlexed gap={4}>
                        <Text color="black" fontSize="1.5">
                          Subtotal
                        </Text>
                        <CustomText color="black" fontSize="1.5">
                        {selectedProducts.length} items
                        </CustomText>
                      </CustomFlexed>
                      <Text color="black" fontSize="1.5">
                      {calculateSubTotalAmount().toFixed(2)}

                      </Text>
                    </CustomFlex>

                    <Spacer height={1.5} />

                    <CustomFlex justify="space-between" align="end">
                      <CustomFlexed gap={4}>
                        <Text color="black" fontSize="1.5">
                          Shipping
                        </Text>
                        <CustomText color="black" fontSize="1.5">
                        {selectedProducts.length} items
                        </CustomText>
                      </CustomFlexed>
                      <Text color="black" fontSize="1.5">
                      {selectedProduct?.shippingCharges}
                      </Text>
                    </CustomFlex>

                    <Spacer height={1.5} />
                    <CustomFlex justify="space-between" align="end">
                      <CustomFlexed gap={4}>
                        <Text color="black" fontSize="1.5">
                          Vat
                        </Text>
                      </CustomFlexed>
                      <Text color="black" fontSize="1.5">
                      {(calculateSubTotalAmount()*0.2).toFixed(2)}
                      </Text>
                    </CustomFlex>
                    <Spacer height={2} />
                    <Total justify="space-between" align="end">
                      <Text
                        color="black"
                        fontSize="1.5"
                        fontWeight={700}
                        lineHeight={1.5}
                      >
                        Total paid by customer
                      </Text>
                      <Text color="black" fontSize="1.5" lineHeight={1.5}>
                      {(calculateSubTotalAmount()+(calculateSubTotalAmount()*0.2)+selectedProduct?.shippingCharges).toFixed(2)} 
                      </Text>
                    </Total>
                  </Billing>
                </CustomerWrapper>
              </CustomCol>

              <CustomCol lg={5}>
                <Wrapper>
               <CustomSpacer />
                  <Heading color="black" fontSize={1.5}>
                    Customer Information
                  </Heading>
                  <Spacer height={1.5} />
                  <TableWrapper>
                    <Scroll height="37">
                      <Text color="black" fontSize="1" lineHeight={1.5}>
                        Name
                      </Text>
                      <Text
                        color="black"
                        fontSize="1.5"
                        fontWeight={700}
                        lineHeight={1.5}
                      >
                        {selectedProduct?.customer?.customer_name}
                      </Text>
                      <Spacer height={2} />

                      <Text color="black" fontSize="1" lineHeight={1.5}>
                        Sale Person Code
                      </Text>
                      <Text
                        color="black"
                        fontSize="1.5"
                        fontWeight={700}
                        lineHeight={1.5}
                      >
                          {selectedProduct?.customer?.salespersoncode}
                      </Text>
                      <Spacer height={2} />

                      <Text color="black" fontSize="1" lineHeight={1.5}>
                        Email
                      </Text>
                      <Text
                        color="black"
                        fontSize="1.5"
                        fontWeight={700}
                        lineHeight={1.5}
                      >
                          {selectedProduct?.customer?.customer_email}
                        
                      </Text>
                      <Spacer height={2} />

                      <Text color="black" fontSize="1" lineHeight={1.5}>
                        Phone Number
                      </Text>
                      <Text
                        color="black"
                        fontSize="1.5"
                        fontWeight={700}
                        lineHeight={1.5}
                        >
                    
                        {selectedProduct?.customer?.phone_no}
                      </Text>
                      <Spacer height={2} />

                      <Text color="black" fontSize="1" lineHeight={1.5}>
                      Delivery Address
                      </Text>
                      <Text
                        color="black"
                        fontSize="1.5"
                        fontWeight={700}
                        lineHeight={1.5}
                      >
                            {selectedProduct?.deliveryAddress?.address}, {selectedProduct?.deliveryAddress?.city} {selectedProduct?.deliveryAddress?.zipCode} {selectedProduct?.deliveryAddress?.state}
                      </Text>
                      <Spacer height={2} />

                      <Text color="black" fontSize="1" lineHeight={1.5}>
                    Billing Address
                      </Text>
                      <Text
                        color="black"
                        fontSize="1.5"
                        fontWeight={700}
                        lineHeight={1.5}
                        >
                        {/* {selectedProduct?.billingAddress?.address} */}
                        {selectedProduct?.billingAddress?.address}, {selectedProduct?.billingAddress?.city} {selectedProduct?.billingAddress?.zipCode} {selectedProduct?.billingAddress?.state}
                       
                      </Text>
                      <Spacer height={2} />

                      <Text color="black" fontSize="1" lineHeight={1.5}>
                      Payment Type
                      </Text>
                      <Text
                        color="black"
                        fontSize="1.5"
                        fontWeight={700}
                        lineHeight={1.5}
                      >
                        {selectedProduct?.paymentType}
                      </Text>
                      <Spacer height={2} />

                 
                    </Scroll>
                  </TableWrapper>
                </Wrapper>
                <Spacer height={3} />
              </CustomCol>
            </Row>
            <Row justifyContent="end">
              <Col lg={3}>
                <Button
                  width="100%"
                  hasBorder
                  ifClicked={() => {
                    onClose();
                  }}
                  label="Cancel"
                />
              </Col>
              <Col lg={3}>
              <CustomSpacer/>
                <Button
                  width="100%"
                  ifClicked={() => {
                   doProceedOrder()
                  //  doTestRes()
                  }}
                  label="Proceed Order"
                />
              </Col>
            </Row>
            {openRefundModal && (
              <OrderRefundModal
                onClose={() => {
                  setOpenRefundModal(false);
                }}
              />
            )}
             {openSuccessModal && (
        <SuccessModal
        onClose={() => {
            setOpenSuccessModal(false);
            onClose()
          }}
          
          label="Your Order was Proceeded successfully"
        />
      )}
          </Container>
        </ModalWrapper>
      </Modal>
    </>
  );
};
const HorizontalScrollContainer = styled.div`
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: none;
  white-space: nowrap; /* Prevent wrapping of content */
`;
const Scroll = styled.div`
  overflow-y: auto;

  height: ${({ height }) => `${height}rem`};

  &::-webkit-scrollbar {
    width: 0.3rem !important;
    height: 0.1rem !important;
  }

  &::-webkit-scrollbar-track {
    background: #eee;
    border-radius: 1rem;
    border: 0.01rem solid ${palette.silver};
    border-width: 0rem 0.1rem 0rem 0rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${palette.yellow};
    border-radius: 1rem;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
const CustomSpacer=styled.div`
@media screen and (max-width: 991px) {
padding-top:1rem;
}
`
const Total = styled(Flexed)`
  border-top: 1px solid ${palette.customGrey};
  padding-top: 1.5rem;
  // ${media.xs`
// gap:10rem;
// `}
  // ${media.sm`
// gap:10rem;
// `}
  // ${media.md`

// `}
`;
const CustomerWrapper = styled.div``;

const Billing = styled.div`
  border: 1px solid ${palette.customGrey};
  border-radius: 0.5rem;
  padding: 1.5rem;
  ${media.xs`
  padding:.5rem .2rem;
`}
  ${media.sm`
padding:.5rem .2rem;
`}
${media.md`
padding:1.5rem;
`}
${media.lg`
padding:.5rem;
`}
`;
const Wrapper = styled.div`
  //   border: 1px solid ${palette.customGrey};
  border-radius: 0.5rem;
`;
const TableWrapper = styled.div`
  border-radius: 0.625rem;
  border: 1px solid ${palette.customGrey};
  background-color: ${palette.white};
  padding: 0.75rem;
`;
const CustomCol = styled(Col)``;

const CustomFlex = styled(Flexed)`
  padding: 0.5rem;
  // ${media.xs`
// gap:6rem;
// `}
  // ${media.sm`
// gap:6rem;
// `}
  // ${media.md`
// 
// `}
`;
const CustomFlexed = styled(Flexed)`
  display: flex;
  ${media.xs`
display:block;
`}
  ${media.sm`
display:block;
`}
${media.md`
display:flex;
`}
${media.lg`
display:flex;
`}
`;

const CustomText = styled(Text)`
  ${media.xs`
padding-top:15px;
`}
  ${media.sm`
padding-top:15px;
`}
${media.md`
padding-top:0px;
`}
${media.lg`
padding-top:0px;
`}
`;

const StatusHeading = styled(Heading)`
  display: flex;

  align-items: center;
  width: 100%;
  border-right: 1px solid #cccbcb;
  height: 7rem;
`;

const ProfilStatus = styled.div`
  height: auto;
  display: flex;
  flex-direction: row;
`;
const IconWrapper = styled.div`
  background-color: ${palette.black};
  border-radius: 0.625rem;
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.img`
  width: 1.25rem;
  height: 1.25rem;
`;
const CustomRow = styled(Row)`
  padding: 0% !important;

  gap: ${({ gap }) => `${gap}rem`};
`;

const InnetText = styled.span`
  font-size: 1.25rem;
  color: black;
`;
const Phone = styled(Text)``;
const Email = styled(Text)``;

const ModalWrapper = styled.div`
  padding: 1.125rem 0rem;
  height: 100%;
  background: ${palette.white};
`;
const Cross = styled.img`
  height: 0.85rem;
`;

const Image = styled.img`
  height: 9.25rem;
  border: 2px solid #f49c10;
  border-radius: 50%;
`;
const ProfileData = styled.div`
  border-bottom: 1px solid #cccbcb;
  width: 100%;
  //   height: 11.5rem;
`;
const InputText = styled.input`
width:60px;

  font-weight: 300; 
  color: black; 
  font-size: 1.125rem; 
  
`;

export default OrdersDetailsModal;
