import React, { useState } from "react";
import styled from "styled-components";
import { Flexed } from "../../styles/shared";
import { palette } from "../../styles/colors";

const Checkbox = ({
  label,
  lineHeight,
  fontSize,
  fontWeight,
  isChecked,
  setIsChecked,
  opacity,
  textTransform,
  sideIcon,
  name,
}) => {
  // const [isChecked, setIsChecked] = useState(checkedValue)

  return (
    <Flexed direction="row" align="center">
      <IconWrapper>
        <Icon
          onClick={() => { if (setIsChecked) {
			setIsChecked(!isChecked);
		  }}}
          src={isChecked ? "icons/check.svg" : "icons/uncheeck.svg"}
        />
      </IconWrapper>

      {label && (
        <CustomText
          onClick={() => {
            if (setIsChecked) {
              setIsChecked(!isChecked);
            }
          }}
          isChecked={isChecked}
          fontSize={fontSize}
          lineHeight={lineHeight}
          fontWeight={fontWeight}
          opacity={opacity}
          textTransform={textTransform}
        >
          {label}
        </CustomText>
      )}

      {sideIcon && (
        <StyledIconWrapper>
          <Icon src="/image/icons/info.svg" />
        </StyledIconWrapper>
      )}
    </Flexed>
  );
};

const CustomText = styled.div`
  /* font-family: ${({ family }) => (family ? `${family}` : "Manrope")}; */
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "400")};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}rem` : "1.3rem")};
  line-height: ${({ lineHeight }) =>
    lineHeight ? `${lineHeight}rem` : "1rem"};

  padding-left: 0.625rem;
  color: ${({ isChecked }) => (isChecked ? palette.yellow : palette.black)};
  text-transform: ${({ textTransform }) =>
    textTransform ? textTransform : "normal"};
  opacity: ${({ opacity }) => (opacity ? opacity : 1)};
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1rem;
  width: 1rem;
`;
const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0.875rem;
  width: 0.875rem;
  margin-left: 0.375rem;
`;

const Icon = styled.img`
  cursor: pointer;
  height: 100%;
  width: 100%;
`;
export default Checkbox;
