import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {palette} from '../../styles/colors'
import {Col, Container, Row} from 'styled-bootstrap-grid'
import {Flexed, Heading, Spacer, Text, VerticalSpacer} from '../../styles/shared'
import CustomDropDown from '../common/CustomDropDown'
import Button from '../common/Button'

const SuccessModal = ({onClose, label}) => {
	const [filterOptions, setFilterOptions] = useState([
		{label: 'Distributer', value: 1},
		{label: 'Shopkeeper', value: 2}
	])
	return (
		<>
			<Modal
				open={true}
				onClose={onClose}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'successModal'
				}}
				showCloseIcon={false}>
				<ModalWrapper>
					<Wrapper>
						<Flexed direction="column" align="center">
							<Text isCentered color="black" fontWeight={950} fontSize={1.5} lineHeight={1.5}>
								{label}
							</Text>
							<Spacer height={2.5} />
							<Image src="/icons/success.svg" />
						</Flexed>
						<Spacer height={2.5} />
						<Flexed direction="row" justify="center" align="center">
							<Button
								label="OK"
								ifClicked={onClose}
								width="13.75rem"
							/>
						</Flexed>
					</Wrapper>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const Image = styled.img`
	height: 8rem;
`
const Wrapper = styled(Container)`
	padding: 0;
`
const StyledCol = styled(Col)`
	/* padding: 0; */
`

const ModalWrapper = styled.div`
	padding: 1.125rem 0rem;
	height: 100%;
	background: ${palette.white};
`

export default SuccessModal
