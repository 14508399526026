export const palette = {
	pureBlack: '#000000',
	black: '#1D1D1D',
	white: '#FFFFFF',
	grey: '#6A6A6A',
	lightGrey: '#D6D6D6',
	danger: '#FF0000',
	yellow: '#F49C10',
	lightYellow: '#FDEBCF',
	lightGrey: '#E8E7E5',
	darkGrey: '#3F3F3F',
	customGrey: '#CCCBCB',
	active:'#28C11F',
	inActive:'#DC1E3F'
}
