import React, {useEffect, useState} from 'react'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import {styled} from 'styled-components'
import {palette} from '../styles/colors'
import SearchSection from '../components/common/SearchSection'
import InputField from './common/InputField'
import {Flexed, Heading, Spacer, Text} from '../styles/shared'
import Button from './common/Button'
import SuccessModal from './modals/SuccessModal'
import Checkbox from './common/CheckBox'
import GalleryModal from './modals/GalleryModal'
import {useDispatch} from 'react-redux'
import {setIsLoading} from '../actions/authActions'
import {toastError, toastSuccess} from '../styles/toasterStyle'
import {addCompaign, generateImageLink} from '../api/apis'
const AddCompaign = ({showAddCompaignForm, doGetCompaigns, onClose}) => {
	const [name, setName] = useState('')

	const [title, setTitle] = useState('')
	const [body, setBody] = useState('')
	const [email, setEmail] = useState('')
	const [sendEmailsToApi, setSendEmailsToAPI] = useState('')
	const [emailError, setEmailError] = useState('')

	const [titleError, setTitleError] = useState('')
	const [imageError, setImageError] = useState('')
	const [openGalleryModal, setOpenGalleryModal] = useState('')
	const [NameError, setNameError] = useState('')
	const [profilImage, setProfileImage] = useState('')
	const [showImage, setShowImage] = useState(null)
	const [getImageLink, setImageLink] = useState('')
	const [checkValue, setCheckedValue] = useState('')
	const [checkValueError, setCheckedValueError] = useState('')
	const [openSuccessModal, setOpenSuccessModal] = useState(false)

	const _dispatch = useDispatch()

	useEffect(() => {
		// doAddCompaignImage();
	}, [])
	useEffect(() => {
		console.log('showImage', showImage)
		if (showImage != null) {
			setImageError('')
		}
	}, [showImage])

	const doAddCompaignImage = async () => {
		_dispatch(setIsLoading(true))
		const emailsArray = email?.split(',')?.map((email) => email?.trim())
		setSendEmailsToAPI(emailsArray)
		const response = await generateImageLink(showImage)

		console.log('Response from addCompaign image:', response?.data)
		setImageLink(response?.data)
		console.log('image ka link show ho', getImageLink)
		if (response?.success) {
			setOpenSuccessModal(true)
			doAddCompaign(response?.data, emailsArray)
		} else {
			toastError(response?.message)
		}

		_dispatch(setIsLoading(false))
	}

	const doAddCompaign = async (imageUrl, emailsArray) => {
		_dispatch(setIsLoading(true))
		console.log('image ', getImageLink)
		const response = await addCompaign(
			name,
			title,
			body,
			checkValue,

			imageUrl,
			emailsArray
		)

		console.log('Response from addCompaign: && getImageLink', getImageLink)

		if (response?.success) {
			setOpenSuccessModal(true)
		} else {
			toastError(response?.message)
		}

		_dispatch(setIsLoading(false))
	}

	// deleteCampaign

	const checkValidationOnClick = () => {
		let isValid = true

		if (name === '') {
			setNameError('Name is required')
			isValid = false
		}
		if (title === '') {
			setTitleError('Title is required')
			isValid = false
		}
		if (checkValue === '') {
			setCheckedValueError('option is required')
			isValid = false
		}
		if (showImage === null) {
			setImageError('Please Select Image')
			isValid = false
		}

		return isValid
	}
	return (
		<>
			<Form>
				<Icon src="./icons/crossicon.svg" alt="loading" onClick={showAddCompaignForm} />
				<Spacer height={1} />
				<Heading color="black" fontSize={1.5} lineHeight={2}>
					Add Campaign
				</Heading>
				<Spacer height={2.125} />
				<Row>
					<Col lg={6}>
						<InputField
							labelType="xlarge"
							label="Name"
							value={name}
							placeholder="My Daily Campaign"
							handleChange={(e) => {
								setNameError('')
								if (e === '') {
									setNameError('name is required')
								}
								setName(e)
							}}
							error={NameError}
							errorMsg={NameError}
							required
						/>

						<Spacer height={1} />
					</Col>
					<Col lg={6}>
						<InputField
							labelType="xlarge"
							label="Title"
							value={title}
							placeholder="Title"
							handleChange={(e) => {
								setTitleError('')
								if (e === '') {
									setTitleError('Title is required')
								}
								setTitle(e)
							}}
							error={titleError}
							errorMsg={titleError}
							required
						/>
					</Col>
				</Row>

				<Spacer height={0.5} />
				<Row>
					<Col lg={12}>
						<InputField
							labelType="xlarge"
							label="Body"
							value={body}
							type="textarea"
							placeholder="Write Something"
							handleChange={(e) => {
								// setBodyError('')
								// if (e === '') {
								// 	setBodyError('Bo is required')
								// }
								setBody(e)
							}}
							// error={titleError}
							// errorMsg={titleError}
							// required
						/>
					</Col>
				</Row>
				<Spacer height={0.5} />

				<Label type="xlarge" fontWeight={700} margin="0rem 0rem 0.19rem 0rem">
					Share With
				</Label>
				<Spacer height={0.8} />
				<Flexed flexWrap="wrap" gap={1} justify="space-between">
					<Checkbox setIsChecked={() => setCheckedValue('Distributor')} isChecked={checkValue === 'Distributor' ? true : false} label="Distributor" />
					<Checkbox setIsChecked={() => setCheckedValue('Online Power House')} isChecked={checkValue === 'Online Power House' ? true : false} label="Online Power House" />
					<Checkbox setIsChecked={() => setCheckedValue('Retailer')} isChecked={checkValue === 'Retailer' ? true : false} label="Retailer" />
					<Checkbox setIsChecked={() => setCheckedValue('Everyone')} isChecked={checkValue === 'Everyone' ? true : false} label="Everyone" />
					<Checkbox setIsChecked={() => setCheckedValue('Email')} isChecked={checkValue === 'Email' ? true : false} label="Email" />
				</Flexed>
				<Spacer height={1} />
				{checkValue === '' && (
					<Text fontSize={0.625} type="small" color="danger" textTransform="lowercase">
						{checkValueError}
					</Text>
				)}

				<Spacer height={2} />
				{checkValue === 'Email' && (
					<Row>
						<Col lg={4}>
							<InputField
								value={email}
								placeholder="ckk@gg.com, bmb@cc.com, ss@gg.com"
								handleChange={(e) => {
									setEmailError('')
									if (e === '') {
										setEmailError('email is required')
									}
									setEmail(e)
								}}
								error={emailError}
								errorMsg={emailError}
								required
							/>
						</Col>
					</Row>
				)}
				<Spacer height={1} />
				<Row justifyContent="center">
					<Col lg={4.5}>
						<Spacer height={3} />
						<InputWrapper>
							<CustomUpload
								dashed
								onClick={() => {
									setOpenGalleryModal(true)
								}}>
								<UploadIcon showImage={showImage || profilImage} src={showImage ? showImage : profilImage ? process.env.REACT_APP_PUBLIC_IMAGE_URL + profilImage : '/icons/Group.svg'} />

								<Text isCentered lineHeight={1} type="small" color="black">
									Upload Image
								</Text>
							</CustomUpload>
						</InputWrapper>
						<Errormsg> {imageError}</Errormsg>
					</Col>
				</Row>
				<Spacer height={1} />

				<Row justifyContent="center">
					<Col lg={2.5}>
						{/* <Button
							label="Submit"
							ifClicked={() => {
								//   showAddCompaignForm();
								setOpenSuccessModal(true)
							}}
							width="100%"
						/> */}
						<Button
							label="Submit"
							ifClicked={() => {
								if (checkValidationOnClick()) {
									doAddCompaignImage()
								}
							}}
							width="100%"
						/>
					</Col>
				</Row>
			</Form>
			{openSuccessModal && (
				<SuccessModal
					onClose={() => {
						doGetCompaigns()
						showAddCompaignForm()
						setOpenSuccessModal(false)
					}}
					label="Your campaign was added successfully"
				/>
			)}
			{openGalleryModal && (
				<GalleryModal
					onClose={() => {
						setOpenGalleryModal(false)
					}}
					label="Upload Image"
					setImage={setShowImage}
				/>
			)}
		</>
	)
}

const Upload = styled.label`
	/* padding: 0.5rem; */
	height: 10rem;
	width: 10rem;
	border: 3px solid ${palette.yellow};
	border-radius: 100%;
	display: flex;
	background-color: ${palette.customGrey};
	align-items: center;
	justify-content: center;
	flex-direction: column;
	cursor: pointer;
`
const FileInput = styled.input`
	display: none !important;
`
const UploadIcon = styled.img`
	width: ${({showImage}) => (showImage ? '100%' : '3rem')};
	height: ${({showImage}) => (showImage ? '100%' : '3rem')};
	//   border-radius: 100%;
	/* font-size: 2.5rem; */
	/* color: ${palette.black}; */
`
const CustomUpload = styled.label`
	padding: 1rem;
	height: 10rem;
	border: 0.063rem dashed ${palette.input_border};
	border-radius: 0.375rem;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.7rem;
	flex-direction: column;
	cursor: pointer;
`

const InputWrapper = styled.div`
	position: relative;

	// height: 8.5rem;
`
const LabelWrapper = styled.div`
	position: absolute;
	top: 5.8rem;
	color: white;
	// left: 4.5rem;
	//   background-color: ${palette.white};
	padding: 0 0.5rem;
`
const Form = styled.div`
	background-color: ${palette.white};
	border-radius: 10px;
	/* height: 80vh; */
	${media.xs`
  padding: 1rem 1rem;
  `}
	${media.sm`
  padding: 1rem 1rem;
  `}
  ${media.md`
  padding: 2.625rem 5rem 2.625rem 5rem;
  `}
`

const Label = styled(Text)`
	/* font-weight: 500; */
	text-transform: capitalize;
	color: ${palette.black};
`
const Errormsg = styled.p`
	font-family: 'Helvetica Neue', sans-serif;
	font-weight: 400;
	font-size: 0.625rem;
	line-height: 0.875rem;
	color: #ff0000;
	margin: undefined;
	text-align: left;
	opacity: 1;
	text-transform: lowercase;
`
const Wrapper = styled.div`
	background-color: ${palette.lightGrey};
	height: 100vh;
	${media.xs`
	padding: 1rem 1rem;
	`}
	${media.sm`
	padding: 1rem 1rem;
	`}
	${media.md`
	padding: 1.625rem 1.25rem 2.625rem 1.25rem;
	`}
`

const TableWrapper = styled.div`
	border-radius: 0.625rem;
	background-color: ${palette.white};
	padding: 0.75rem;
`

const IconWrapper = styled.div`
	background-color: ${palette.black};
	border-radius: 0.625rem;
	width: 2.25rem;
	height: 2.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
`

const Icon = styled.img`
	width: 0.875rem;
	height: 0.875rem;
	cursor: pointer;
	position: absolute; /* Make sure to specify a position */
	right: 4rem; /* Adjust this value to move the icon further to the right */
`
export default AddCompaign
