import React, { useEffect, useState } from "react";
import { Col, Container, Row, media } from "styled-bootstrap-grid";
import { styled } from "styled-components";
import { palette } from "../styles/colors";
import SearchSection from "./common/SearchSection";
import InputField from "./common/InputField";
import { Flexed, Heading, Spacer, Text } from "../styles/shared";
import Button from "./common/Button";
import SuccessModal from "./modals/SuccessModal";
import Checkbox from "./common/CheckBox";
import GalleryModal from "./modals/GalleryModal";

const EditCompaign = ({ setShowEditCompaignForm ,editData, setEditData }) => {
  const [name, setName] = useState("");

  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [titleError, setTitleError] = useState("");
const [openGalleryModal,setOpenGalleryModal] = useState('')
  const [NameError, setNameError] = useState("");
  const [profilImage, setProfileImage] = useState("");
  const [showImage, setShowImage] = useState(null);
  const [salesPersonCodeError, setSalesPersonCodeError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [checkValue, setCheckedValue] = useState(null)
  const [email, setEmail] = useState("");

useEffect(()=>{
  setTitle(editData?.compaignTitle)
  setBody(editData?.compaignBody)
  setName(editData?.compaignName)
  setCheckedValue(editData?.shareWithEmail.length >0 ? "Email" : editData?.shareWith)
  setShowImage(editData?.imgUrl)
  setEmail(editData?.shareWithEmail)
},[])

  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const handleCapture = ({ target }) => {
    const reader = new FileReader();
    reader.readAsDataURL(target.files[0]);
    reader.onload = () => {
      if (reader.readyState === 2) {
        setShowImage(reader.result);
        setProfileImage(reader.result);
      }
    };
  };
  return (
    <>
      <Form>

							
        <Icon src="./icons/crossicon.svg" alt="loading" onClick={setShowEditCompaignForm}/>
        <Spacer height={1}/>
        <Heading color="black" fontSize={1.5} lineHeight={2}>
          Campaign Info
        </Heading>
        <Spacer height={2.125} />
        <Row>
          <Col lg={6}>
            <InputField
              labelType="xlarge"
              label="Name"
              value={name}
              placeholder="My Daily Campaign"
              handleChange={(e) => {
                setNameError("");
                if (e === "") {
                  setNameError("name is required");
                }
                setName(e);
              }}
              error={NameError}
              errorMsg={NameError}
              disabled={true}
              // required
            />

            <Spacer height={1} />
          </Col>
          <Col lg={6}>
            <InputField
              labelType="xlarge"
              label="Title"
              value={title}
              placeholder="Title"
              handleChange={(e) => {
                setTitleError("");
                if (e === "") {
                  setTitleError("Title is required");
                }
                setTitle(e);
              }}
              error={titleError}
              errorMsg={titleError}
              disabled={true}
              // required
            />
          </Col>
        </Row>

        <Spacer height={0.5} />
        <Row>
          <Col lg={12}>
            <InputField
              labelType="xlarge"
              label="Body"
              value={body}
              type="textarea"

              placeholder="Write Something"
              handleChange={(e) => {
                // setBodyError('')
                // if (e === '') {
                // 	setBodyError('Bo is required')
                // }
                setBody(e);
              }}
              disabled={true}
              // error={titleError}
              // errorMsg={titleError}
              // required
            />
          </Col>
        </Row>
        <Spacer height={0.5} />

        <Label type="xlarge" fontWeight={700} margin="0rem 0rem 0.19rem 0rem">
          Share With
        </Label>
        <Spacer height={0.8} />
        <Flexed flexWrap="wrap" gap={1} justify="space-between">
        <Checkbox  isChecked={checkValue === 'Distributors' ? true : false} label="Distributors" />
					<Checkbox  isChecked={checkValue === 'Online Power House' ? true : false} label="Online Power House" />
					<Checkbox  isChecked={checkValue === 'Retailer' ? true : false} label="Retailer" />
					<Checkbox  isChecked={checkValue === 'Everyone' ? true : false} label="Everyone" />
					<Checkbox  isChecked={checkValue === 'Email' ? true : false} label="Email" />
				
        </Flexed>
        {email.length>0 && (<>
        <Spacer height={2} />
        <Row>
          <Col lg={4}>
            <InputField
            value={email}
              placeholder="ckk@gg.com, bmb@cc.com, ss@gg.com"
              handleChange={(e) => {
                // setBodyError('')
                // if (e === '') {
                // 	setBodyError('Bo is required')
                // }
                setBody(e);
              }}
              // error={titleError}
              // errorMsg={titleError}
              // required
            />
          </Col>
        </Row>
        </>)}
        <Spacer height={1} />
        <Row justifyContent="center">
          <Col lg={4.5}>
            <Spacer height={3} />
            <InputWrapper>
              <CustomUpload dashed 
			  
			  >
                <UploadIcon
                  showImage={showImage || profilImage}
                  src={
                    showImage
                      ? showImage
                      : profilImage
                      ? showImage
                      : "/icons/Group.svg"
                  }
                />
                {/* <Text isCentered lineHeight={1} type="small" color="black">
                  Upload Image
                </Text> */}
                {/* <Icon src="icons/add.svg" /> */}
                {/* <FileInput
                  id="faceImage"
                  accept="image/jpeg/png"
                  type="file"
                  onChange={(e) => {
                    handleCapture(e);
                    // setShowImage('')
                  }}
                /> */}
              </CustomUpload>
            </InputWrapper>
          </Col>
        </Row>
        <Spacer height={1} />

        {/* <Row justifyContent="center">
          <Col lg={2.5}>
            <Button
              label="Update"
              ifClicked={() => {
                //   setShowEditCompaignForm();
                setOpenSuccessModal(true);
              }}
              width="100%"
            />
          </Col>
        </Row> */}
      </Form>
      {openSuccessModal && (
        <SuccessModal
          onClose={() => {
            setOpenSuccessModal(false);
          }}
          label="Your compaign add successfully"
        />
      )}
      {openGalleryModal && (
        <GalleryModal
          onClose={() => {
            setOpenGalleryModal(false);
          }}
          label="Upload Image"
        />
      )}
    </>
  );
};

const FileInput = styled.input`
  display: none !important;
`;
const UploadIcon = styled.img`
  width: ${({ showImage }) => (showImage ? "100%" : "3rem")};
  height: ${({ showImage }) => (showImage ? "100%" : "3rem")};
  //   border-radius: 100%;
  /* font-size: 2.5rem; */
  /* color: ${palette.black}; */
`;
const CustomUpload = styled.label`
  padding: 1rem;
  height: 10rem;
  border: 0.063rem dashed ${palette.input_border};
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
  flex-direction: column;
  cursor: pointer;
`;

const InputWrapper = styled.div`
  position: relative;

  // height: 8.5rem;
`;
const LabelWrapper = styled.div`
  position: absolute;
  top: 5.8rem;
  color: white;
  // left: 4.5rem;
  //   background-color: ${palette.white};
  padding: 0 0.5rem;
`;
const Form = styled.div`
  background-color: ${palette.white};
  border-radius: 10px;
  min-height: 80vh;
  ${media.xs`
  padding: 1rem 1rem;
  `}
  ${media.sm`
  padding: 1rem 1rem;
  `}
  ${media.md`
  padding: 2.625rem 5rem 2.625rem 5rem;
  `}
`;

const Label = styled(Text)`
  /* font-weight: 500; */
  text-transform: capitalize;
  color: ${palette.black};
`;
const Wrapper = styled.div`
  background-color: ${palette.lightGrey};
  height: 100vh;
  ${media.xs`
	padding: 1rem 1rem;
	`}
  ${media.sm`
	padding: 1rem 1rem;
	`}
	${media.md`
	padding: 1.625rem 1.25rem 2.625rem 1.25rem;
	`}
`;

const TableWrapper = styled.div`
  border-radius: 0.625rem;
  background-color: ${palette.white};
  padding: 0.75rem;
`;

const IconWrapper = styled.div`
  background-color: ${palette.black};
  border-radius: 0.625rem;
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.img`
  width: 0.875rem;
  height: 0.875rem;
  cursor:pointer;
  position: absolute; /* Make sure to specify a position */
  right: 4rem; /* Adjust this value to move the icon further to the right */
`;
export default EditCompaign;
