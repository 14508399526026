import React from 'react'
import styled, {keyframes} from 'styled-components'
import {palette} from '../../styles/colors'

const Loader = ({visible}) => {
	console.log('visiblevisible', visible)
	return (
		<Wrapper visible={visible}>
			<LoaderContainer>
				<LoaderInner />
			</LoaderContainer>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: ${({visible}) => (visible === false ? 'none ' : 'flex')};
	position: fixed;
	justify-content: center;
	align-items: center;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	height: 100vh;
	width: 100vw;
	backdrop-filter: blur(3px); /* Apply the blur effect */
	background-color: rgba(232, 231, 229, 0.3); /* Background color with opacity */
	z-index: 99999;
	
`
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

// Styled component for the loader container
const LoaderContainer = styled.div`
	display: inline-block;
	width: 80px;
	height: 80px;
	position: relative;
	border: 8px solid #f3f3f3;
	border-radius: 50%;
	animation: ${spin} 1.2s linear infinite;
	z-index: 999999;
`

// Styled component for the loader's inner circle
const LoaderInner = styled.div`
	width: 100%;
	height: 100%;
	z-index: 999999 ;

	position: absolute;
	/* border: 8px solid transparent; */
	border-top: 8px solid ${palette.yellow};
	border-radius: 50%;
	animation: ${spin} 1.2s linear infinite;
`
export default Loader
