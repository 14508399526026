import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {palette} from '../../styles/colors'
import {Col, Container, Row} from 'styled-bootstrap-grid'
import {Flexed, Heading, Spacer, Text, VerticalSpacer} from '../../styles/shared'
import CustomDropDown from '../common/CustomDropDown'
import Button from '../common/Button'

const DeleteModal = ({onClose, label, onSubmit}) => {
	const [filterOptions, setFilterOptions] = useState([
		{label: 'Distributer', value: 1},
		{label: 'Shopkeeper', value: 2}
	])
	return (
		<>
			<Modal
				open={true}
				onClose={onClose}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'DeleteModal'
				}}
				showCloseIcon={false}>
				<ModalWrapper>
					<Wrapper>
						<Text isCentered color="black" fontSize={1.5} lineHeight={1.5}>
							{label}
						</Text>
						<Spacer height={3.438} />
						<Row justifyContent="around">
							<StyledCol xs={3} sm={3} md={3} lg={3}>
								<Button
									label="No"
									hasBorder
									ifClicked={() => {
										onClose()
									}}
									width="100%"
								/>
							</StyledCol>
							<StyledCol xs={3} sm={3} md={3} lg={3}>
								<Button
									label="Yes"
									ifClicked={() => {
										if (onSubmit) {
											onSubmit()
										}
									}}
									width="100%"
								/>
							</StyledCol>
						</Row>
					</Wrapper>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const Wrapper = styled(Container)`
	padding: 0;
`
const StyledCol = styled(Col)`
	/* padding: 0; */
`

const ModalWrapper = styled.div`
	padding: 1.5rem 0rem 1.5rem 0;
	height: 100%;
	background: ${palette.white};
`

export default DeleteModal
